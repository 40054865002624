import { arrowUpIcon } from 'assets/icons';
import { AdminHeader } from 'components/Header/AdminHeader';
import { OrgFilter } from 'components/Organization/OrgFilter';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import { observer } from 'mobx-react-lite';
import { AdminPage } from 'pages/RecoveryAdmin/AdminPage';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import styled, { css } from 'styled-components';
import { PrintTriageViewModel } from './PrintTriageViewModel';
import { RejectedStack } from './RejectedStack';
import { ToPrintStack } from './ToPrintStack';
import { TriageStatus } from './TriageStatus';
import { UnassignedStack } from './UnassignedStack';

export interface PrintTriageComponent {
    vm: PrintTriageViewModel;
}

export const TriageStack = styled.div`
    align-items: center;
    display: grid;
    flex-direction: column-reverse;
    grid: auto 1fr / auto;
    justify-content: center;
    padding: 0 0 2.5rem;
    position: relative;
    perspective: 80rem;
    perspective-origin: 50% 30rem;
`;

export const TriageHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
`;

const TriageStacks = styled.div`
    display: grid;
    grid: auto / 16rem 26rem 16rem;
    grid-gap: 1rem;
    padding: 1rem 2rem;
    justify-content: space-between;
`;

export const EmptyTriageCard = styled.div`
    align-items: center;
    background: #fff;
    display: flex;
    height: 10rem;
    justify-content: center;
    margin: 0 0 2.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const LicensePlate = styled.div<{ warn?: boolean }>`
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5rem;
    justify-content: center;
    ${({ warn }) =>
        warn &&
        css`
            background: #fffbcc;
        `}
`;

export const DetailStrap = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    & ${LicensePlate} {
        font-size: 0.75rem;
    }
`;

export const Organization = styled.div`
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
`;

const ButtonBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 26rem;
    & > .btn {
        min-width: 8rem;
    }
`;

export const PrintTriage: React.FC<PrintTriageComponent> = observer(({ vm }) => {
    const [direction, setDirection] = useState(0);
    const location = useLocation();

    const handleKeyDown = (e: KeyboardEvent) => {
        if (vm.printing) {
            return;
        }
        if (e.code === 'ArrowLeft') {
            setDirection(-1);
            vm.addToRejected();
        } else if (e.code === 'ArrowRight') {
            setDirection(1);
            vm.addToPrint();
        } else if (e.code === 'KeyZ' && (e.metaKey || e.ctrlKey)) {
            vm.undo();
        } else if (e.code === 'KeyA' && (e.metaKey || e.ctrlKey)) {
            vm.acceptAll();
        }
    };

    useEffect(() => {
        vm.handleLoad();
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [location]);

    return (
        <AdminPage>
            <AdminHeader refresh={vm.refresh} refreshing={vm.fetching} showSearch={false} />
            <div className="container">
                <OrgFilter vm={vm} />
                <TriageStacks>
                    <RejectedStack vm={vm} />
                    <UnassignedStack direction={direction} vm={vm} />
                    <ToPrintStack vm={vm} />
                </TriageStacks>

                <ButtonBar className="mb-2">
                    <Button
                        disabled={vm.printing}
                        color="primary"
                        outline={true}
                        onClick={vm.addToRejected}
                    >
                        <SvgIcon icon={arrowUpIcon} rotation={270} size="sm" className="mr-2" />
                        Mark invalid
                    </Button>
                    <Button
                        disabled={vm.printing}
                        color="primary"
                        outline={true}
                        onClick={vm.addToPrint}
                    >
                        Accept
                        <SvgIcon icon={arrowUpIcon} rotation={90} size="sm" className="ml-2" />
                    </Button>
                </ButtonBar>
                <ButtonBar>
                    <Button disabled={vm.printing} color="link" onClick={vm.undo} title="Ctrl + Z">
                        Undo
                    </Button>
                    <Button
                        disabled={vm.printing}
                        className="font-weight-bold"
                        color="link"
                        onClick={vm.acceptAll}
                        title="Ctrl + A"
                    >
                        Accept all
                    </Button>
                </ButtonBar>

                {vm.badDetails.length > 0 && (
                    <div className="container">
                        <h4>Invalid debts</h4>
                        <ul>
                            {vm.badDetails.map((details, i) => (
                                <li key={`${details.debtIdentifier}-${i}`}>
                                    {details.debtIdentifier}: {details.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <TriageStatus vm={vm} />
        </AdminPage>
    );
});
