import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
    AlreadyPaidComponent,
    ConfirmDetailsComponent,
    ConfirmPlateComponent,
    matchOrgKey,
    matchOrgPath,
    NotFoundComponent,
    PaymentCompleteComponent,
    PaymentFailedComponent,
} from 'routing';
import { routingStore } from 'stores';
import { ThemeProvider } from 'styled-components';
import { orgKeys, ThemeContext } from 'styles/theme';

export interface OrganizationParams {
    orgKey: string;
}

export const Organization: React.FC<PublicAppComponent> = ({ vm }) => {
    const orgPathMatch = matchOrgPath(routingStore.location);
    const orgKey = matchOrgKey(routingStore.location) || '';
    const theme = useContext(ThemeContext)(orgKey);

    useEffect(() => {
        vm.organizationKey = orgKey;
    }, [orgKey]);

    if (!theme || !orgPathMatch) {
        if (process.env.NODE_ENV === 'development') {
            // tslint:disable-next-line:no-console
            console.error(
                `No theme found for org key "${orgKey}". Valid keys are: ${orgKeys.join(', ')}`,
            );
        }
        return NotFoundComponent(vm);
    }

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route path={`${orgPathMatch.path}/oops`} component={PaymentFailedComponent} />
                <Route
                    path={`${orgPathMatch.path}/already-paid`}
                    component={AlreadyPaidComponent}
                />
                <Route path={`${orgPathMatch.path}/paid`} component={PaymentCompleteComponent} />
                <Route
                    path={`${orgPathMatch.path}/pay`}
                    exact={true}
                    component={ConfirmPlateComponent}
                />
                <Route
                    path={`${orgPathMatch.path}/:referenceNumber/:licensePlate`}
                    component={ConfirmDetailsComponent}
                />
                <Redirect
                    from={`${orgPathMatch.path}`}
                    to={`${orgPathMatch.path}/pay`}
                    exact={true}
                />
            </Switch>
        </ThemeProvider>
    );
};
