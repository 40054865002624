import { CreateDebtPostalAddressPayload, DebtPostalAddressRepresentation } from 'api/Api';
import cav from 'chopped-and-viewed';
import { toAddress, toMotochekAddress } from './motochek-to-address';

export interface MotochekRecord {
    raw: string;
    recordType: string;
    plateNo: string;
    replacementPlate: string;
    vin: string;
    messageCode1: string;
    messageCode2: string;
    messageCode3: string;
    yearOfManufacture: string;
    make: string;
    model: string;
    submodel: string;
    dateQueryProcessed: string;
    registeredPersonName: string;
    tradingAsOrRepresenting: string;
    jointOwnerIndicator: string;
    sexOrCompany: string;
    dateOfBirthOfRegisteredPerson: string;
    physicalAddressPropertyName: string;
    physicalAddressFlatFloorNumber: string;
    physicalAddressStreetNumber: string;
    physicalAddressStreetName: string;
    physicalAddressRDNumber: string;
    physicalAddressSuburb: string;
    physicalAddressTown: string;
    physicalAddressPostcode: string;
    mailingAddressPropertyName: string;
    mailingAddressFlatFloorName: string;
    mailingAddressStreetNumber: string;
    mailingAddressStreetName: string;
    mailingAddressBoxBagRD: string;
    mailingAddressBoxBagNumber: string;
    mailingAddressRDNumber: string;
    mailingAddressSuburb: string;
    mailingAddressTown: string;
    mailingAddressPostcode: string;
    goneNoAddress: string;
    ownershipStatus: string;
    ticketNumber: string;
    queryDateForOwnerAsAt: string;
    acquisitionDateOwnership: string;
    registrationStatus: string;
    licenceExpiryDate: string;
    licenceType: string;
    dateOfIssueForLatestLicense: string;
    timeOfIssueForLatestLicense: string;
    continuousLicense: string;
    mainColour: string;
    secondColour: string;
    ccRating: string;
    bodyStyle: string;
    subjectToWOF: string;
    dateOfLatestWOFInspection: string;
    resultOfLatestWOFInspection: string;
    expiryDateOfLastSuccessfulWOF: string;
    subjectToCOFInspection: string;
    dateOfLatestCOFInspection: string;
    resultOfLatestCOFInspection: string;
    expiryDateOfLastSuccessfulCOF: string;
    subjectToRUC: string;
    importedDamaged: string;
    cancellationReasonCode: string;
    registeredOverseas: string;
    dayOfFirstRegistrationOverseas: string;
    monthOfFirstRegistrationOverseas: string;
    yearOfFirstRegistrationOverseas: string;
    inspectionAgent: string;
}

export const parse = (s: string) => {
    // discard the header line
    const [, ...lines] = s.split(/\r?\n/);

    // discard the trailing line if it's empty
    if (lines[lines.length - 1] === '') {
        lines.pop();
    }

    return lines.map(parseLine);
};

// https://www.nzta.govt.nz/assets/Vehicles/docs/Motochek/data-format-results.doc
export const fields: [keyof MotochekRecord, number][] = [
    ['recordType', 1],
    ['plateNo', 6],
    ['replacementPlate', 6],
    ['vin', 17],
    ['messageCode1', 2],
    ['messageCode2', 2],
    ['messageCode3', 2],
    ['yearOfManufacture', 4],
    ['make', 20],
    ['model', 20],
    ['submodel', 20],
    ['dateQueryProcessed', 8],
    ['registeredPersonName', 56],
    ['tradingAsOrRepresenting', 56],
    ['jointOwnerIndicator', 1],
    ['sexOrCompany', 1],
    ['dateOfBirthOfRegisteredPerson', 8],
    ['physicalAddressPropertyName', 35],
    ['physicalAddressFlatFloorNumber', 8],
    ['physicalAddressStreetNumber', 8],
    ['physicalAddressStreetName', 40],
    ['physicalAddressRDNumber', 7],
    ['physicalAddressSuburb', 35],
    ['physicalAddressTown', 40],
    ['physicalAddressPostcode', 8],
    ['mailingAddressPropertyName', 35],
    ['mailingAddressFlatFloorName', 8],
    ['mailingAddressStreetNumber', 8],
    ['mailingAddressStreetName', 40],
    ['mailingAddressBoxBagRD', 3],
    ['mailingAddressBoxBagNumber', 7],
    ['mailingAddressRDNumber', 7],
    ['mailingAddressSuburb', 35],
    ['mailingAddressTown', 40],
    ['mailingAddressPostcode', 8],
    ['goneNoAddress', 1],
    ['ownershipStatus', 1],
    ['ticketNumber', 6],
    ['queryDateForOwnerAsAt', 8],
    ['acquisitionDateOwnership', 8],
    ['registrationStatus', 1],
    ['licenceExpiryDate', 8],
    ['licenceType', 1],
    ['dateOfIssueForLatestLicense', 8],
    ['timeOfIssueForLatestLicense', 4],
    ['continuousLicense', 1],
    ['mainColour', 6],
    ['secondColour', 6],
    ['ccRating', 5],
    ['bodyStyle', 3],
    ['subjectToWOF', 1],
    ['dateOfLatestWOFInspection', 8],
    ['resultOfLatestWOFInspection', 1],
    ['expiryDateOfLastSuccessfulWOF', 8],
    ['subjectToCOFInspection', 1],
    ['dateOfLatestCOFInspection', 8],
    ['resultOfLatestCOFInspection', 1],
    ['expiryDateOfLastSuccessfulCOF', 8],
    ['subjectToRUC', 1],
    ['importedDamaged', 1],
    ['cancellationReasonCode', 1],
    ['registeredOverseas', 1],
    ['dayOfFirstRegistrationOverseas', 2],
    ['monthOfFirstRegistrationOverseas', 2],
    ['yearOfFirstRegistrationOverseas', 4],
    ['inspectionAgent', 56],
];

export const expectedWidth = fields.reduce((width, [, fieldWidth]) => width + fieldWidth, 0);

export const parseLine = (line: string) => {
    const parser = cav(fields);
    return { ...parser(line), raw: line } as MotochekRecord;
};

export const validateRecord = (record: MotochekRecord) => {
    return record.raw.length === expectedWidth;
};

const invalidMessageCodes = ['21', '22', '41', '53'];
export const toRecords = (input: string) => {
    const records = parse(input);

    const addressRecords = records.map<CreateDebtPostalAddressPayload & { valid: boolean }>(
        item => {
            const address = toAddress(item);
            const motochekAddress = toMotochekAddress(item);
            const { registeredPersonName: recipient, plateNo, vin, messageCode1 } = item;

            let postalAddress: DebtPostalAddressRepresentation | null = null;
            // some message codes mean we shouldn't expect an address
            if (address && recipient && !invalidMessageCodes.includes(messageCode1)) {
                postalAddress = { address, recipient, motochekAddress };
            }

            // we fall back to the vin if the plateNo isn't populated, this is where Motochek puts 7+ character plates
            const licensePlate = plateNo || vin;

            return {
                licensePlate,
                address: postalAddress,
                valid: validateRecord(item),
            };
        },
    );

    const invalidRecords = addressRecords.filter(record => !record.valid);

    if (invalidRecords.length === 0) {
        return addressRecords;
    } else {
        throw new Error(
            `Invalid records: ${invalidRecords.map(record => record.licensePlate).join(', ')}`,
        );
    }
};
