import uuidv4 from 'uuid/v4';

/**
 * Generates a new base64 encoded URL safe uuid
 * Lifted entirely from Fawkes 😘
 */
export function uuid() {
    return btoa(String.fromCharCode(...uuidv4(null, [])))
        .replace(/\+/g, '-') // Replace + with - (see RFC 4648, sec. 5) to make it URL safe
        .replace(/\//g, '_') // Replace / with _ (see RFC 4648, sec. 5) to make it URL safe
        .substring(0, 22); // Drop '==' padding
}