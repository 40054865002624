import { Spinner } from 'components/Spinner/Spinner';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { getCurrencyFormat } from 'utils/currency';
import { toRecoveryKey } from 'utils/recovery';
import {
    EmptyTriageCard,
    LicensePlate,
    Organization,
    PrintTriageComponent,
    TriageHeader,
    TriageStack,
} from './PrintTriage';
import { TriageCard } from './TriageCard';

interface UnassignedStackComponent extends PrintTriageComponent {
    direction: number;
}

const DetailItem = styled.div<{ warn: boolean }>`
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    text-align: center;
    ${({ warn }) =>
        warn &&
        css`
            background: #fffbcc;
        `}
    & label {
        display: block;
        font-weight: 600;
        line-height: 1;
        margin: 0;
    }
`;
const TriageDetail = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0.75rem 0.75rem;
`;

export const UnassignedStack: FC<UnassignedStackComponent> = observer(({ direction, vm }) => {
    return (
        <div>
            <TriageHeader>
                {vm.fetching
                    ? `Fetching recoveries (${vm.unassigned.length}/${Math.min(
                          vm.total,
                          vm.batchSize,
                      )})`
                    : `Recoveries (${vm.unassigned.length})`}
            </TriageHeader>
            <AnimatePresence>
                {vm.unassigned.length === 0 ? (
                    <EmptyTriageCard>{vm.fetching ? <Spinner /> : 'All assigned!'}</EmptyTriageCard>
                ) : (
                    <TriageStack>
                        {vm.unassignedData.map((recovery, i) => {
                            const { incidentDetails } = recovery;
                            const volume = incidentDetails && incidentDetails.ProductQuantity;
                            const isDefaultVolume = parseFloat(volume || '') === 1;
                            const productName = incidentDetails && incidentDetails.ProductName;
                            const price =
                                incidentDetails && incidentDetails.ProductPrice
                                    ? parseFloat(incidentDetails.ProductPrice)
                                    : 0;
                            const productPrice = price > 0 ? getCurrencyFormat(price) : undefined;
                            return (
                                <TriageCard
                                    key={toRecoveryKey(recovery)}
                                    index={i}
                                    initial={false}
                                    recovery={recovery}
                                    translateX={direction * 100}
                                >
                                    <LicensePlate warn={/\*/.test(recovery.licensePlate)}>
                                        {recovery.licensePlate}
                                    </LicensePlate>
                                    <Organization>{recovery.organizationKey}</Organization>
                                    <TriageDetail>
                                        <DetailItem warn={false}>
                                            <label>Event Id</label>
                                            {recovery.debtIdentifier}
                                        </DetailItem>
                                        {productName && (
                                            <DetailItem warn={false}>
                                                <label>Product</label>
                                                {productName}
                                            </DetailItem>
                                        )}
                                        <DetailItem warn={isDefaultVolume}>
                                            <label>Amount</label>
                                            {volume}
                                        </DetailItem>
                                        {productPrice && (
                                            <DetailItem warn={price > 5}>
                                                <label>$/L</label>
                                                {productPrice}
                                            </DetailItem>
                                        )}
                                    </TriageDetail>
                                </TriageCard>
                            );
                        })}
                    </TriageStack>
                )}
            </AnimatePresence>
        </div>
    );
});
