import { ChurnZeroEventName } from 'api/Api.generated';
import { timesIcon } from 'assets/icons';
import { PublicLayout } from 'components/Layout';
import { LoadingOverlay } from 'components/Loader/LoaderOverlay';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import { observer } from 'mobx-react-lite';
import { Payment } from 'pages/Payment/Payment';
import React, { useContext, useEffect } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { alreadyPaidRoute, cannotPayRoute, confirmPlateRoute, matchConfirmDetails } from 'routing';
import { Context } from 'stores';
import styled, { css } from 'styled-components';
import { Link, Logo, PublicButton } from 'styles';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat, getCurrencyFormatFromString } from 'utils/currency';
import { formatDate } from 'utils/date';
import { isOnlineStore } from 'utils/IsOnlineStore';
import { loadStripe } from 'utils/load-stripe';
import { formatVolume } from 'utils/volume';
import { PublicAppComponent } from './ConfirmPlate';

const ButtonBlock = styled.div`
    text-align: center;
    margin: 0 -1.875rem 0.5rem;
    padding: 0 2rem 0.5rem;
    width: calc(100% + 4rem);
    @media (min-width: 768px) {
        border-top: 1px solid #ccc;
        padding-top: 1rem;
    }
`;

const LinkBlock = styled.div`
    text-align: center;
    width: calc(100% + 2rem);
    @media (max-width: 767px) {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        margin-bottom: 1rem;
        padding: 1rem 0;
    }
`;

const DesktopWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        margin: 1rem 0;
        padding: 1rem 2rem;
    }
`;

const Details = styled.div`
    flex: 1 1 calc(50% - 20px);
`;

const DetailsRow = styled.div`
    display: flex;
`;

const Detail = styled.div<{ flexBasis: number; noBorder?: boolean }>`
    ${({ flexBasis }) =>
        css`
            flex: 1 0 ${flexBasis}px;
        `}
    ${({ noBorder }) =>
        !noBorder &&
        css`
            border-bottom: 1px solid #ccc;
            margin-bottom: 1rem;
        `}
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem;
`;

const Header = styled.div`
    font-weight: bold;
    margin: 0 0 1rem;
    width: 100%;
`;

const LocationName = styled.div`
    font-weight: 600;
`;

const LicensePlate = styled.div`
    align-items: center;
    align-self: flex-start;
    border: 1px solid #555;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #fffbf0;
    display: inline-flex;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 1;
    padding: 0.375rem 0.625rem;
`;

const DetailLabel = styled.div`
    color: #555;
    font-weight: 600;
    font-size: ${props => props.theme.tinyFontSize};
    line-height: 1;
    margin: 0 0 0.375rem;
    text-transform: uppercase;
`;

const DetailContent = styled.div`
    font-size: ${props => props.theme.smallFontSize};
    line-height: 1rem;
    word-break: word-break;
`;

const Photo = styled.img`
    align-self: flex-start;
    margin: 0 1rem 0 0;
    max-width: 280px;
    flex: 0 1 calc(50% - 20px);
    @media (max-width: 767px) {
        max-width: 320px;
        min-height: 210px;
        margin: 0 -20px 1rem;
    }
`;

const PopoverCloseButton = styled.button`
    background: #fff;
    border: none;
    display: inline-flex;
    padding: 0.375rem;
    line-height: 0.75rem;
`;

export const ConfirmDetails: React.FC<PublicAppComponent> = observer(({ vm }) => {
    const { routing } = useContext(Context);
    useEffect(() => {
        loadStripe();
        const match = matchConfirmDetails(routing.location);
        if (match) {
            const { referenceNumber, licensePlate, orgKey } = match.params;
            vm.organizationKey = orgKey;
            vm.referenceNumber = referenceNumber;
            vm.licensePlate = licensePlate;
            vm.handleFindDetails();
        }
    }, [routing.location.pathname]);
    useEffect(() => {
        const { plateCheckState: state } = vm;
        if (state === 'successful') {
            vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewDetailsPage, vm.debtIdentifier);
        } else if (state === 'already-paid') {
            routing.replace(alreadyPaidRoute(vm.organizationKey));
        } else if (state === 'invalid-debt-state') {
            routing.replace(cannotPayRoute(vm.organizationKey));
        } else if (state === 'invalid-details') {
            routing.replace(
                confirmPlateRoute(
                    vm.organizationKey,
                    vm.referenceNumber,
                    vm.licensePlate,
                    'details',
                ),
            );
        } else if (state === 'errored') {
            routing.replace(
                confirmPlateRoute(vm.organizationKey, vm.referenceNumber, vm.licensePlate, 'err'),
            );
        }
    }, [vm.plateCheckState]);
    const { logo, helpPhone } = getOrgDetails(vm.organizationKey);

    // detect when people are going back & revert state back to successful
    useEffect(() => {
        vm.detailsConfirmed = routing.location.hash.indexOf('confirmed') >= 0;
    }, [routing.location.hash]);

    const handleConfirm = () => {
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayConfirmedDetails, vm.debtIdentifier);
        const { pathname, search } = routing.location;
        routing.push(`${pathname}${search}#confirmed`);
    };

    if (vm.plateCheckState === 'working') {
        return isOnlineStore.isOnline ? <LoadingOverlay /> : null;
    } else if (vm.plateCheckState === 'successful' && vm.detailsConfirmed) {
        return <Payment vm={vm} />;
    }
    return (
        <>
            <PublicLayout wide={true} sendEvent={vm.sendChurnZeroEvent}>
                <Logo alt="" src={logo} />
                <DesktopWrapper>
                    <Header>Details for {vm.licensePlate}</Header>
                    <Photo src={vm.imageUrl} />
                    <Details>
                        <DetailsRow>
                            <Detail flexBasis={280}>
                                <DetailLabel>Date</DetailLabel>
                                <DetailContent>
                                    {vm.date && formatDate(vm.date, `EEEE d MMMM yyyy 'at' h:mma`)}
                                </DetailContent>
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <Detail flexBasis={280}>
                                <DetailLabel>Location</DetailLabel>
                                <DetailContent>
                                    <LocationName>{vm.locationName}</LocationName>
                                    <div>{vm.locationAddress}</div>
                                </DetailContent>
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <Detail flexBasis={280}>
                                <DetailLabel>Pump</DetailLabel>
                                <DetailContent>{vm.locationPump}</DetailContent>
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <Detail flexBasis={170}>
                                <DetailLabel>Number plate</DetailLabel>
                                <LicensePlate>{vm.licensePlate}</LicensePlate>
                            </Detail>
                            <Detail flexBasis={110}>
                                <DetailLabel>Fuel Type</DetailLabel>
                                <DetailContent>{vm.fuelType}</DetailContent>
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <Detail flexBasis={100} noBorder={true}>
                                <DetailLabel>Cost per litre</DetailLabel>
                                <DetailContent>
                                    {getCurrencyFormatFromString(vm.price, 4)}
                                </DetailContent>
                            </Detail>
                            <Detail flexBasis={70} noBorder={true}>
                                <DetailLabel>Litres</DetailLabel>
                                <DetailContent>{formatVolume(vm.volume)}</DetailContent>
                            </Detail>
                            <Detail flexBasis={110} noBorder={true}>
                                <DetailLabel>Amount</DetailLabel>
                                <DetailContent>{getCurrencyFormat(vm.amount)}</DetailContent>
                            </Detail>
                        </DetailsRow>
                    </Details>
                    <ButtonBlock>
                        <PublicButton onClick={handleConfirm}>Pay now</PublicButton>
                    </ButtonBlock>
                    <LinkBlock>
                        <Link
                            id="incorrectDetailsTrigger"
                            href=""
                            onClick={vm.showIncorrectDetails}
                        >
                            These details are incorrect
                        </Link>
                    </LinkBlock>
                </DesktopWrapper>
            </PublicLayout>
            <Popover
                placement="auto"
                isOpen={vm.incorrectDetails}
                toggle={vm.hideModals}
                target="incorrectDetailsTrigger"
            >
                <PopoverHeader>
                    <PopoverCloseButton onClick={vm.hideModals}>
                        <SvgIcon size="xs" icon={timesIcon} />
                    </PopoverCloseButton>
                </PopoverHeader>
                <PopoverBody>
                    If you believe these details are incorrect then please call{' '}
                    <Link href={`tel:${helpPhone}`}>{helpPhone}</Link> for assistance.
                </PopoverBody>
            </Popover>
        </>
    );
});
