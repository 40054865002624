import { format, parseISO } from 'date-fns';

export const formatDate = (date: Date | string, s: string) => {
    if (typeof date === 'string') {
        date = parseISO(date);
    }
    return format(date, s)
        .replace(/AM\b/, 'am')
        .replace(/PM\b/, 'pm');
};

export const toLocalDate = (date: Date): LocalDate => {
    return (format(date, 'yyyy-MM-dd') as any) as LocalDate;
};

export const toLocalDateTime = (date: Date): LocalDateTime => {
    return (format(date, `yyyy-MM-dd'T'hh:mm:ss`) as any) as LocalDateTime;
};

export const getPrintTime = () => {
    return format(new Date(), 'yyyy-MM-dd_HH.mm.ss');
};
