import { apiInstance } from 'api/Api';
import { Organization, OrganizationParams } from 'components/Organization/Organization';
import { SnackbarControllerContext } from 'components/Snackbar/SnackbarController';
import { SnackbarControllerViewModel } from 'components/Snackbar/SnackbarControllerViewModel';
import { Location } from 'history';
import isEmpty from 'lodash.isempty';
import { ConfirmDetails } from 'pages/Details/ConfirmDetails';
import { ConfirmPlate } from 'pages/Details/ConfirmPlate';
import { Home } from 'pages/Home/Home';
import { NotFound } from 'pages/NotFound/NotFound';
import { AlreadyPaid } from 'pages/Payment/AlreadyPaid';
import { PaymentComplete } from 'pages/Payment/PaymentComplete';
import { PaymentFailed } from 'pages/Payment/PaymentFailed';
import { PrintTriage } from 'pages/PrintTriage/PrintTriage';
import { PrintTriageViewModel } from 'pages/PrintTriage/PrintTriageViewModel';
import { stringify } from 'query-string';
import React, { lazy, useContext } from 'react';
import { generatePath, match, matchPath, RouteComponentProps } from 'react-router';
import { PublicAppViewModel, PublicAppViewModelContext } from 'viewmodel/PublicAppViewModel';
import { AddressUpload } from '../pages/AddressUpload/AddressUpload';
import { AddressUploadViewModel } from '../pages/AddressUpload/AddressUploadViewModel';
import { GenerateLetters } from '../pages/PrintTriage/GenerateLetters';

export const HomeComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <Home vm={vm} />;
};

export const NotFoundComponent = (vm?: PublicAppViewModel) => {
    const contextVm = useContext(PublicAppViewModelContext);
    return <NotFound vm={vm || contextVm} />;
};

export const ConfirmPlateComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <ConfirmPlate vm={vm} />;
};

export const OrgComponent: React.FC<RouteComponentProps<OrganizationParams>> = (props) => {
    const vm = useContext(PublicAppViewModelContext);
    return <Organization {...props} vm={vm} />;
};
type PlateError = 'details' | 'err';

export const confirmPlateRoute = (
    orgKey: string,
    code?: string,
    reg?: string,
    err?: PlateError,
) => {
    const params = {
        ...(code && { code }),
        ...(reg && { reg }),
        ...(err && { err }),
    };

    if (isEmpty(params)) {
        return `/${orgKey}/pay`;
    } else {
        return `/${orgKey}/pay?${stringify(params)}`;
    }
};

export const ConfirmDetailsComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <ConfirmDetails vm={vm} />;
};

export const matchOrgPath = (loc: Location) => {
    return matchPath<OrganizationParams>(loc.pathname, {
        path: '/:orgKey',
    });
};

export const matchOrgKey = (loc: Location): string | null => {
    const orgMatch = matchOrgPath(loc);

    return orgMatch && orgMatch.params.orgKey;
};

export const matchConfirmDetails = (loc: Location) => {
    return matchPath(loc.pathname, { path: '/:orgKey/:referenceNumber/:licensePlate' }) as match<{
        orgKey: string;
        referenceNumber: string;
        licensePlate: string;
    }> | null;
};

export const confirmDetailsRoute = (
    orgKey: string,
    referenceNumber: string,
    licensePlate: string,
) => {
    referenceNumber = referenceNumber.trim();
    return `/${orgKey}/${referenceNumber}/${licensePlate}?m`;
};

export const PaymentCompleteComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <PaymentComplete vm={vm} />;
};

export const paymentCompleteRoute = (orgKey: string) => {
    return `/${orgKey}/paid`;
};

export const PaymentFailedComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <PaymentFailed vm={vm} />;
};

export const cannotPayRoute = (orgKey: string) => {
    return `/${orgKey}/oops`;
};

export const RecoveryAdminComponent = lazy(() => import('pages/RecoveryAdmin/RecoveryAdmin'));

export const adminRoute = () => {
    return '/auror/admin';
};
export const adminOrgRoute = () => {
    return '/auror/admin/:orgKey?';
};

export const AlreadyPaidComponent = () => {
    const vm = useContext(PublicAppViewModelContext);
    return <AlreadyPaid vm={vm} />;
};

export const alreadyPaidRoute = (orgKey: string) => {
    return `/${orgKey}/already-paid`;
};

export const PrintTriageComponent = () => {
    const snackbarVm = useContext(SnackbarControllerContext) || new SnackbarControllerViewModel();
    const vm = new PrintTriageViewModel(snackbarVm);
    return <PrintTriage vm={vm} />;
};

export const GenerateLettersComponent = () => {
    return <GenerateLetters api={apiInstance} />;
};

export const printTriageRoute = (orgKey?: string) => {
    return generatePath(`${adminRoute()}/generate-letters`);
};

export const addressUploadComponent = () => {
    const vm = new AddressUploadViewModel();
    return <AddressUpload vm={vm} />;
};

export const addressUploadRoute = () => {
    return `${adminRoute()}/address-upload`;
};
