import React from 'react';
import { AddressUploadViewModel } from './AddressUploadViewModel';
import { observer } from 'mobx-react-lite';
import { Button, CustomInput, FormGroup, Label } from 'reactstrap';
import { OperationState } from '../../viewmodel/PromiseViewModel';
import styled, { css, ThemeProvider } from 'styled-components';
import Spinner from 'reactstrap/lib/Spinner';
import { defaultTheme } from '../../styles/theme';

const Content = styled.div`
    padding-top: 2rem;
    display: flex;
    justify-content: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StatusMessageContainer = styled.div<{ color?: 'success' | 'warning' | 'danger' }>`
    margin-top: 1rem;
    ${({ color, theme }) =>
        color &&
        css`
            color: ${theme[color + 'Color']};
        `}
`;

export interface AddressUploadComponent {
    vm: AddressUploadViewModel;
}

export const AddressUpload: React.FC<AddressUploadComponent> = observer(({ vm }) => {
    const state = vm.upload?.state;

    return (
        <ThemeProvider theme={defaultTheme}>
            <Content>
                <Container>
                    <FormGroup>
                        <Label for="addressUpload">Upload address file:</Label>
                        <CustomInput
                            type="file"
                            onChange={vm.azureStorageFileUploader}
                            id="addressUpload"
                            name="addressUpload"
                            valid={state === OperationState.Done}
                            invalid={state === OperationState.Error}
                            disabled={state === OperationState.Loading}
                        />
                        {state === OperationState.Error ? (
                            <StatusMessageContainer color="danger">
                                {vm.statusMessage}{' '}
                                <Button color="danger" size="sm" onClick={vm.retryFileUpload}>
                                    Retry
                                </Button>
                            </StatusMessageContainer>
                        ) : state === OperationState.Loading ? (
                            <StatusMessageContainer color="warning">
                                <Spinner size="sm" color="warning" /> {vm.statusMessage}
                            </StatusMessageContainer>
                        ) : state === OperationState.Done ? (
                            <StatusMessageContainer color="success">
                                {vm.statusMessage}
                            </StatusMessageContainer>
                        ) : (
                            vm.statusMessage && (
                                <StatusMessageContainer>{vm.statusMessage}</StatusMessageContainer>
                            )
                        )}
                    </FormGroup>
                </Container>
            </Content>
        </ThemeProvider>
    );
});
