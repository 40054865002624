import styled, { css } from 'styled-components';

export const Container = styled.div<{ wide?: boolean }>`
    width: 280px;
    padding-bottom: 2rem;
    ${props =>
        props.wide &&
        css`
            @media (min-width: 768px) {
                width: 640px;
            }
        `}
`;

export const Body = styled.div`
    background: #f7f8f9;
    color: ${props => props.theme.textColor};
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    min-height: 100vh;
`;

export const H3 = styled.div`
    font-weight: ${props => props.theme.bold};
    font-size: 1rem;
    margin: 0 0 1rem;
`;

export const Logo = styled.img`
    display: block;
    margin: 1rem auto;
    max-width: ${props => props.theme.logoLength || 96 }px;
`;

export const P = styled.p`
    font-size: ${props => props.theme.smallFontSize};
    line-height: 1.4;
`;

export const TinyP = styled.p`
    font-size: ${props => props.theme.tinyFontSize};
    line-height: 1.4;
`;

export const Label = styled.label`
    display: block;
    font-size: ${props => props.theme.smallFontSize};
    font-weight: ${props => props.theme.bold};
    margin: 0.25rem 0;
    &:first-child {
        margin-top: 0;
    }
`;

export const Input = styled.input`
    border: ${props => props.theme.inputBorder};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 1rem;
    margin: 0 0 1rem;
    padding: 0.4375rem 0.75rem;
    width: 100%;
    &::placeholder {
        color: ${props => props.theme.placeholderColor};
    }
`;

export const PublicButton = styled.button`
    align-items: center;
    background: ${props => props.theme.primaryColor};
    border: none;
    border-radius: 1000px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 1rem;
    font-weight: 600;
    justify-content: center;
    padding: 0.5rem 0.6875rem;
    width: 100%;
    &:disabled,
    &.disabled {
        background: ${props => props.theme.primaryColor};
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover {
        background: ${props => props.theme.primaryHoverColor};
        color: #fff;
        text-decoration: none;
    }
    &:active,
    &:focus {
        background: ${props => props.theme.primaryHoverColor};
    }
    @media (min-width: 768px) {
        align-self: center;
        max-width: 400px;
    }
`;

export const PublicButtonLink = PublicButton.withComponent('a');

export const Link = styled.a`
    background: none;
    border: none;
    color: ${props => props.theme.linkColor};
    cursor: pointer;
    display: inline-block;
    font-size: ${props => props.theme.smallFontSize};
    font-weight: ${props => props.theme.bold};
    white-space: nowrap;
    &:not([href]):not([tabindex]) {
        color: ${props => props.theme.linkColor};

        &:hover,
        &:active {
            color: ${props => props.theme.linkHoverColor};
        }
    }
    &:hover,
    &:active {
        color: ${props => props.theme.linkHoverColor};
    }
`;

export const Form = styled.form``;

export const ErrorBlock = styled.div`
    border: 1px solid ${props => props.theme.errorColor};
    border-radius: ${props => props.theme.borderRadius};
    color: ${props => props.theme.errorColor};
    font-size: ${props => props.theme.smallFontSize};
    line-height: 1.2;
    margin: 1rem 0;
    padding: 0.5rem;
    white-space: pre-wrap;
`;
