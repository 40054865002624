import { PublicLayout } from 'components/Layout';
import { HR, PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React from 'react';
import { H3, Logo, P } from 'styles';
import { getOrgDetails } from 'styles/theme';

export const AlreadyPaid: React.FC<PublicAppComponent> = ({ vm }) => {
    const { logo } = getOrgDetails(vm.organizationKey);
    return (
        <PublicLayout sendEvent={vm.sendChurnZeroEvent}>
            <Logo alt="" src={logo} />
            <H3>Good Work!</H3>
            <P>It looks like this payment has already been made.</P>
            <HR />
        </PublicLayout>
    );
};
