import PrintPage from 'components/Printing/PrintPage';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { createGlobalStyle, StyleSheetManager } from 'styled-components';
import { toRecoveryKey } from 'utils/recovery';
import { PrintDocumentViewModel } from './PrintDocumentViewModel';

interface PrintDocumentComponent {
    vm: PrintDocumentViewModel;
}

const GlobalPrintStyle = createGlobalStyle`
@media print {
    @page {
        size: auto;
        margin: 0;
    }
    body {
        margin: 0;
        padding: 0;
        min-width: unset !important;
        -webkit-print-color-adjust: exact;
    }
}
`;

export const PrintDocument: React.FC<PrintDocumentComponent> = observer(({ vm }) => {
    return (
        <>
            {vm.recoveries.map(recovery => (
                <PrintPage key={toRecoveryKey(recovery)} recovery={recovery} />
            ))}
        </>
    );
});

export const PrintDocumentFrameContent: React.FC<{
    vm: PrintDocumentViewModel;
    head: HTMLHeadElement;
}> = observer(({ vm, head }) => {
    return (
        <StyleSheetManager target={head}>
            <>
                <GlobalPrintStyle />
                <PrintDocument vm={vm} />
            </>
        </StyleSheetManager>
    );
});
