import * as queryString from 'query-string';

// This type expresses that the current type *might* be an instance of a TErrorData, and that you can look at the value in $$errorType to check
export type PossibleApiError<TErrorData extends { $$errorType: string }> =
    | Partial<Pick<TErrorData, '$$errorType'>>
    | { $$errorType: string };

export type QueryStringParameters =
    | string
    | number
    | boolean
    | Instant
    | LocalDate
    | LocalTime
    | undefined
    | null;

export abstract class ApiBase {
    abstract fetch<T>(actionName: string, url: string, init: RequestInit): CancellablePromise<T>;

    createQueryParameters(o: { [key: string]: QueryStringParameters | QueryStringParameters[] }) {
        return '?' + queryString.stringify(o);
    }

    access_Confirm(
        accessRequest: AccessRequest,
    ): CancellablePromise<
        | (DebtDetailsRepresentation &
              PossibleApiError<DebtAlreadyPaidApiError> &
              PossibleApiError<DebtInInvalidStateApiError>) /*Usually will return a DebtDetailsRepresentation but the following errors should be expected*/
        | DebtAlreadyPaidApiError /* Expect an error of type DebtAlreadyPaidApiError*/
        | DebtInInvalidStateApiError /* Expect an error of type DebtInInvalidStateApiError*/
    > {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: accessRequest
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(accessRequest);

        return this.fetch('access_Confirm', `api/spa/Access/confirm`, init);
    }

    analytics_TrackChurnZeroEvent(data: EventDataRequest): CancellablePromise<void> {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: data
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(data);

        return this.fetch('analytics_TrackChurnZeroEvent', `api/spa/Analytics/churnzero`, init);
    }

    aurorAdmin_UploadDebtPostalAddresses(
        createDebtPostalAddresses: CreateDebtPostalAddressesRequest,
    ): CancellablePromise<
        | (CreateDebtPostalAddressesResponse &
              PossibleApiError<InvalidBatchApiError>) /*Usually will return a CreateDebtPostalAddressesResponse but the following errors should be expected*/
        | InvalidBatchApiError /* Expect an error of type InvalidBatchApiError*/
    > {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: createDebtPostalAddresses
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(createDebtPostalAddresses);

        return this.fetch(
            'aurorAdmin_UploadDebtPostalAddresses',
            `api/spa/auroradmin/AurorAdmin/uploadAddresses`,
            init,
        );
    }

    aurorAdmin_GetDebtDetailsByStates(
        states?: Array<DebtState>,
        organizationKey?: string,
        page?: number,
        pageSize?: number,
    ): CancellablePromise<DebtDetailsByStatesResponse> {
        const init: RequestInit = { method: 'GET' };
        return this.fetch(
            'aurorAdmin_GetDebtDetailsByStates',
            `api/spa/auroradmin/AurorAdmin/debtsByStates${this.createQueryParameters({
                states,
                organizationKey,
                page,
                pageSize,
            })}`,
            init,
        );
    }

    aurorAdmin_GetDebtDetailsByStatesAndPrintIndex(
        states?: Array<DebtState>,
        organizationKey?: string,
        page?: number,
        pageSize?: number,
    ): CancellablePromise<DebtDetailsByStatesResponse> {
        const init: RequestInit = { method: 'GET' };
        return this.fetch(
            'aurorAdmin_GetDebtDetailsByStatesAndPrintIndex',
            `api/spa/auroradmin/AurorAdmin/debtsByStatesAndPrintIndex${this.createQueryParameters({
                states,
                organizationKey,
                page,
                pageSize,
            })}`,
            init,
        );
    }

    aurorAdmin_GetAllDebtDetails(organizationKey?: string): CancellablePromise<Array<DebtDetails>> {
        const init: RequestInit = { method: 'GET' };
        return this.fetch(
            'aurorAdmin_GetAllDebtDetails',
            `api/spa/auroradmin/AurorAdmin/allDebts${this.createQueryParameters({
                organizationKey,
            })}`,
            init,
        );
    }

    aurorAdmin_GetDebtLetterDetails(
        request: GetDebtLetterDetailsRequest,
    ): CancellablePromise<
        | (Array<DebtLetterDetails> &
              PossibleApiError<InvalidDebtStateApiError>) /*Usually will return a Array<DebtLetterDetails> but the following errors should be expected*/
        | InvalidDebtStateApiError /* Expect an error of type InvalidDebtStateApiError*/
    > {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: request
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(request);

        return this.fetch(
            'aurorAdmin_GetDebtLetterDetails',
            `api/spa/auroradmin/AurorAdmin/debtLetterDetails`,
            init,
        );
    }

    aurorAdmin_FireDebtStateTrigger(
        request: FireDebtStateTriggerRequest,
    ): CancellablePromise<Array<DebtStateChangeResult>> {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: request
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(request);

        return this.fetch(
            'aurorAdmin_FireDebtStateTrigger',
            `api/spa/auroradmin/AurorAdmin/fireDebtStateTriggers`,
            init,
        );
    }

    aurorAdmin_StartLettersGeneration(
        outputFolderName?: string,
    ): CancellablePromise<DebtLettersGenerationStats> {
        const init: RequestInit = { method: 'POST' };
        return this.fetch(
            'aurorAdmin_StartLettersGeneration',
            `api/spa/auroradmin/AurorAdmin/startLettersGeneration${this.createQueryParameters({
                outputFolderName,
            })}`,
            init,
        );
    }

    aurorAdmin_OverrideDebtState(request: OverrideDebtStateRequest): CancellablePromise<void> {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: request
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(request);

        return this.fetch(
            'aurorAdmin_OverrideDebtState',
            `api/spa/auroradmin/AurorAdmin/overrideState`,
            init,
        );
    }

    aurorAdmin_CreateFile(
        request: CreateFileRequest,
    ): CancellablePromise<
        | (FileResponse &
              PossibleApiError<CreateFileTypeInvalidApiError>) /*Usually will return a FileResponse but the following errors should be expected*/
        | CreateFileTypeInvalidApiError /* Expect an error of type CreateFileTypeInvalidApiError*/
    > {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: request
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(request);

        return this.fetch('aurorAdmin_CreateFile', `api/spa/auroradmin/AurorAdmin/create`, init);
    }

    payment_Create(
        createPaymentRequest: CreatePaymentRequest,
    ): CancellablePromise<
        | (CompletedPaymentRepresentation &
              PossibleApiError<CreatePaymentApiError>) /*Usually will return a CompletedPaymentRepresentation but the following errors should be expected*/
        | CreatePaymentApiError /* Expect an error of type CreatePaymentApiError*/
    > {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: createPaymentRequest
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(createPaymentRequest);

        return this.fetch('payment_Create', `api/spa/Payment/Create`, init);
    }
}

export interface AccessRequest {
    licensePlate: string | null;
    referenceNumber: string | null;
}

export enum BatchStatusResponseCode {
    Success = 'Success',
    FailedSafeToReprocess = 'FailedSafeToReprocess',
    FailedUnsafeToReprocess = 'FailedUnsafeToReprocess',
}

export type BatchStatusResponseCodeKeyType = keyof typeof BatchStatusResponseCode;

export const BatchStatusResponseCodeValueDescriptionMap: {
    [key in BatchStatusResponseCodeKeyType]: string;
} = {
    [BatchStatusResponseCode.Success]: `Success`,
    [BatchStatusResponseCode.FailedSafeToReprocess]: `FailedSafeToReprocess`,
    [BatchStatusResponseCode.FailedUnsafeToReprocess]: `FailedUnsafeToReprocess`,
};

export enum ChurnZeroEventName {
    AlfiepayAlreadyPaid = 'AlfiepayAlreadyPaid',
    AlfiepayCallSupport = 'AlfiepayCallSupport',
    AlfiepayClickedIncorrectDetails = 'AlfiepayClickedIncorrectDetails',
    AlfiepayClickedPay = 'AlfiepayClickedPay',
    AlfiepayConfirmedDetails = 'AlfiepayConfirmedDetails',
    AlfiepayEnteredCode = 'AlfiepayEnteredCode',
    AlfiepayInvalidCode = 'AlfiepayInvalidCode',
    AlfiepayPaid = 'AlfiepayPaid',
    AlfiepayPaidManually = 'AlfiepayPaidManually',
    AlfiepayPaidText = 'AlfiepayPaidText',
    AlfiepayPaymentFailed = 'AlfiepayPaymentFailed',
    AlfiepayReceiptRequested = 'AlfiepayReceiptRequested',
    AlfiepayViewCodePage = 'AlfiepayViewCodePage',
    AlfiepayViewDetailsPage = 'AlfiepayViewDetailsPage',
    AlfiepayViewHomePage = 'AlfiepayViewHomePage',
    AlfiepayViewNotFoundPage = 'AlfiepayViewNotFoundPage',
    AlfiepayViewPaymentPage = 'AlfiepayViewPaymentPage',
    AlfiepayViewPaymentFailedPage = 'AlfiepayViewPaymentFailedPage',
    AlfiepayViewPaymentSuccessfulPage = 'AlfiepayViewPaymentSuccessfulPage',
    AlfiepayTextMessageSent = 'AlfiepayTextMessageSent',
    AlfiepayTextMessageSent1 = 'AlfiepayTextMessageSent1',
    AlfiepayTextMessageSent2 = 'AlfiepayTextMessageSent2',
    AlfiepayLetterSent = 'AlfiepayLetterSent',
    AlfiepayLetterSent1 = 'AlfiepayLetterSent1',
    AlfiepayLetterSent2 = 'AlfiepayLetterSent2',
}

export type ChurnZeroEventNameKeyType = keyof typeof ChurnZeroEventName;

export const ChurnZeroEventNameValueDescriptionMap: { [key in ChurnZeroEventNameKeyType]: string } =
    {
        [ChurnZeroEventName.AlfiepayAlreadyPaid]: `Alfiepay: Already Paid`,
        [ChurnZeroEventName.AlfiepayCallSupport]: `Alfiepay: Call Support`,
        [ChurnZeroEventName.AlfiepayClickedIncorrectDetails]: `Alfiepay: Clicked Incorrect Details`,
        [ChurnZeroEventName.AlfiepayClickedPay]: `Alfiepay: Clicked Pay`,
        [ChurnZeroEventName.AlfiepayConfirmedDetails]: `Alfiepay: Confirmed Details`,
        [ChurnZeroEventName.AlfiepayEnteredCode]: `Alfiepay: Entered Code`,
        [ChurnZeroEventName.AlfiepayInvalidCode]: `Alfiepay: Invalid Code`,
        [ChurnZeroEventName.AlfiepayPaid]: `Alfiepay: Paid`,
        [ChurnZeroEventName.AlfiepayPaidManually]: `Alfiepay: Paid Manually`,
        [ChurnZeroEventName.AlfiepayPaidText]: `Alfiepay: Paid Text`,
        [ChurnZeroEventName.AlfiepayPaymentFailed]: `Alfiepay: Payment Failed`,
        [ChurnZeroEventName.AlfiepayReceiptRequested]: `Alfiepay: Receipt Requested`,
        [ChurnZeroEventName.AlfiepayViewCodePage]: `Alfiepay: View Code Page`,
        [ChurnZeroEventName.AlfiepayViewDetailsPage]: `Alfiepay: View Details Page`,
        [ChurnZeroEventName.AlfiepayViewHomePage]: `Alfiepay: View Home Page`,
        [ChurnZeroEventName.AlfiepayViewNotFoundPage]: `Alfiepay: View Not Found Page`,
        [ChurnZeroEventName.AlfiepayViewPaymentPage]: `Alfiepay: View Payment Page`,
        [ChurnZeroEventName.AlfiepayViewPaymentFailedPage]: `Alfiepay: View Payment Failed Page`,
        [ChurnZeroEventName.AlfiepayViewPaymentSuccessfulPage]: `Alfiepay: View Payment Successful Page`,
        [ChurnZeroEventName.AlfiepayTextMessageSent]: `Alfiepay: Text sent`,
        [ChurnZeroEventName.AlfiepayTextMessageSent1]: `Alfiepay: 1st text sent`,
        [ChurnZeroEventName.AlfiepayTextMessageSent2]: `Alfiepay: 2nd text sent`,
        [ChurnZeroEventName.AlfiepayLetterSent]: `Alfiepay: Letter sent`,
        [ChurnZeroEventName.AlfiepayLetterSent1]: `Alfiepay: 1st letter sent`,
        [ChurnZeroEventName.AlfiepayLetterSent2]: `Alfiepay: 2nd letter sent`,
    };

export interface CompletedPaymentRepresentation {
    receiptUrl: string | null;
}

export interface CreateDebtPostalAddressError {
    description: string;
    licensePlate: string;
}

export interface CreateDebtPostalAddressesRequest {
    motochekFileId: string;
    payload: Array<CreateDebtPostalAddressPayload>;
}

export interface CreateDebtPostalAddressesResponse {
    batchStatusResponseCode: BatchStatusResponseCode;
    errors: Array<CreateDebtPostalAddressError>;
    stateUpdates: Array<DebtStateUpdateRepresentation>;
    warnings: Array<CreateDebtPostalAddressWarning>;
}

export interface CreateDebtPostalAddressPayload {
    address: DebtPostalAddressRepresentation | null;
    licensePlate: string;
}

export interface CreateDebtPostalAddressWarning {
    currentDebtState: DebtState | null;
    description: string;
    licensePlate: string;
    warningReason: DebtPostalAddressWarningReason;
}

export interface CreateFileRequest {
    fileId: string;
    fileName: string;
    fileType: FileType;
}

export interface CreateFileTypeInvalidApiError {
    $$errorType: 'CreateFileTypeInvalidApiError';
    userMessage: string;
}

export namespace CreateFileTypeInvalidApiError {
    export function isInstance(
        o: PossibleApiError<CreateFileTypeInvalidApiError>,
    ): o is CreateFileTypeInvalidApiError {
        return o.$$errorType === 'CreateFileTypeInvalidApiError';
    }
}

export interface CreatePaymentApiError {
    $$errorType: 'CreatePaymentApiError';
    messageForUser: string | null;
    saveEventErrorType: PaymentCreationStatus;
}

export namespace CreatePaymentApiError {
    export function isInstance(
        o: PossibleApiError<CreatePaymentApiError>,
    ): o is CreatePaymentApiError {
        return o.$$errorType === 'CreatePaymentApiError';
    }
}

export interface CreatePaymentRequest {
    debtIdentifier: string | null;
    emailAddress: string | null;
    tokenizationResult: StripeTokenizationResultRepresentation | null;
}

export interface DebtAlreadyPaidApiError {
    $$errorType: 'DebtAlreadyPaidApiError';
}

export namespace DebtAlreadyPaidApiError {
    export function isInstance(
        o: PossibleApiError<DebtAlreadyPaidApiError>,
    ): o is DebtAlreadyPaidApiError {
        return o.$$errorType === 'DebtAlreadyPaidApiError';
    }
}

export interface DebtDetails {
    amountIncludingCollectionFee: number /* WARNING: Server type Decimal has greater precision that JS number */;
    amountOwedForGoods: number /* WARNING: Server type Decimal has greater precision that JS number */;
    dateOfIncident: LocalDateTime;
    debtIdentifier: string;
    debtState: DebtState;
    incidentDetails: { [key in IncidentDetailsPropsBagKey]?: string | null } | null;
    licensePlate: string;
    organizationKey: string;
    organizationName: string;
}

export interface DebtDetailsByStatesResponse {
    debts: Array<DebtDetails>;
    page: number;
    pageSize: number;
    total: number;
}

export interface DebtDetailsRepresentation {
    amount: number /* WARNING: Server type Decimal has greater precision that JS number */;
    dateOfIncident: Instant;
    debtIdentifier: string;
    fawkesImage: FileRepresentation;
    incidentDetails: { [key in IncidentDetailsPropsBagKey]?: string | null } | null;
    licensePlate: string;
    organizationKey: string;
}

export interface DebtInInvalidStateApiError {
    $$errorType: 'DebtInInvalidStateApiError';
}

export namespace DebtInInvalidStateApiError {
    export function isInstance(
        o: PossibleApiError<DebtInInvalidStateApiError>,
    ): o is DebtInInvalidStateApiError {
        return o.$$errorType === 'DebtInInvalidStateApiError';
    }
}

export interface DebtLetterDetails {
    amount: number /* WARNING: Server type Decimal has greater precision that JS number */;
    collectionFee:
        | number /* WARNING: Server type Decimal has greater precision that JS number */
        | null;
    dateOfIncident: LocalDateTime;
    debtIdentifier: string;
    fawkesImage: FileRepresentation | null;
    incidentDetails: { [key in IncidentDetailsPropsBagKey]?: string | null } | null;
    index: number;
    letterTemplateCode: LetterTemplateCode;
    licensePlate: string;
    organizationKey: string;
    pdfGenerationDate: LocalDate;
    postalAddress: string;
    printingBatch: number /* WARNING: Server type Int64 has greater precision that JS number */;
    recipient: string;
    secondDebtLetterDetails: SecondDebtLetterDetails | null;
}

export interface DebtLettersGenerationStats {
    batchesCount: number;
    batchSize: number;
    fileName: string | null;
    lettersCount: number;
}

export interface DebtPostalAddressRepresentation {
    address: string;
    motochekAddress: MotochekAddressRepresentation;
    recipient: string;
}

export enum DebtPostalAddressWarningReason {
    NotInCorrectState = 'NotInCorrectState',
    AddressWasInvalid = 'AddressWasInvalid',
    DebtDoesNotExist = 'DebtDoesNotExist',
}

export type DebtPostalAddressWarningReasonKeyType = keyof typeof DebtPostalAddressWarningReason;

export const DebtPostalAddressWarningReasonValueDescriptionMap: {
    [key in DebtPostalAddressWarningReasonKeyType]: string;
} = {
    [DebtPostalAddressWarningReason.NotInCorrectState]: `NotInCorrectState`,
    [DebtPostalAddressWarningReason.AddressWasInvalid]: `AddressWasInvalid`,
    [DebtPostalAddressWarningReason.DebtDoesNotExist]: `DebtDoesNotExist`,
};

export enum DebtState {
    InDebt = 'InDebt',
    Settled = 'Settled',
    HandledExternally = 'HandledExternally',
    NotifyExpired = 'NotifyExpired',
    NotifiedExpired = 'NotifiedExpired',
    New = 'New',
    NeedPostalAddress = 'NeedPostalAddress',
    WaitingForPostalAddress = 'WaitingForPostalAddress',
    WaitingToSendSMS1 = 'WaitingToSendSMS1',
    SendingSMS1 = 'SendingSMS1',
    WaitingToSendSMS2 = 'WaitingToSendSMS2',
    SendingSMS2 = 'SendingSMS2',
    WaitingToSendLetter1 = 'WaitingToSendLetter1',
    ReadyToSendLetter1 = 'ReadyToSendLetter1',
    ReadyToSendLetter2 = 'ReadyToSendLetter2',
    WaitingToSendLetter2 = 'WaitingToSendLetter2',
    AllPossibleCommunicationsSent = 'AllPossibleCommunicationsSent',
    NoCommunicationsSent = 'NoCommunicationsSent',
    NotEligibleForRecovery = 'NotEligibleForRecovery',
    NeedOrangeboxInputLetter1 = 'NeedOrangeboxInputLetter1',
    NeedOrangeboxInputLetter2 = 'NeedOrangeboxInputLetter2',
    WaitingForOrangeboxInputLetter1 = 'WaitingForOrangeboxInputLetter1',
    WaitingForOrangeboxInputLetter2 = 'WaitingForOrangeboxInputLetter2',
}

export type DebtStateKeyType = keyof typeof DebtState;

export const DebtStateValueDescriptionMap: { [key in DebtStateKeyType]: string } = {
    [DebtState.InDebt]: `InDebt`,
    [DebtState.Settled]: `Settled`,
    [DebtState.HandledExternally]: `HandledExternally`,
    [DebtState.NotifyExpired]: `NotifyExpired`,
    [DebtState.NotifiedExpired]: `NotifiedExpired`,
    [DebtState.New]: `New`,
    [DebtState.NeedPostalAddress]: `NeedPostalAddress`,
    [DebtState.WaitingForPostalAddress]: `WaitingForPostalAddress`,
    [DebtState.WaitingToSendSMS1]: `WaitingToSendSMS1`,
    [DebtState.SendingSMS1]: `SendingSMS1`,
    [DebtState.WaitingToSendSMS2]: `WaitingToSendSMS2`,
    [DebtState.SendingSMS2]: `SendingSMS2`,
    [DebtState.WaitingToSendLetter1]: `WaitingToSendLetter1`,
    [DebtState.ReadyToSendLetter1]: `ReadyToSendLetter1`,
    [DebtState.ReadyToSendLetter2]: `ReadyToSendLetter2`,
    [DebtState.WaitingToSendLetter2]: `WaitingToSendLetter2`,
    [DebtState.AllPossibleCommunicationsSent]: `AllPossibleCommunicationsSent`,
    [DebtState.NoCommunicationsSent]: `NoCommunicationsSent`,
    [DebtState.NotEligibleForRecovery]: `NotEligibleForRecovery`,
    [DebtState.NeedOrangeboxInputLetter1]: `NeedOrangeboxInputLetter1`,
    [DebtState.NeedOrangeboxInputLetter2]: `NeedOrangeboxInputLetter2`,
    [DebtState.WaitingForOrangeboxInputLetter1]: `WaitingForOrangeboxInputLetter1`,
    [DebtState.WaitingForOrangeboxInputLetter2]: `WaitingForOrangeboxInputLetter2`,
};

export interface DebtStateChangeResult {
    currentState: DebtState | null;
    debtIdentifier: string;
    errorMessage: string | null;
}

export enum DebtStateTrigger {
    Waited = 'Waited',
    PaymentReceived = 'PaymentReceived',
    ExternallyHandled = 'ExternallyHandled',
    FullyPaidInFawkes = 'FullyPaidInFawkes',
    CommunicationSent = 'CommunicationSent',
    CommunicationFailed = 'CommunicationFailed',
    FoundMobileNumber = 'FoundMobileNumber',
    CantFindMobileNumber = 'CantFindMobileNumber',
    RequestedPostalAddress = 'RequestedPostalAddress',
    FoundPostalAddress = 'FoundPostalAddress',
    CantFindPostalAddressAndPriorCommunicationSent = 'CantFindPostalAddressAndPriorCommunicationSent',
    Notified = 'Notified',
    Cancelled = 'Cancelled',
    PartiallyPaidExternally = 'PartiallyPaidExternally',
    Expired = 'Expired',
    ManualOverride = 'ManualOverride',
    CantFindPostalAddressAndNoPriorCommunicationSent = 'CantFindPostalAddressAndNoPriorCommunicationSent',
    RecoveryDeletedInFawkes = 'RecoveryDeletedInFawkes',
    RejectedPrintDetails = 'RejectedPrintDetails',
    IntelEventDeletedInFawkes = 'IntelEventDeletedInFawkes',
    RecoveryNoLongerMeetsDebtCriteria = 'RecoveryNoLongerMeetsDebtCriteria',
    RecoveryMeetsDebtCriteria = 'RecoveryMeetsDebtCriteria',
    VehicleInformationChanged = 'VehicleInformationChanged',
    CollectionFeeAdded = 'CollectionFeeAdded',
    RequestedOrangeboxInput = 'RequestedOrangeboxInput',
    ReceivedOrangeboxInput = 'ReceivedOrangeboxInput',
    AddressChangedByOrangebox = 'AddressChangedByOrangebox',
    RejectedByOrangebox = 'RejectedByOrangebox',
    AllSmsSent = 'AllSmsSent',
    AllSmsFailed = 'AllSmsFailed',
    PhoneNumberChanged = 'PhoneNumberChanged',
}

export type DebtStateTriggerKeyType = keyof typeof DebtStateTrigger;

export const DebtStateTriggerValueDescriptionMap: { [key in DebtStateTriggerKeyType]: string } = {
    [DebtStateTrigger.Waited]: `Waited`,
    [DebtStateTrigger.PaymentReceived]: `PaymentReceived`,
    [DebtStateTrigger.ExternallyHandled]: `ExternallyHandled`,
    [DebtStateTrigger.FullyPaidInFawkes]: `FullyPaidInFawkes`,
    [DebtStateTrigger.CommunicationSent]: `CommunicationSent`,
    [DebtStateTrigger.CommunicationFailed]: `CommunicationFailed`,
    [DebtStateTrigger.FoundMobileNumber]: `FoundMobileNumber`,
    [DebtStateTrigger.CantFindMobileNumber]: `CantFindMobileNumber`,
    [DebtStateTrigger.RequestedPostalAddress]: `RequestedPostalAddress`,
    [DebtStateTrigger.FoundPostalAddress]: `FoundPostalAddress`,
    [DebtStateTrigger.CantFindPostalAddressAndPriorCommunicationSent]: `CantFindPostalAddressAndPriorCommunicationSent`,
    [DebtStateTrigger.Notified]: `Notified`,
    [DebtStateTrigger.Cancelled]: `Cancelled`,
    [DebtStateTrigger.PartiallyPaidExternally]: `PartiallyPaidExternally`,
    [DebtStateTrigger.Expired]: `Expired`,
    [DebtStateTrigger.ManualOverride]: `ManualOverride`,
    [DebtStateTrigger.CantFindPostalAddressAndNoPriorCommunicationSent]: `CantFindPostalAddressAndNoPriorCommunicationSent`,
    [DebtStateTrigger.RecoveryDeletedInFawkes]: `RecoveryDeletedInFawkes`,
    [DebtStateTrigger.RejectedPrintDetails]: `RejectedPrintDetails`,
    [DebtStateTrigger.IntelEventDeletedInFawkes]: `IntelEventDeletedInFawkes`,
    [DebtStateTrigger.RecoveryNoLongerMeetsDebtCriteria]: `RecoveryNoLongerMeetsDebtCriteria`,
    [DebtStateTrigger.RecoveryMeetsDebtCriteria]: `RecoveryMeetsDebtCriteria`,
    [DebtStateTrigger.VehicleInformationChanged]: `VehicleInformationChanged`,
    [DebtStateTrigger.CollectionFeeAdded]: `CollectionFeeAdded`,
    [DebtStateTrigger.RequestedOrangeboxInput]: `RequestedOrangeboxInput`,
    [DebtStateTrigger.ReceivedOrangeboxInput]: `ReceivedOrangeboxInput`,
    [DebtStateTrigger.AddressChangedByOrangebox]: `AddressChangedByOrangebox`,
    [DebtStateTrigger.RejectedByOrangebox]: `RejectedByOrangebox`,
    [DebtStateTrigger.AllSmsSent]: `AllSmsSent`,
    [DebtStateTrigger.AllSmsFailed]: `AllSmsFailed`,
    [DebtStateTrigger.PhoneNumberChanged]: `PhoneNumberChanged`,
};

export interface DebtStateUpdateRepresentation {
    currentDebtState: DebtState;
    debtIdentifier: string;
}

export interface EventDataRequest {
    description: string | null;
    eventData: string | null;
    eventName: ChurnZeroEventName;
    eventValue: number | null;
    token: string | null;
}

export interface FileRepresentation {
    clientId: string | null;
    imageFullSizeUrl: string /* Uri */ | null;
    thumbnailLargeUrl: string /* Uri */ | null;
    thumbnailMediumUrl: string /* Uri */ | null;
    thumbnailSmallUrl: string /* Uri */ | null;
}

export interface FileResponse {
    uploadUrl: string;
}

export enum FileType {
    Other = 'Other',
    Orangebox = 'Orangebox',
    MotochekResponse = 'MotochekResponse',
}

export type FileTypeKeyType = keyof typeof FileType;

export const FileTypeValueDescriptionMap: { [key in FileTypeKeyType]: string } = {
    [FileType.Other]: `Other`,
    [FileType.Orangebox]: `Orangebox`,
    [FileType.MotochekResponse]: `MotochekResponse`,
};

export interface FireDebtStateTriggerRequest {
    debtIdentifiers: Array<string | null> | null;
    trigger: DebtStateTrigger;
}

export interface GetDebtLetterDetailsRequest {
    debtIdentifiers: Array<string | null> | null;
}

export enum IncidentDetailsPropsBagKey {
    SiteAddress = 'SiteAddress',
    SiteName = 'SiteName',
    LocationWithinSite = 'LocationWithinSite',
    ProductName = 'ProductName',
    ProductQuantity = 'ProductQuantity',
    ProductPrice = 'ProductPrice',
    SitePhoneNumber = 'SitePhoneNumber',
}

export type IncidentDetailsPropsBagKeyKeyType = keyof typeof IncidentDetailsPropsBagKey;

export const IncidentDetailsPropsBagKeyValueDescriptionMap: {
    [key in IncidentDetailsPropsBagKeyKeyType]: string;
} = {
    [IncidentDetailsPropsBagKey.SiteAddress]: `SiteAddress`,
    [IncidentDetailsPropsBagKey.SiteName]: `SiteName`,
    [IncidentDetailsPropsBagKey.LocationWithinSite]: `LocationWithinSite`,
    [IncidentDetailsPropsBagKey.ProductName]: `ProductName`,
    [IncidentDetailsPropsBagKey.ProductQuantity]: `ProductQuantity`,
    [IncidentDetailsPropsBagKey.ProductPrice]: `ProductPrice`,
    [IncidentDetailsPropsBagKey.SitePhoneNumber]: `SitePhoneNumber`,
};

export interface InvalidBatchApiError {
    batchStatus: BatchStatusResponseCode;
    $$errorType: 'InvalidBatchApiError';
}

export namespace InvalidBatchApiError {
    export function isInstance(
        o: PossibleApiError<InvalidBatchApiError>,
    ): o is InvalidBatchApiError {
        return o.$$errorType === 'InvalidBatchApiError';
    }
}

export interface InvalidDebtStateApiError {
    details: Array<InvalidDebtStateDetails>;
    $$errorType: 'InvalidDebtStateApiError';
}

export namespace InvalidDebtStateApiError {
    export function isInstance(
        o: PossibleApiError<InvalidDebtStateApiError>,
    ): o is InvalidDebtStateApiError {
        return o.$$errorType === 'InvalidDebtStateApiError';
    }
}

export interface InvalidDebtStateDetails {
    debtIdentifier: string;
    description: string;
}

export enum LetterTemplateCode {
    Default = 'Default',
    BpCorporate = 'BpCorporate',
    BpFranchise = 'BpFranchise',
    Z = 'Z',
    Mobil = 'Mobil',
    Caltex = 'Caltex',
    Npd = 'Npd',
}

export type LetterTemplateCodeKeyType = keyof typeof LetterTemplateCode;

export const LetterTemplateCodeValueDescriptionMap: { [key in LetterTemplateCodeKeyType]: string } =
    {
        [LetterTemplateCode.Default]: `Default`,
        [LetterTemplateCode.BpCorporate]: `BpCorporate`,
        [LetterTemplateCode.BpFranchise]: `BpFranchise`,
        [LetterTemplateCode.Z]: `Z`,
        [LetterTemplateCode.Mobil]: `Mobil`,
        [LetterTemplateCode.Caltex]: `Caltex`,
        [LetterTemplateCode.Npd]: `Npd`,
    };

export interface MotochekAddressRepresentation {
    mailingAddressBoxBagNumber: string | null;
    mailingAddressBoxBagRd: string | null;
    mailingAddressFlatFloorName: string | null;
    mailingAddressPostcode: string | null;
    mailingAddressPropertyName: string | null;
    mailingAddressRdNumber: string | null;
    mailingAddressStreetName: string | null;
    mailingAddressStreetNumber: string | null;
    mailingAddressSuburb: string | null;
    mailingAddressTown: string | null;
}

export interface OverrideDebtStateRequest {
    debtIdentifiers: Array<string | null> | null;
    newState: DebtState;
}

export enum PaymentCreationStatus {
    DebtInInvalidState = 'DebtInInvalidState',
    PaymentViaStripeFailed = 'PaymentViaStripeFailed',
    DebtHasAlreadyBeenPaid = 'DebtHasAlreadyBeenPaid',
}

export type PaymentCreationStatusKeyType = keyof typeof PaymentCreationStatus;

export const PaymentCreationStatusValueDescriptionMap: {
    [key in PaymentCreationStatusKeyType]: string;
} = {
    [PaymentCreationStatus.DebtInInvalidState]: `DebtInInvalidState`,
    [PaymentCreationStatus.PaymentViaStripeFailed]: `PaymentViaStripeFailed`,
    [PaymentCreationStatus.DebtHasAlreadyBeenPaid]: `DebtHasAlreadyBeenPaid`,
};

export interface SecondDebtLetterDetails {
    collectionFeeAppliedOn: LocalDate;
}

export interface StripeTokenizationResultRepresentation {
    card: StripeTokenizedCardRepresentation | null;
    client_ip: string /* IPAddress */ | null;
    created: number /* WARNING: Server type Int64 has greater precision that JS number */;
    id: string | null;
    livemode: boolean;
    object: string | null;
    type: string | null;
    used: boolean;
}

export interface StripeTokenizedCardRepresentation {
    address_city: string | null;
    address_country: string | null;
    address_line1: string | null;
    address_line1_check: string | null;
    address_line2: string | null;
    address_state: string | null;
    address_zip: string | null;
    address_zip_check: string | null;
    brand: string | null;
    country: string | null;
    cvc_check: string | null;
    dynamic_last4: string | null;
    exp_month: number;
    exp_year: number;
    funding: string | null;
    id: string | null;
    last4: string | null;
    metadata: { [key: string]: any /* Object */ | null } | null;
    name: string | null;
    object: string | null;
    tokenization_method: any /* Object */ | null;
}
