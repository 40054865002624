import sadAlfie from 'assets/images/alfie-sad.png';
import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

const Page = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
`;

const Alfie = styled.img`
    max-width: 8rem;
    margin: 0 0 2rem;
`;

export const AppCrashed = () => {
    const reload = () => window.location.reload();
    return (
        <Page>
            <Alfie src={sadAlfie} />
            <p>Uh oh! Something went wrong. Try refreshing the page.</p>
            <Button onClick={reload} color="primary">
                Refresh
            </Button>
        </Page>
    );
};
