import isFunction from 'lodash.isfunction';
export const loadStripe = (onload?: () => void) => {
    const existing = document.getElementById('stripe-js');

    // don't load twice
    if (existing) {
        isFunction(onload) && onload();
        return;
    }
    const script = document.createElement('script');

    script.id = 'stripe-js';
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    if (isFunction(onload)) {
        script.addEventListener('load', onload);
    }

    document.body.appendChild(script);
};
