import { ChurnZeroEventName } from 'api/Api.generated';
import React from 'react';
import { matchOrgKey } from 'routing';
import { routingStore } from 'stores';
import styled, { css } from 'styled-components';
import {Body, Container, Link, P, TinyP} from 'styles';
import { getOrgDetails } from 'styles/theme';

interface PublicLayoutComponent {
    footer?: boolean;
    wide?: boolean;
    sendEvent: (eventName: ChurnZeroEventName, token: string | null) => void;
}

const Footer = styled.div<{ wide: boolean }>`
    ${({ wide }) =>
        wide &&
        css`
            @media (min-width: 768px) {
                padding: 0 2rem;
            }
        `}
    margin-top: 0.5rem;
`;

const FooterLink = styled(Link)`
    font-weight: 400;
`;

export const PublicLayout: React.FC<PublicLayoutComponent> = ({
    children,
    footer = true,
    wide = false,
    sendEvent,
}) => {
    const onClick = () => {
        sendEvent(ChurnZeroEventName.AlfiepayCallSupport, null);
    };

    const orgKey = matchOrgKey(routingStore.location);
    const { helpPhone } = getOrgDetails(orgKey);

    return (
        <Body>
            <Container wide={wide}>
                {children}
                {footer && helpPhone && (
                    <Footer wide={wide}>
                        <P>
                            Call{' '}
                            <FooterLink onClick={onClick} href={`tel:${helpPhone}`}>
                                {helpPhone}
                            </FooterLink>{' '}
                            for more information and assistance.
                        </P>
                        <TinyP>
                            If you have been sent a letter with your registered vehicle details, please note that this information has been obtained from the Motor Vehicle Register in order to provide you with notification of that vehicle’s involvement in non-payment at a fuel retailer (authorised by NZTA under New Zealand Gazette Notice Number 2022-au2513). An individual can notify the Registrar should they wish to remove their name and address from the register.
                        </TinyP>
                    </Footer>
                )}
            </Container>
        </Body>
    );
};
