import {
    Api,
    apiInstance,
    DebtDetails,
    DebtLetterDetails,
    DebtState,
    DebtStateTrigger,
    InvalidDebtStateApiError,
    InvalidDebtStateDetails,
} from 'api/Api';
import { SnackbarControllerViewModel } from 'components/Snackbar/SnackbarControllerViewModel';
import fastDeepEqual from 'fast-deep-equal';
import { action, computed, observable } from 'mobx';
import { PrintDocumentFrameContent } from 'pages/Printing/PrintDocument';
import { PrintDocumentViewModel } from 'pages/Printing/PrintDocumentViewModel';
import { ensureImagesAreLoaded } from 'pages/RecoveryAdmin/RecoveryAdminViewModel';
import React from 'react';
import ReactDOM from 'react-dom';
import { getPrintTime } from 'utils/date';
import { getUniqueId } from 'utils/getUniqueId';
import { globalHandleError } from 'utils/globalHandleError';
import {themeMap} from 'styles/theme';

export const visibleCardCount = 12;

export const isValidDebt = ({ incidentDetails, licensePlate, organizationKey }: DebtLetterDetails) => {
    const volume = (incidentDetails && incidentDetails.ProductQuantity) || '';
    const price = parseFloat((incidentDetails && incidentDetails.ProductPrice) || '') || 0;

    const validVolume = !!volume && parseFloat(volume) !== 1; // volume not default of "1"
    const validPrice = price < 5; // price < $5/L
    const validPlate = !/\*/.test(licensePlate); // plate doesn't have * in it

    // Check if we have a valid theme for the letter template
    const validLetterTemplate = (organizationKey in themeMap);

    return validVolume && validPrice && validPlate && validLetterTemplate;
};

export class PrintTriageViewModel {
    @computed
    get toPrintData() {
        return this.toPrint.slice(0, visibleCardCount);
    }
    @computed
    get rejectedData() {
        return this.rejected.slice(0, visibleCardCount);
    }
    @computed
    get unassignedData() {
        return this.unassigned.slice(0, visibleCardCount);
    }
    @computed
    get printIds() {
        return this.toPrint.map(recovery => recovery.debtIdentifier);
    }

    @observable
    printError = false;

    @observable
    confirmMarkChanges = false;

    orgFilter?: string;

    @observable
    fetching = false;

    @observable
    printing = false;

    readonly api: Pick<
        Api,
        | 'aurorAdmin_FireDebtStateTrigger'
        | 'aurorAdmin_GetDebtDetailsByStates'
        | 'aurorAdmin_GetDebtDetailsByStatesAndPrintIndex'
        | 'aurorAdmin_GetDebtLetterDetails'
        | 'aurorAdmin_ToPdf'
    >;
    @observable
    badDetails: InvalidDebtStateDetails[] = [];

    @observable
    unassigned: DebtLetterDetails[] = [];

    @observable
    marking = false;

    @observable
    rejected: DebtLetterDetails[] = [];

    @observable
    toPrint: DebtLetterDetails[] = [];

    @observable
    debtDetails: DebtDetails[] = [];

    @observable
    successfulPrintCount = 0;

    @observable
    batchSize = 250;

    @observable
    total = 0;

    @observable
    private assigned: DebtLetterDetails[] = [];

    constructor(
        readonly snackbarVm: SnackbarControllerViewModel,
        api?: Pick<
            Api,
            | 'aurorAdmin_FireDebtStateTrigger'
            | 'aurorAdmin_GetDebtDetailsByStates'
            | 'aurorAdmin_GetDebtDetailsByStatesAndPrintIndex'
            | 'aurorAdmin_GetDebtLetterDetails'
            | 'aurorAdmin_ToPdf'
        >,
    ) {
        this.api = api || apiInstance;
    }

    @action.bound
    reject(debt?: DebtLetterDetails) {
        if (!debt) {
            return;
        }
        this.assigned.push(debt);
        this.rejected.unshift(debt);
    }

    isToPrint(debt: DebtLetterDetails) {
        return this.toPrint.findIndex(x => fastDeepEqual(x, debt)) >= 0;
    }

    isRejected(debt: DebtLetterDetails) {
        return this.rejected.findIndex(x => fastDeepEqual(x, debt)) >= 0;
    }

    @action.bound
    addToRejected() {
        if (this.unassigned.length === 0) {
            return;
        }
        this.reject(this.unassigned.shift());
    }

    @action.bound
    acceptAll() {
        while (this.unassigned.length > 0) {
            this.addToPrint();
        }
    }

    @action.bound
    addToPrint() {
        if (this.unassigned.length === 0) {
            return;
        }
        const debt = this.unassigned.shift();
        if (!debt) {
            return;
        }
        this.assigned.push(debt);
        this.toPrint.unshift(debt);
    }

    @action.bound
    undo() {
        const debt = this.assigned.pop();

        if (debt) {
            this.unassignDebt(debt);
        }
    }

    @action.bound
    unassignDebt(debt: DebtLetterDetails) {
        const excludeDebt = (x: DebtLetterDetails) =>
            !(x.debtIdentifier === debt.debtIdentifier && x.licensePlate === x.licensePlate);

        if (this.isRejected(debt)) {
            this.rejected = this.rejected.filter(excludeDebt);
            this.unassigned.unshift(debt);
        } else if (this.isToPrint(debt)) {
            this.toPrint = this.toPrint.filter(excludeDebt);
            this.unassigned.unshift(debt);
        }
    }

    @action.bound
    handlePrint() {
        this.printAssigned();
    }

    @action.bound
    handleLoad() {
        this.load();
    }

    @action.bound
    handleMarkChanges() {
        this.markChanges();
    }

    @action.bound
    refresh() {
        this.load();
    }

    @action.bound
    toggleMarkChanges() {
        this.confirmMarkChanges = !this.confirmMarkChanges;
    }

    @action.bound
    private reset() {
        this.debtDetails = [];
        this.unassigned = [];
        this.assigned = [];
        this.toPrint = [];
        this.rejected = [];
        this.badDetails = [];
    }

    @transformToMobxFlow
    private async markChanges() {
        this.marking = true;
        try {
            const toPrintIds = this.toPrint.map(x => x.debtIdentifier);
            const toRejectIds = this.rejected.map(x => x.debtIdentifier);
            await this.fireDebtStateTriggerForBatch(toPrintIds, DebtStateTrigger.CommunicationSent);
            await this.fireDebtStateTriggerForBatch(
                toRejectIds,
                DebtStateTrigger.RejectedPrintDetails,
            );
            await this.load(); // reload in place
        } catch (e) {
            globalHandleError(e);
            this.snackbarVm.showSnackbar(getUniqueId(), {
                content: e.message,
                persistent: true,
            });
        } finally {
            this.marking = false;
            this.confirmMarkChanges = false;
        }
    }

    async fireDebtStateTriggerForBatch(batchOfIds: string[], trigger: DebtStateTrigger) {
        // If we send too big a batch to the FireDebtStateTrigger endpoint at a time, we
        // risk a time-out. Instead, let's send multiple small batches.
        const batchSize = 25;
        while (batchOfIds.length > 0) {
            const batchToMark = batchOfIds.splice(0, batchSize);
            await this.api.aurorAdmin_FireDebtStateTrigger({
                debtIdentifiers: batchToMark,
                trigger: trigger,
            });
        }
    }

    @transformToMobxFlow
    private async load() {
        this.reset();

        this.fetching = true;
        try {
            const { debts, total } = await this.api.aurorAdmin_GetDebtDetailsByStatesAndPrintIndex(
                [DebtState.ReadyToSendLetter1, DebtState.ReadyToSendLetter2],
                this.orgFilter,
                1,
                this.batchSize,
            );
            this.debtDetails = debts;
            this.total = total;

            // make sure the debt ids are unique
            const debtIds = [...new Set(this.debtDetails.map(x => x.debtIdentifier))];
            for (const id of debtIds) {
                await this.loadDebtDetail(id);
            }
        } catch (e) {
            globalHandleError(e);
            this.snackbarVm.showSnackbar(getUniqueId(), {
                content: e.message,
                persistent: true,
            });
        } finally {
            this.fetching = false;
        }
    }

    @transformToMobxFlow
    private async loadDebtDetail(id: string) {
        const debts = await this.api.aurorAdmin_GetDebtLetterDetails({
            debtIdentifiers: [id],
        });

        if (InvalidDebtStateApiError.isInstance(debts)) {
            this.badDetails.push(...debts.details);
        } else {
            debts.forEach(debt => {
                if (isValidDebt(debt)) {
                    this.unassigned.push(debt);
                } else {
                    this.reject(debt);
                }
            });
        }
    }

    @transformToMobxFlow
    private async printAssigned() {
        this.printing = true;
        const debtLetterToPrint = this.toPrint.reverse();

        this.printError = false;
        this.successfulPrintCount = 0;

        while (debtLetterToPrint.length > 0) {
            const batch = debtLetterToPrint.splice(0, 25);

            const printSuccessful = await this.printMixedOrgLetters(batch);
            if (printSuccessful) {
                this.successfulPrintCount += batch.length;
            } else {
                this.printError = true;
                batch.forEach(debt => {
                    this.unassignDebt(debt);
                });
            }
        }

        this.printing = false;
        this.confirmMarkChanges = true;
    }

    @transformToMobxFlow
    private async printMixedOrgLetters(letters: DebtLetterDetails[]) {
        let iframe: HTMLIFrameElement | undefined;

        try {
            document.title = this.GeneratePDFName(letters);

            iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            const iframeWindow = iframe.contentWindow!;

            const root = iframeWindow.document.createElement('div');
            iframeWindow.document.body.appendChild(root);

            const vm = new PrintDocumentViewModel(letters);

            ReactDOM.render(
                React.createElement(PrintDocumentFrameContent, {
                    vm,
                    head: iframeWindow.document.head,
                }),
                root,
            );

            await ensureImagesAreLoaded(root);

            const styleEl = iframeWindow.document.head.querySelector('style');
            const { cssRules } = styleEl!.sheet! as CSSStyleSheet; // if this isn't there we have bigger problems
            const cssDeclarations: string[] = [];
            for (let i = 0; i < cssRules.length; i++) {
                cssDeclarations.push(cssRules[i].cssText);
            }
            const style = cssDeclarations.join(' ');

            let blob;
            blob = await this.api
                .aurorAdmin_ToPdf({
                    fawkesEventResourceLocators: letters.map(x => x.debtIdentifier),
                    html: `<html><head><style>${style}</style><body>${root.outerHTML}`,
                })
                .then(res => res.blob());

            const hiddenElement = document.createElement('a');
            hiddenElement.href = URL.createObjectURL(blob);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${document.title}.pdf`;
            hiddenElement.click();

            // wait for next tick to remove iframe to let printing happen consistently
            await new Promise(resolve => {
                setTimeout(() => {
                    iframe && iframe.remove();
                    resolve();
                });
            });
            return true;
        } catch (e) {
            globalHandleError(e);
            return false;
        }
    }

    private GeneratePDFName(letters: DebtLetterDetails[]): string {
        const printingBatch = letters[0].printingBatch;
        const indexes: number[] = letters.map(x => x.index);
        const minIndex = Math.min(...indexes)
            .toString()
            .padStart(5, '0');
        const maxIndex = Math.max(...indexes)
            .toString()
            .padStart(5, '0');

        return `Alfiepay_Print_${printingBatch}_${minIndex}_${maxIndex}_${getPrintTime()}`;
    }
}
