import { ChurnZeroEventName } from 'api/Api.generated';
import { PublicLayout } from 'components/Layout';
import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React, { useEffect } from 'react';
import { H3, Logo, P } from 'styles';
import { getOrgDetails } from 'styles/theme';

export const PaymentFailed: React.FC<PublicAppComponent> = ({ vm }) => {
    const { logo, helpPhone } = getOrgDetails(vm.organizationKey);
    useEffect(() => {
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewPaymentFailedPage, vm.debtIdentifier);
    });
    return (
        <PublicLayout footer={false} sendEvent={vm.sendChurnZeroEvent}>
            <Logo alt="" src={logo} />
            <H3>Oops.</H3>
            <P>
                It looks like this payment can no longer be made using Alfiepay. For more info
                please call <a href={`tel:${helpPhone}`}>{helpPhone}</a> and we can help.
            </P>
        </PublicLayout>
    );
};
