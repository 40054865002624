import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import React, { useContext, useEffect, useState } from 'react';
import { globalHandleError } from 'utils/globalHandleError';
import { telemetry } from 'utils/telemetry';
import { AuthService } from './AuthService';

export interface Auth0Context {
    isAuthenticated: boolean;
    user: any;
    loading: boolean;
    handleRedirectCallback(): Promise<RedirectLoginResult>;
    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
    loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
    logout(o?: LogoutOptions): void;
}
interface Auth0ProviderOptions {
    children: React.ReactElement;
    onRedirectCallback?(result: RedirectLoginResult): void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Auth0Context | null>(null);
export const useAuth0 = () => useContext(Auth0Context)!;
export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState<Auth0Client>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AuthService.setClient(auth0Client);
        AuthService.isAuthenticated = isAuthenticated;
    }, [isAuthenticated]);

    const neverEndingPromise = new Promise(() => undefined);

    const getTokenSilently = async (o: GetTokenSilentlyOptions | undefined) => {
        if (auth0Client) {
            const token = await auth0Client.getTokenSilently(o);
            if (!token) {
                setIsAuthenticated(false);
                window.location.reload();
                return neverEndingPromise;
            }
            return token;
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions);
            setAuth0(auth0FromHook);

            if (window.location.search.includes('code=')) {
                const result = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback(result);
            }

            const userIsAuthed = await auth0FromHook.isAuthenticated();

            setIsAuthenticated(userIsAuthed);

            if (userIsAuthed) {
                const authUser = await auth0FromHook.getUser();
                telemetry.setCurrentUser(authUser);
                setUser(authUser);
            }

            setLoading(false);
        };
        initAuth0().catch(globalHandleError);
    }, []);

    const handleRedirectCallback = async () => {
        setLoading(true);
        const result = await auth0Client!.handleRedirectCallback();
        const redirectUser = await auth0Client!.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(redirectUser);
        return result;
    };
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                handleRedirectCallback,
                getTokenSilently,
                getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
                    auth0Client!.getIdTokenClaims(o),
                loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
                logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
