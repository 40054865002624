import { ChurnZeroEventName } from 'api/Api.generated';
import sadAlfie from 'assets/images/alfie-sad.png';
import alfieLogo from 'assets/images/alfiepay-logo.svg';
import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import { Backdrop } from 'pages/Home/Backdrop';
import { Alfie, Body, Strip } from 'pages/Home/Home';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Header = styled.h2`
    font-size: calc(3rem + 1vw);
    font-weight: 600;
    margin: 0 0 0.5rem;
    text-align: center;
`;
const SubHeader = styled.h3`
    font-size: calc(1.5rem + 0.5vw);
    font-weight: 300;
    margin: 2rem 0;
    text-align: center;
`;
const P = styled.p`
    text-align: center;
`;

const A = styled.a`
    &,
    &:hover,
    &:active,
    &:visited {
        color: #00b5a5;
    }
`;

const Logo = styled.img`
    position: fixed;
    top: 5vh;
    left: 5vw;
    width: calc(8ch + 8vw);
`;

export const NotFound: React.FC<PublicAppComponent> = ({ vm }) => {
    useEffect(() => {
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewNotFoundPage, vm.debtIdentifier);
    });
    return (
        <Backdrop>
            <Logo src={alfieLogo} />
            <Body>
                <Header>Oops!</Header>
                <SubHeader>There's nothing here.</SubHeader>
                <P>
                    Maybe try the <A href="/">home page</A>?
                </P>
            </Body>
            <Strip />
            <Alfie src={sadAlfie} />
        </Backdrop>
    );
};
