import React, { FC, useEffect } from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Logo } from 'styles';
import { getOrgDetails, orgKeys } from 'styles/theme';

const OrgFilterGroup = styled.div`
    align-items: center;
    background: #fff;
    display: flex;
    margin: 1rem 0;
    padding: 0.5rem;
`;

const OrgButton = styled(Link)`
    align-items: center;
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.borderColor};
    display: flex;
    padding: 1rem;
    &:hover {
        text-decoration: none;
    }
    & ${Logo} {
        height: 2rem;
        margin: 0;
    }
    &:first-child {
        border-radius: 6px 0 0 6px;
    }
    &:not(:first-child) {
        margin-left: -1px;
    }
    &:last-child {
        border-radius: 0 6px 6px 0;
    }
`;

const ActiveOrgButton = styled(OrgButton)`
    background: var(--light-blue);
`;

interface OrgFilterableViewModel {
    orgFilter?: string;
}

interface OrgFilterComponent {
    vm: OrgFilterableViewModel;
}

export const OrgFilter: FC<OrgFilterComponent> = ({ vm }) => {
    const { params, path } = useRouteMatch<{ orgKey?: string }>();
    const { orgKey } = params;

    useEffect(() => {
        vm.orgFilter = orgKey;
    }, [orgKey]);

    return (
        <OrgFilterGroup>
            {orgKeys.map((key) => {
                if (key === 'testorg' || key === 'org1') {
                    return null;
                }
                const details = getOrgDetails(key);
                if (!details) {
                    return null;
                }
                return key === orgKey ? (
                    <ActiveOrgButton to={generatePath(path)} key={key}>
                        <Logo src={details.logo} />
                    </ActiveOrgButton>
                ) : (
                    <OrgButton to={generatePath(path, { orgKey: key })} key={key}>
                        <Logo src={details.logo} />
                    </OrgButton>
                );
            })}
        </OrgFilterGroup>
    );
};
