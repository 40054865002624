import { format } from 'date-fns';

export const downloadCSVs = (plates: string[]) => {
    const batchSize = 999;
    let batchNumber = 1;
    while(plates.length > 0) {
        const thisBatch = plates.splice(0, batchSize);
        downloadCSVForPlateBatch(batchNumber, thisBatch);
        batchNumber++;
    }
};

const downloadCSVForPlateBatch = (batchNumber: number, plates: string[]) => {
    const csvUrl = toDataUrl(plates);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = `motochek-${batchNumber}-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`;
    hiddenElement.click();
};

export const toDataUrl = (plates: string[]) => {
    const output = [
        ...new Set(
            plates.map(plate =>
                plate
                    .trim()
                    .replace(/\s/g, '')
                    .toUpperCase(),
            ),
        ),
    ];

    return encodeURIComponent(output.join('\n'));
};
