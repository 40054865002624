import { ErrorCustomDataSymbol } from '../utils/ErrorCustomDataSymbol';

/**
 * Returns true if the error was caused by an aborted Fetch
 */
export function fetchErrorIsAbort(err: Error | any) {
    // https://github.com/mo/abortcontroller-polyfill
    return err.name === 'AbortError';
}

export enum KnownFetchErrorNames {
    AbortError = 'AbortError',
    Unauthenticated = '401Unauthenticated',
    NotFound = '404NotFound',
    InternalServerError = '500InternalServerError',
    JsonParsingError = 'JsonParsingError',
    NetworkError = 'NetworkError',
}

export class FetchError extends Error {
    [ErrorCustomDataSymbol]: FetchErrorCustomData;

    constructor(readonly name: KnownFetchErrorNames, message: string, data: FetchErrorCustomData) {
        super(`${data.actionName}: ${message}`);

        // Set the prototype explicitly.
        // Otherwise `instanceof FetchError` wouldn't work
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, FetchError.prototype);

        this[ErrorCustomDataSymbol] = data;
    }
}

export interface FetchErrorCustomData {
    actionName: string;
    url: string;
    status: number;
    method?: string;
    innerErrorMessage?: string;
}

export function getFetchErrorName(error: unknown) {
    if (error && error instanceof FetchError) {
        return error.name;
    }

    return undefined;
}
