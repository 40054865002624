import { LetterTemplateCode } from 'api/Api';
import React, { FC } from 'react';
import { globalHandleError } from 'utils/globalHandleError';
import { FirstLetterBP } from './FirstLetterBP';
import { FirstLetterBPFranchise } from './FirstLetterBPFranchise';
import { FirstLetterMobil } from './FirstLetterMobil';
import { FirstLetterZ } from './FirstLetterZ';
import { PrintPageComponent } from './PrintPage';
import { SecondLetterBP } from './SecondLetterBP';
import { SecondLetterBPFranchise } from './SecondLetterBPFranchise';
import { SecondLetterZ } from './SecondLetterZ';
import { SecondLetterMobil } from './SecondLetterMobil';
import { FirstLetterCaltex } from './FirstLetterCaltex';
import { SecondLetterCaltex } from './SecondLetterCaltex';
import { FirstLetterNpd } from './FirstLetterNPD';
import { SecondLetterNpd } from './SecondLetterNPD';

export const PdfBody: FC<PrintPageComponent> = ({ recovery }) => {
    const { letterTemplateCode, secondDebtLetterDetails } = recovery;

    const firstLetter = !secondDebtLetterDetails;

    switch (letterTemplateCode) {
        case LetterTemplateCode.BpCorporate:
            return firstLetter ? (
                <FirstLetterBP recovery={recovery} />
            ) : (
                <SecondLetterBP recovery={recovery} />
            );
        case LetterTemplateCode.BpFranchise:
            return firstLetter ? (
                <FirstLetterBPFranchise recovery={recovery} />
            ) : (
                <SecondLetterBPFranchise recovery={recovery} />
            );
        case LetterTemplateCode.Z:
            return firstLetter ? (
                <FirstLetterZ recovery={recovery} />
            ) : (
                <SecondLetterZ recovery={recovery} />
            );
        case LetterTemplateCode.Mobil:
            return firstLetter ? (
                <FirstLetterMobil recovery={recovery} />
            ) : (
                <SecondLetterMobil recovery={recovery} />
            );
        case LetterTemplateCode.Caltex:
            return firstLetter ? (
                <FirstLetterCaltex recovery={recovery} />
            ) : (
                <SecondLetterCaltex recovery={recovery} />
            );
        case LetterTemplateCode.Npd:
            return firstLetter ? (
                <FirstLetterNpd recovery={recovery} />
            ) : (
                <SecondLetterNpd recovery={recovery} />
            )
            
        case LetterTemplateCode.Default:
            globalHandleError(
                new Error(`LetterTemplateCode '${recovery.letterTemplateCode}' is not supported`),
            );
            return null;
        default:
            const unsupported: never = letterTemplateCode;
            globalHandleError(new Error(`LetterTemplateCode '${unsupported}' is not supported`));
            return null;
    }
};
