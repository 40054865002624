import React from 'react';
import styled, { css } from 'styled-components';

const CardIconBlock = styled.div<{ fade: boolean }>`
    ${props =>
        props.fade &&
        css`
            color: #ccc;
        `}
    padding: 0 0.5rem;
`;
const Icon = styled.i`
    width: 32px;
`;

export type CardBrand = 'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb' | 'unknown';

const brandToPf = (brand: CardBrand) => {
    const map = {
        visa: 'pf-visa',
        mastercard: 'pf-mastercard',
        amex: 'pf-american-express',
        discover: 'pf-discover',
        diners: 'pf-diners',
        jcb: 'pf-jcb',
        unknown: 'pf-credit-card',
    };
    return map[brand] || map.unknown;
};

interface CardIconComponent {
    brand: CardBrand;
}

export const CardIcon: React.FC<CardIconComponent> = ({ brand }) => {
    const className = `pf ${brandToPf(brand)}`;
    return (
        <CardIconBlock fade={brand === 'unknown'}>
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/paymentfont/1.1.2/css/paymentfont.min.css"
            />
            <Icon className={className} />
        </CardIconBlock>
    );
};
