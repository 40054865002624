import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { InputProps } from 'reactstrap';
import { Input } from 'styles';

export const FormInput: React.FC<InputProps & { field: FormField }> = observer(props => {
    const { field, ...rest } = props;
    const onBlur = () => field.validate();
    return <Input value={field.value} onBlur={onBlur} {...rest} />;
});

export class FormField {
    @observable.ref
    value: string = '';

    @observable
    hasBeenValidated: boolean = false;

    @computed
    get validationMessage(): string | null {
        return this.hasBeenValidated ? this.validator(this.value) : null;
    }

    @computed
    get isValid() {
        return !this.validationMessage;
    }

    constructor(initialValue: string, readonly validator: (val: string) => string | null) {
        this.value = initialValue;
    }

    @action.bound
    validate() {
        this.hasBeenValidated = true;
        return !this.validationMessage;
    }
}
