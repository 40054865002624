import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

export const useMousePosition = (el: HTMLElement | null) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const throttledSetPosition = throttle(setPosition, 100);

    useEffect(() => {
        if (!el) {
            return;
        }
        const setFromEvent = (e: MouseEvent) => {
            const { top, left } = el.getBoundingClientRect();
            throttledSetPosition({ x: e.clientX - left, y: e.clientY - top });
        };
        el.addEventListener('mousemove', setFromEvent);

        return () => {
            el.removeEventListener('mousemove', setFromEvent);
        };
    }, [el]);

    return position;
};
