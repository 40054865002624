import { Api, DebtState } from 'api/Api';
import { action, autorun, computed, observable } from 'mobx';
import { BlockTitle } from 'pages/RecoveryAdmin/RecoveryAdminViewModel';
import { RecoveryRowViewModel } from './RecoveryRowViewModel';

export interface Action {
    label: string;
    fn?: () => void;
    goto?: string;
}
export class RecoveryBlockViewModel {
    readonly description: string;
    readonly title: BlockTitle;
    readonly states: DebtState[];
    readonly blockAction?: Action;
    readonly rowAction?: Action;
    readonly onSelect?: (selectedIds: string[]) => void;
    readonly getAllRecoveries: () => RecoveryRowViewModel[];

    @observable
    lastToggledId?: string = undefined;

    @observable
    expanded = false;

    @observable
    search = '';

    // can act if any of `selections` is set to true
    @computed
    get canAct() {
        return this.selectedRows.length > 0;
    }

    @computed
    get someChecked() {
        return this.recoveries.some(recovery => recovery.isChecked);
    }

    @computed
    get allChecked() {
        return this.recoveries.every(recovery => recovery.isChecked);
    }

    @computed
    get recoveries(): RecoveryRowViewModel[] {
        return this.getAllRecoveries().filter(recovery => this.states.includes(recovery.debtState));
    }

    @computed
    get selectedRows() {
        return this.matchingRecoveries.filter(x => x.isChecked);
    }

    constructor(
        options: {
            description: string;
            title: BlockTitle;
            states: DebtState[];
            blockAction?: Action;
            rowAction?: Action;
            onSelect?: (selectedIds: string[]) => void;
            getAllRecoveries: () => RecoveryRowViewModel[];
        },
        readonly api: Pick<Api, 'aurorAdmin_OverrideDebtState'>,
    ) {
        const {
            blockAction,
            description,
            states,
            rowAction,
            title,
            onSelect,
            getAllRecoveries,
        } = options;
        this.states = states;
        this.description = description;
        this.title = title;
        this.blockAction = blockAction;
        this.rowAction = rowAction;
        this.onSelect = onSelect;
        this.getAllRecoveries = getAllRecoveries;

        autorun(() => {
            if (this.onSelect) {
                this.onSelect(this.selectedIds);
            }
        });
    }

    @computed
    get selectedIds() {
        return this.selectedRows.map(x => x.debtIdentifier);
    }

    @computed
    get selectionCount() {
        return this.selectedRows.length;
    }

    @computed
    get matchingRecoveries() {
        const search = this.search.trim().toLowerCase();
        return this.recoveries.filter(recovery => {
            return (
                !search ||
                recovery.debtIdentifier.toLowerCase().includes(search) ||
                recovery.licensePlate.toLowerCase().includes(search) ||
                recovery.organizationName.toLowerCase().includes(search) ||
                recovery.siteName.toLowerCase().includes(search) ||
                String(recovery.amountIncludingCollectionFee).includes(search) ||
                String(recovery.amountOwedForGoods).includes(search)
            );
        });
    }

    @action.bound
    toggleAll() {
        const { someChecked } = this;

        this.recoveries.forEach(x => x.toggleChecked(!someChecked));
    }

    @action.bound
    toggleRange(id: string) {
        const { lastToggled, recoveries } = this;
        const target = this.getRecovery(id);
        if (lastToggled && target) {
            let fromIndex = this.getRowIndex(lastToggled);
            let toIndex = this.getRowIndex(target);
            if (fromIndex > toIndex) {
                [fromIndex, toIndex] = [toIndex, fromIndex];
            }
            const range = recoveries.slice(fromIndex, toIndex + 1);
            range.forEach(x => x.toggleChecked(lastToggled.isChecked));

            // prevent weird text selection from shift-clicking
            const selection = document.getSelection();
            if (selection) {
                selection.empty();
            }
        } else if (target) {
            target.toggleChecked();
        }
        this.lastToggledId = id;
    }

    getRecovery(id?: string) {
        const { recoveries } = this;
        if (id) {
            return recoveries.find(x => x.debtIdentifier === id);
        } else {
            return undefined;
        }
    }
    getRowIndex(row: RecoveryRowViewModel) {
        const { recoveries } = this;
        if (row) {
            return recoveries.findIndex(x => x.debtIdentifier === row.debtIdentifier);
        } else {
            return -1;
        }
    }
    @computed
    get lastToggled() {
        const { lastToggledId } = this;
        return this.getRecovery(lastToggledId);
    }

    @action.bound
    clearSelectionByDebtId(ids: string[]) {
        this.recoveries.forEach(x => ids.includes(x.debtIdentifier) && x.toggleChecked(false));
    }

    @action.bound
    clearSelection() {
        this.recoveries.forEach(x => x.toggleChecked(false));
    }

    @action.bound
    toggleExpanded() {
        this.expanded = !this.expanded;
    }

    @transformToMobxFlow
    async overrideState(debtIds: string[], newState: DebtState) {
        await this.api.aurorAdmin_OverrideDebtState({
            debtIdentifiers: debtIds,
            newState,
        });

        this.selectedRows.forEach(x => {
            x.debtState = newState;
        });
        this.clearSelection();
    }

    @computed
    get interpolatedLabel() {
        const { rowAction, selectionCount } = this;

        if (rowAction) {
            return rowAction.label.replace(' $count', () => {
                if (selectionCount === 0) {
                    return '';
                } else {
                    return ` (${selectionCount})`;
                }
            });
        } else {
            return undefined;
        }
    }
}
