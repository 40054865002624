import { AlfieConfig } from './alfie-config';

interface RaygunCustomData {
    from?: string;
}
/**
 * To mutate Breadcrumbs for a call to raygun. Intended to obfuscate access tokens
 * @param payload
 * @param callback
 */
const forEachBreadcrumb = (payload: any, callback: (data: RaygunCustomData) => void) => {
    // remove accessToken from breadcrumbs
    if (
        payload instanceof Object &&
        payload.Details instanceof Object &&
        payload.Details.Breadcrumbs instanceof Array
    ) {
        payload.Details.Breadcrumbs.forEach((b: any) => {
            if (
                b instanceof Object &&
                b.CustomData instanceof Object &&
                typeof b.CustomData.from === 'string'
            ) {
                callback(b.CustomData);
            }
        });
    }
};

export const initRaygun = (config: AlfieConfig) => {
    if (!rg4js || !config.raygun.apiKey) {
        return;
    }
    rg4js('apiKey', config.raygun.apiKey);
    rg4js('enableCrashReporting', true);
    rg4js('setAutoBreadcrumbsXHRIgnoredHosts', ['visualstudio.com']);
    rg4js('options', { ignore3rdPartyErrors: true });
    rg4js('onBeforeSend', (payload: any) => {
        // could possibly loosen this to be `code=` -- discovered by inspecting `from` in errors after logging in
        const tokenRe = /code=.+state=/;
        forEachBreadcrumb(payload, (data: RaygunCustomData) => {
            if (data.from && tokenRe.test(data.from)) {
                data.from = '/[REDACTED]';
            }
        });
        return payload;
    });
};

/**
 * @param user comes from Auth0 which means it's weakly typed
 */
export const setRaygunUser = (user: any) => {
    // Because this user type is so squishy, make sure it has useful info before proceeding
    if (rg4js && user && user.email && user.sub) {
        rg4js('setUser', {
            identifier: user.sub,
            isAnonymous: false,
            email: user.email,
            fullName: user.name,
        });
    }
};
