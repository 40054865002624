import { FontLink } from 'components/FontLink';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'styles/theme';

export const BackdropEl = styled.div`
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.white};
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        font-family: Poppins, 'Open Sans', Arial, Helvetica, sans-serif;
    }
`;
export const Backdrop: FC = ({ children }) => (
    <ThemeProvider theme={defaultTheme}>
        <BackdropEl>
            <FontLink />
            {children}
        </BackdropEl>
    </ThemeProvider>
);
