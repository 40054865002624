import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
    getCopyDate,
    getSiteName,
    P,
    PrintPageComponent,
    Signature,
    Signoff,
    Url,
} from './PrintPage';

export const SecondLetterNpd: FC<PrintPageComponent> = ({ recovery }) => {
    const { teamName } = getOrgDetails(recovery.organizationKey);
    const siteName = getSiteName(recovery);
    const copyDate = getCopyDate(recovery);

    return (
        <div>
            <P>Dear {recovery.recipient},</P>
            <P>
                We are writing to you as the registered owner of a vehicle with number plate{' '}
                <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor
                Vehicle Register.
            </P>
            <P>
                This is a reminder that payment of {getCurrencyFormat(recovery.amount)} is still
                outstanding for fuel your vehicle received at {copyDate} at {siteName}. 
                If you have already paid or resolved this matter, please disregard this letter.
            </P>
            <P>
                You can pay now by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>,
                using <b>{recovery.licensePlate}</b> and unique code{' '}
                <b>{recovery.debtIdentifier}</b> when directed. Alternatively, you can pop into{' '}
                {siteName} to pay in person.
            </P>
            <P>
                While this matter remains unresolved you will be required to prepay for fuel
                purchases at all NPD stores.
            </P>
            <P>
            If you have any questions, feel free to call the NPD team on 03 365 8988 or come and see us in-store.
            </P>

            <Signoff>
                Kind regards,
                <Signature>{teamName}</Signature>
            </Signoff>
        </div>
    );
};
