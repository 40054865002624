import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useMousePosition } from 'utils/useMousePosition';

interface TriageImageComponent {
    small: boolean;
    src: string;
}

const Image = styled.img<{ small: boolean }>`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    ${({ small }) =>
        small
            ? css`
                  max-height: 10rem;
              `
            : css`
                  max-height: 20rem;
              `}
    max-width: 100%;

    transition: transform ease-in-out 0.1s, transform-origin ease-in-out 0.1s;
`;

const ImageBox = styled.div<{ small: boolean; zoomable: boolean; zoomed: boolean }>`
    align-items: center;
    display: flex;
    justify-content: center;
    ${({ small }) =>
        small
            ? css`
                  min-height: 10rem;
              `
            : css`
                  min-height: 20rem;
              `}
    ${({ zoomable, zoomed }) =>
        zoomable &&
        css`
            overflow: hidden;
            &:hover img {
                cursor: ${zoomed ? 'zoom-out' : 'zoom-in'};
            }
        `}
`;

export const TriageImage: React.FC<TriageImageComponent> = ({ small, src }) => {
    const [zoomed, setZoom] = React.useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const position = useMousePosition(ref.current);

    const zoom = () => setZoom(!zoomed);
    const style = small
        ? undefined
        : {
              transformOrigin: `${position.x}px ${position.y}px`,
              transform: zoomed ? `scale(2.5)` : undefined,
          };

    return (
        <ImageBox onClick={zoom} ref={ref} zoomable={!small} zoomed={zoomed} small={small}>
            <Image small={small} src={src} style={style} />
        </ImageBox>
    );
};
