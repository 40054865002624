import defaultLogo from 'assets/images/alfie-img.png';
import bpLogo from 'assets/images/BP.svg';
import zLogo from 'assets/images/Z.svg';
import mobilLogo from 'assets/images/Mobil.svg';
import caltexLogo from 'assets/images/Caltex.svg';
import colesExpressLogo from 'assets/images/ColesExpress.svg';
import npdLogo from 'assets/images/Npd.svg';
import React from 'react';

export type OrganizationKey = 'bp' | 'z' | 'mobil' | 'caltex' | 'npd' | 'org1' | 'testorg' | 'colesexpress';

interface AlfieCssTheme {
    errorColor: string;
    primaryColor: string;
    primaryHoverColor: string;
    textColor: string;
    linkColor: string;
    linkHoverColor: string;
    pdfVisitBackground: string;
    pdfVisitHeader: string;
    pdfVisitText: string;
    pdfRegistrationBackground: string;
    pdfRegistrationText: string;
    pdfCodeBackground: string;
    pdfCodeText: string;
    logoLength?: number;
}

const alfieTheme: AlfieCssTheme = {
    errorColor: '#dc3545',
    primaryColor: '#009639',
    primaryHoverColor: '#008733',
    textColor: '#555',
    linkColor: '#4592ff',
    linkHoverColor: '#247fff',
    pdfVisitBackground: '#009639',
    pdfVisitHeader: '#ffda00',
    pdfVisitText: '#fff',
    pdfRegistrationBackground: '#a6ce39',
    pdfRegistrationText: '#555',
    pdfCodeBackground: '#ffda00',
    pdfCodeText: '#555',
};

const bpCss = {
    errorColor: '#dc3545',
    primaryColor: '#009639',
    primaryHoverColor: '#008733',
    textColor: '#555',
    linkColor: '#4592ff',
    linkHoverColor: '#247fff',
    pdfVisitBackground: '#009639',
    pdfVisitHeader: '#ffda00',
    pdfVisitText: '#fff',
    pdfRegistrationBackground: '#a6ce39',
    pdfRegistrationText: '#555',
    pdfCodeBackground: '#ffda00',
    pdfCodeText: '#555',
};

export const themeMap: { [key in OrganizationKey]: AlfieCssTheme } = {
    bp: bpCss,
    z: {
        errorColor: '#dc3545',
        primaryColor: '#251b62',
        primaryHoverColor: '#211858',
        textColor: '#555',
        linkColor: '#4592ff',
        linkHoverColor: '#247fff',
        pdfVisitBackground: '#f39202',
        pdfVisitHeader: '#251b62',
        pdfVisitText: '#251b62',
        pdfRegistrationBackground: '#00a7e0',
        pdfRegistrationText: '#fff',
        pdfCodeBackground: '#251b62',
        pdfCodeText: '#fff',
    },
    mobil: {
        errorColor: '#dc3545',
        primaryColor: '#0C67B2',
        primaryHoverColor: '#003771',
        textColor: '#555',
        linkColor: '#4592ff',
        linkHoverColor: '#247fff',
        pdfVisitBackground: '#0C67B2',
        pdfVisitHeader: '#fff',
        pdfVisitText: '#fff',
        pdfRegistrationBackground: '#4288B9',
        pdfRegistrationText: '#fff',
        pdfCodeBackground: '#A3C7E1',
        pdfCodeText: '#0C67B2',
    },
    caltex: {
        errorColor: '#dc3545',
        primaryColor: '#ED1B2E',
        primaryHoverColor: '#003771',
        textColor: '#555',
        linkColor: '#4592ff',
        linkHoverColor: '#247fff',
        pdfVisitBackground: '#ed1b2e',
        pdfVisitHeader: '#fff',
        pdfVisitText: '#fff',
        pdfRegistrationBackground: '#e5edf0',
        pdfRegistrationText: '#00526E',
        pdfCodeBackground: '#00526E',
        pdfCodeText: '#fff',
        logoLength: 164,
    },
    npd: {
        errorColor: '#dc3545',
        primaryColor: '#0063A8',
        primaryHoverColor: '#003771',
        textColor: '#555',
        linkColor: '#4592ff',
        linkHoverColor: '#247fff',
        pdfVisitBackground: '#0063A8',
        pdfVisitHeader: '#fff',
        pdfVisitText: '#fff',
        pdfRegistrationBackground: '#ED2C1B',
        pdfRegistrationText: '#FFF',
        pdfCodeBackground: '#333333',
        pdfCodeText: '#fff',
    },
    org1: bpCss,
    testorg: bpCss,
    colesexpress: {
        errorColor: '#dc3545',
        primaryColor: '#ED1B2E',
        primaryHoverColor: '#003771',
        textColor: '#555',
        linkColor: '#4592ff',
        linkHoverColor: '#247fff',
        pdfVisitBackground: '#ed1b2e',
        pdfVisitHeader: '#fff',
        pdfVisitText: '#fff',
        pdfRegistrationBackground: '#e5edf0',
        pdfRegistrationText: '#00526E',
        pdfCodeBackground: '#00526E',
        pdfCodeText: '#fff',
        logoLength: 164,
    }
};

const baseTheme = {
    bold: 600,
    borderColor: '#eaeaea',
    borderRadius: '4px',
    fadedColor: '#aaa',
    inputBorder: '1px solid #eaeaea',
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    smallFontSize: '0.875rem',
    tinyFontSize: '0.625rem',
    white: '#fff',
    successColor: '#28a745',
    warningColor: '#ffc107',
    dangerColor: '#dc3545',
};

export const toStyledTheme = (orgKey: string) => {
    if (orgKey in themeMap) {
        return Object.assign({}, baseTheme, themeMap[orgKey]);
    }

    return undefined;
};

interface OrganizationDetails {
    helpPhone: string;
    logo: string;
    storeDescription?: string;
    teamName?: string;
}
const testOrgDetails = {
    helpPhone: '0800 FAKE NUM',
    logo: defaultLogo,
    storeDescription: 'Alfiestore',
    teamName: 'Alfiepay Team',
};

const orgDetailsMap: { [key in OrganizationKey]: OrganizationDetails } = {
    bp: {
        helpPhone: '0800 800 027',
        logo: bpLogo,
        storeDescription: 'bp Connect',
        teamName: 'bp Team',
    },
    z: {
        helpPhone: '0800 474 355',
        logo: zLogo,
        storeDescription: 'Z',
        teamName: 'Z Team'
     },
    mobil: {
        helpPhone: '',
        logo: mobilLogo,
        storeDescription: '',
        teamName: '',
    },
    caltex: {
        helpPhone: '',
        logo: caltexLogo,
        storeDescription: 'Caltex',
        teamName: 'Caltex Team',
    },
    npd: {
        helpPhone: '03 365 8988',
        logo: npdLogo,
        teamName: 'NPD Team',
        storeDescription: 'NPD'
    },
    org1: testOrgDetails,
    testorg: testOrgDetails,
    colesexpress: {
        helpPhone: '',
        logo: colesExpressLogo,
        storeDescription: 'Coles Express',
        teamName: 'Coles Express Team',
    },
};

export const getOrgDetails = (orgKey: string | null): OrganizationDetails => {
    if (orgKey && orgKey in orgDetailsMap) {
        return orgDetailsMap[orgKey];
    }

    return { helpPhone: '', logo: '' };
};

export const defaultTheme = Object.assign({}, baseTheme, alfieTheme);

export const ThemeContext = React.createContext(toStyledTheme);

export const orgKeys = Object.keys(orgDetailsMap);
