import { barsIcon, syncAltIcon } from 'assets/icons';
import alfiepayLogo from 'assets/images/alfie-img.png';
import { useAuth0 } from 'components/Authorize/Auth0Wrapper';
import { Input } from 'components/Input';
import { Spinner } from 'components/Spinner/Spinner';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import { adminRoute, printTriageRoute } from 'routing';
import styled from 'styled-components';

interface AdminHeaderComponent {
    dropdownMenuItems?: JSX.Element[];
    refresh: () => void;
    refreshing: boolean;
    showSearch: boolean;
    onSearch?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const FixedHeader = styled.div`
    border-bottom: 1px solid var(--light);
    background: #fff;
    left: 0;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 1000;
`;

const HeaderBlock = styled.div`
    display: flex;
    > button {
        margin-left: 1rem;
    }
`;

export const RefreshButton = styled(Button)`
    justify-content: center;
    min-width: 3rem;
`;

const LogoImg = styled.img`
    height: 2rem;
`;

export const AdminHeader: React.FC<AdminHeaderComponent> = ({
    dropdownMenuItems: menuItems,
    onSearch,
    refresh,
    refreshing,
    showSearch,
}) => {
    const auth0 = useAuth0();
    const logout = () => auth0.logout();
    const { params } = useRouteMatch<{ orgKey?: string }>();
    const { orgKey } = params;

    return (
        <FixedHeader>
            <HeaderBlock className="container">
                <Link to="/auror/admin" className="mr-3">
                    <LogoImg src={alfiepayLogo} />
                </Link>
                {showSearch && onSearch ? (
                    <Input type="search" placeholder="Search" onChange={onSearch} />
                ) : (
                    <div className="flex-grow-1" />
                )}
                <RefreshButton
                    color="primary"
                    onClick={refresh}
                    disabled={refreshing}
                    outline={true}
                >
                    {refreshing ? <Spinner /> : <SvgIcon size="sm" icon={syncAltIcon} />}
                </RefreshButton>

                <UncontrolledDropdown>
                    <DropdownToggle
                        outline={true}
                        color="primary"
                        className="ml-3"
                        data-toggle="dropdown"
                    >
                        <SvgIcon icon={barsIcon} size="md" />
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        <DropdownItem tag={Link} to={adminRoute()}>
                            Home
                        </DropdownItem>
                        <DropdownItem divider={true} />
                        <DropdownItem tag={Link} to={printTriageRoute(orgKey)}>
                            Generate letters
                        </DropdownItem>
                        {menuItems}
                        <DropdownItem divider={true} />
                        <DropdownItem onClick={logout}>Logout</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </HeaderBlock>
        </FixedHeader>
    );
};
