import React from 'react';
import { SnackbarButton, SnackbarRenderer, SnackbarText } from './Snackbar';

interface StripeFailureSnackbarComponent {
    message: string;
}

export const StripeFailureSnackbar: React.FC<StripeFailureSnackbarComponent> = ({ message }) => {
    const reload = () => window.location.reload();
    return (
        <SnackbarRenderer>
            <SnackbarText>{message}</SnackbarText>
            <SnackbarButton onClick={reload}>Reload</SnackbarButton>
        </SnackbarRenderer>
    );
};
