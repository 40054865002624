import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'utils/setupNonce';

import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { routingStore } from 'stores';
import 'styles/styles.css';
import { initRaygun } from 'utils/raygun';
import App from './App';

import { alfieConfig } from 'utils/alfie-config';
import { setupAppInsights } from 'utils/appInsights';

setupAppInsights(alfieConfig);
initRaygun(alfieConfig);

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById('root'),
);
