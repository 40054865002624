import { MotochekAddressRepresentation } from 'api/Api';
import compact from 'lodash.compact';
import pick from 'lodash.pick';
import { MotochekRecord } from './motochek-parser';

function hasMailing(record: MotochekRecord) {
    const values = Object.values(
        pick(
            record,
            'mailingAddressPropertyName',
            'mailingAddressFlatFloorName',
            'mailingAddressStreetNumber',
            'mailingAddressStreetName',
            'mailingAddressBoxBagRD',
            'mailingAddressBoxBagNumber',
            'mailingAddressRDNumber',
            'mailingAddressSuburb',
            'mailingAddressTown',
            'mailingAddressPostcode',
        ),
    );

    // true if ANY value is truthy
    return values.some(val => !!val);
}

function formatStreet(flat: string, streetNumber: string, name: string) {
    const num = compact([flat, streetNumber]).join('/');

    return compact([num, name]).join(' ');
}

function formatLocality(suburb: string, rd: string, bag: string) {
    if (rd) {
        return `RD ${rd}`;
    } else if (bag) {
        return `Private Bag ${bag}`;
    } else {
        return suburb;
    }
}

function formatTown(town: string, postcode: string) {
    return compact([town, postcode]).join(' ');
}

export const toAddress = (record: MotochekRecord): string => {
    const mailing = hasMailing(record);

    if (mailing) {
        const street = formatStreet(
            record.mailingAddressFlatFloorName,
            record.mailingAddressStreetNumber,
            record.mailingAddressStreetName,
        );
        const locality = formatLocality(
            record.mailingAddressSuburb,
            record.mailingAddressRDNumber,
            record.mailingAddressBoxBagNumber,
        );
        const town = formatTown(record.mailingAddressTown, record.mailingAddressPostcode);

        return compact([record.mailingAddressPropertyName, street, locality, town]).join('\n');
    } else {
        const street = formatStreet(
            record.physicalAddressFlatFloorNumber,
            record.physicalAddressStreetNumber,
            record.physicalAddressStreetName,
        );
        const locality = formatLocality(
            record.physicalAddressSuburb,
            record.physicalAddressRDNumber,
            '',
        );
        const town = formatTown(record.physicalAddressTown, record.physicalAddressPostcode);

        return compact([record.physicalAddressPropertyName, street, locality, town]).join('\n');
    }
};

export const toMotochekAddress  = (record: MotochekRecord): MotochekAddressRepresentation => {
    if(hasMailing(record)) {
        return {
            mailingAddressBoxBagNumber: record.mailingAddressBoxBagNumber,
            mailingAddressBoxBagRd: record.mailingAddressBoxBagRD,
            mailingAddressFlatFloorName: record.mailingAddressFlatFloorName,
            mailingAddressPostcode: record.mailingAddressPostcode,
            mailingAddressPropertyName: record.mailingAddressPropertyName,
            mailingAddressRdNumber: record.mailingAddressRDNumber,
            mailingAddressStreetName: record.mailingAddressStreetName,
            mailingAddressStreetNumber: record.mailingAddressStreetNumber,
            mailingAddressSuburb: record.mailingAddressSuburb,
            mailingAddressTown: record.mailingAddressTown,
        };
    } else {
        return {
            // No box or bag for a physical address
            mailingAddressBoxBagNumber: "", 
            mailingAddressBoxBagRd: "",
            mailingAddressFlatFloorName: record.physicalAddressFlatFloorNumber,
            mailingAddressPostcode: record.physicalAddressPostcode,
            mailingAddressPropertyName: record.physicalAddressPropertyName,
            mailingAddressRdNumber: record.physicalAddressRDNumber,
            mailingAddressStreetName: record.physicalAddressStreetName,
            mailingAddressStreetNumber: record.physicalAddressStreetNumber,
            mailingAddressSuburb: record.physicalAddressSuburb,
            mailingAddressTown: record.physicalAddressTown,
        }
    }
}
