import { DebtDetails, DebtState } from 'api/Api';
import { action, observable } from 'mobx';

export class RecoveryRowViewModel {
    @observable
    isChecked = false;

    @observable
    debtState: DebtState;

    readonly amountIncludingCollectionFee: number;
    readonly amountOwedForGoods: number;
    readonly debtIdentifier: string;
    readonly organizationName: string;
    readonly licensePlate: string;
    readonly siteName: string;

    constructor(debtDetails: Readonly<DebtDetails>) {
        this.debtState = debtDetails.debtState;

        this.amountIncludingCollectionFee = debtDetails.amountIncludingCollectionFee;
        this.amountOwedForGoods = debtDetails.amountOwedForGoods;
        this.debtIdentifier = debtDetails.debtIdentifier;
        this.organizationName = debtDetails.organizationName;
        this.licensePlate = debtDetails.licensePlate;
        this.siteName = (debtDetails.incidentDetails && debtDetails.incidentDetails.SiteName) || '';
    }

    @action.bound
    toggleChecked(value?: boolean) {
        if (typeof value === 'boolean') {
            this.isChecked = value;
        } else {
            this.isChecked = !this.isChecked;
        }
    }
}
