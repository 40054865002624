import 'abortcontroller-polyfill';
import { AuthService } from 'components/Authorize/AuthService';
import { isOnlineStore } from 'utils/IsOnlineStore';
import { globalHandleError } from '../utils/globalHandleError';
import { ApiBase, EventDataRequest } from './Api.generated';
import { FetchError, FetchErrorCustomData, KnownFetchErrorNames } from './FetchError';
export * from './Api.generated';

export class Api extends ApiBase {
    constructor(readonly addAuthorizeHeaderCallback: (headers: Headers) => Promise<void>) {
        super();
    }

    fetchResponse(
        actionName: string,
        url: string,
        init: RequestInit,
    ): CancellablePromise<Response> {
        const { headers: customHeaders, method, ...otherInits } = init;
        const headers = new Headers(customHeaders || {});
        headers.set('X-Requested-With', 'XMLHttpRequest');

        const controller = new AbortController();
        const { signal } = controller;

        const promise = (async () => {
            await isOnlineStore.whenOnline();

            // Let the auth service handle appending the "authorization" headers
            await this.addAuthorizeHeaderCallback(headers);

            let response: Response;
            try {
                response = await window.fetch(url, {
                    credentials: 'same-origin',
                    signal,
                    method,
                    ...otherInits,
                    headers,
                });
            } catch (ex) {
                throw new FetchError(KnownFetchErrorNames.NetworkError, `Network Error`, {
                    actionName,
                    status: 0,
                    url,
                    method,
                    innerErrorMessage: ex.message,
                });
            }

            const { status } = response;

            if (status >= 400) {
                const errorData: FetchErrorCustomData = {
                    actionName,
                    status,
                    url,
                    method,
                };
                switch (status) {
                    case 422: // unprocessable entity
                        // we got an ApiError back, just deserialize it
                        break;
                    case 401: {
                        throw new FetchError(
                            KnownFetchErrorNames.Unauthenticated,
                            'Unauthenticated',
                            errorData,
                        );
                    }
                    case 404: {
                        // not found
                        throw new FetchError(KnownFetchErrorNames.NotFound, 'Not Found', errorData);
                    }
                    default: {
                        if (process.env.__DEV__) {
                            response.blob().then(blob => {
                                window.open(URL.createObjectURL(blob));
                            }, globalHandleError);
                        }

                        throw new FetchError(
                            KnownFetchErrorNames.InternalServerError,
                            'Internal Server Error',
                            errorData,
                        );
                    }
                }
            }

            return response;
        })() as CancellablePromise<Response>;

        promise.cancel = () => controller.abort();

        return promise;
    }

    fetch<T>(actionName: string, url: string, init: RequestInit): CancellablePromise<T> {
        const responsePromise = this.fetchResponse(actionName, url, init);
        const promise = (async () => {
            const { method } = init;
            const response = await responsePromise;
            const { status } = response;
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                return null;
            }
            try {
                return await response.json();
            } catch (ex) {
                throw new FetchError(
                    KnownFetchErrorNames.JsonParsingError,
                    ex.message || 'JSON Parsing error',
                    {
                        actionName,
                        status,
                        url,
                        method,
                    },
                );
            }
        })() as CancellablePromise<T>;

        promise.cancel = () => responsePromise.cancel && responsePromise.cancel();

        return promise;
    }

    sendChurnZeroEvent(data: EventDataRequest): CancellablePromise<void> {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: data
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(data);

        return this.fetch('analytics_TrackChurnZeroEvent', `api/spa/analytics/churnzero`, init);
    }

    getAddressCsv(): CancellablePromise<Response> {
        const init: RequestInit = { method: 'GET' };

        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');

        return this.fetchResponse('download_getAddressCSV', `api/Download/getAddressCsv`, init);
    }

    aurorAdmin_ToPdf(request: ToPdfRequest): CancellablePromise<Response> {
        const init: RequestInit = { method: 'POST' };

        // [FromBody]: request
        init.headers = new Headers();
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(request);

        return this.fetchResponse('download_ToPdf', `api/Download/ToPdf`, init);
    }
}

export const apiInstance = new Api(async headers => {
    const token = await AuthService.getTokenSilently();
    if (token) {
        headers.set('Authorization', `Bearer ${token}`);
    }
});

export interface ToPdfRequest {
    fawkesEventResourceLocators: string[];
    html: string;
}
