import { action, computed, observable } from 'mobx';
import { createDebug } from 'utils/debug';
import { SnackbarConfig } from './Snackbar.types';

const debug = createDebug('SnackbarController');

export class SnackbarControllerViewModel {
    private readonly snackbarsMap = observable.map<string, SnackbarConfig>([], { deep: false });

    @computed
    get snackbars() {
        return [...this.snackbarsMap.entries()].map(([id, config]) => ({ id, config }));
    }

    @action.bound
    showSnackbar(id: string, config: SnackbarConfig) {
        if (!this.snackbarsMap.has(id)) {
            debug(log => log`show ${id}`);
            this.snackbarsMap.set(id, config);
        } else {
            debug(log => log`already shown ${id}`);
        }
    }

    @action.bound
    handleDismiss(id: string) {
        debug(log => log`remove ${id}`);
        this.snackbarsMap.delete(id);
    }
}
