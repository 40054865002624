import { ChurnZeroEventName } from 'api/Api.generated';
import { checkCircleIcon } from 'assets/icons';
import { PublicLayout } from 'components/Layout';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import { observer } from 'mobx-react-lite';
import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React, { useContext, useEffect } from 'react';
import { confirmPlateRoute, matchOrgKey } from 'routing';
import { Context, routingStore } from 'stores';
import styled from 'styled-components';
import { H3, Logo, P, PublicButtonLink } from 'styles';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';

const CentredH3 = styled(H3)`
    align-items: center;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    margin: 2rem 0;
    & svg {
        color: ${({ theme }) => theme.primaryColor};
        margin-right: 0.5rem;
    }
`;

const CentredP = styled(P)`
    text-align: center;
`;

const Details = styled.div`
    color: #555;
    display: grid;
    font-size: ${({ theme }) => theme.smallFontSize};
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
    margin: 3rem 0;
    width: 280px;
`;

const Amount = styled.div`
    font-weight: 600;
`;

export const PaymentComplete: React.FunctionComponent<PublicAppComponent> = observer(({ vm }) => {
    useEffect(() => {
        if (vm.receiptUrl) {
            vm.sendChurnZeroEvent(
                ChurnZeroEventName.AlfiepayViewPaymentSuccessfulPage,
                vm.debtIdentifier,
            );
        } else {
            const orgKey = matchOrgKey(routingStore.location);
            if (orgKey) {
                routing.replace(confirmPlateRoute(orgKey));
            }
        }
    }, [vm.receiptUrl]);
    const { routing } = useContext(Context);
    const { logo } = getOrgDetails(vm.organizationKey);
    return (
        <PublicLayout footer={false} sendEvent={vm.sendChurnZeroEvent}>
            <Logo alt="" src={logo} />
            <CentredH3>
                <SvgIcon size="md" icon={checkCircleIcon} />
                Payment successful
            </CentredH3>
            <CentredP>Thank you.</CentredP>
            <CentredP>This will appear on your statement as Alfiepay Limited.</CentredP>
            <Details>
                <div>Payee</div>
                <Amount>{vm.name.value}</Amount>
                <div>Amount</div>
                <Amount>{getCurrencyFormat(vm.amount)}</Amount>
            </Details>
            {vm.receiptUrl && (
                <PublicButtonLink href={vm.receiptUrl} target="_blank" rel="noopener noreferrer">
                    View receipt
                </PublicButtonLink>
            )}
        </PublicLayout>
    );
});
