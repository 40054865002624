import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { pluraliser } from 'utils/pluraliser';
import { PrintTriageComponent } from './PrintTriage';

const StyledAlert = styled(Alert)`
    margin: 1rem -1rem 0;
`;

function confirmMessage(toPrint: number, rejected: number) {
    return (
        <>
            {toPrint > 0 && <b>{pluraliser(toPrint, 'recovery', 'recoveries')} as printed</b>}
            {toPrint > 0 && rejected > 0 && ' and '}
            {rejected > 0 && <b>{pluraliser(rejected, 'recovery', 'recoveries')} as rejected</b>}
        </>
    );
}

export const TriageStatus: FC<PrintTriageComponent> = observer(({ vm }) => {
    return (
        <Modal isOpen={vm.confirmMarkChanges} toggle={vm.toggleMarkChanges}>
            <ModalHeader toggle={vm.toggleMarkChanges}>Mark recoveries as processed?</ModalHeader>
            <ModalBody>
                This will mark {confirmMessage(vm.toPrint.length, vm.rejected.length)} and fetch
                more recoveries for review.
                {vm.printError && (
                    <StyledAlert color="warning">
                        Unfortunately there were some printing errors. These have been returned to{' '}
                        <i>Unassigned</i> so you can try again.
                    </StyledAlert>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={vm.handleMarkChanges} disabled={vm.marking}>
                    {vm.marking && <Spinner size="sm" className="mr-2" />}
                    OK
                </Button>
                <Button outline={true} onClick={vm.toggleMarkChanges} disabled={vm.marking}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
});
