import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import styled from 'styled-components';
import { getUniqueId } from 'utils/getUniqueId';
import { SnackbarControllerContext } from './SnackbarController';

/**
 * The components renders passed children in another react tree managed by SnackbarController
 */
export const SnackbarRenderer: React.FunctionComponent = ({ children }) => {
    const [id] = React.useState(() => getUniqueId());
    const context = React.useContext(SnackbarControllerContext);
    if (!context && process.env.__DEV__) {
        throw new Error('Snackbar cannot be rendered outside of SnackbarControllerContext');
    }

    React.useEffect(() => {
        context?.showSnackbar(id, {
            content: children,
            persistent: true,
        });

        return () => {
            context?.handleDismiss(id);
        };
    }, [id, context, children]);

    return null;
};

export const SnackbarContent = styled.div`
    display: flex;
    align-items: center;
`;

export const SnackbarText = styled.span`
    flex: 1 1 auto;
`;

export const RetryButton = styled(Button)`
    background: rgba(0, 0, 0, 0);
    border-color: transparent;
    border-radius: 2rem;
    flex: 0 0 auto;
    margin-right: 0.5rem;
`;

export const SnackbarButton: React.FC<ButtonProps> = ({ children, className, ...props }) => {
    return (
        <RetryButton color="primary" className={className} {...props}>
            {children}
        </RetryButton>
    );
};
