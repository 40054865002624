import { Input as ReactStrapInput } from 'reactstrap';
import styled from 'styled-components';

export const Input = styled(ReactStrapInput)`
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--gray);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:disabled,
    &.disabled {
        background-color: var(--light);
        opacity: 0.65;
    }
    @media (prefers-reduced-motion: reduce) {
        .form-control {
            transition: none;
        }
    }
`;
