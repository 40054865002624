import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
    getCopyDate,
    getSiteName,
    P,
    Phone,
    PrintPageComponent,
    Signature,
    Signoff,
    Url,
} from './PrintPage';

export const SecondLetterZ: FC<PrintPageComponent> = ({ recovery }) => {
    const { helpPhone, teamName } = getOrgDetails(recovery.organizationKey);
    const siteName = getSiteName(recovery);
    const copyDate = getCopyDate(recovery);

    return (
        <div>
            <P>Dear {recovery.recipient},</P>
            <P>
                We are writing to you as the registered owner of a vehicle with number plate{' '}
                <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor
                Vehicle Register.
            </P>
            <P>
                This is the 2<sup>nd</sup> letter we’ve sent regarding this unpaid fuel incident.
            </P>
            <P>
                This is a reminder that payment of {getCurrencyFormat(recovery.amount)} is still
                outstanding for fuel received at {copyDate} at {siteName}. We understand this may
                have been a mistake and would appreciate payment, or a phone call to help resolve
                the unpaid fuel incident.
            </P>
            <P>
                Please pay by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>, using{' '}
                <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
                directed. Alternatively, you can pop into any Z station to pay in person.
            </P>
            <P>
                While this matter remains unresolved <b>{recovery.licensePlate}</b> will be required
                to prepay for fuel purchases at all Z stations. If you are unable to make payment or
                unable to contact us to discuss the unpaid fuel incident, we may consider providing
                your information to a debt collection agency and this could impact your future
                credit rating.
            </P>
            <P>
                For any help with this matter, please call us on <Phone>{helpPhone}</Phone> (0800 Z
                ENERGY). You can also email us at general@z.co.nz.
            </P>

            <Signoff>
                Kind regards,
                <Signature>{teamName}</Signature>
            </Signoff>
        </div>
    );
};
