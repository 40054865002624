import { ChurnZeroEventName } from 'api/Api.generated';
import { PublicLayout } from 'components/Layout';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, useContext, useEffect } from 'react';
import { confirmDetailsRoute } from 'routing';
import { Context } from 'stores';
import styled from 'styled-components';
import { ErrorBlock, Form, Input, Label, Logo, PublicButton } from 'styles';
import { getOrgDetails } from 'styles/theme';
import { globalHandleError } from 'utils/globalHandleError';
import { loadStripe } from 'utils/load-stripe';
import { parseParam } from 'utils/parseParam';
import { PublicAppViewModel } from 'viewmodel/PublicAppViewModel';

function parseLocation(location: Location<any>) {
    const code = parseParam(location, 'code');
    const reg = parseParam(location, 'reg');
    const err = parseParam(location, 'err');

    return { ...code, ...err, ...reg };
}

export interface PublicAppComponent {
    vm: PublicAppViewModel;
}

const UpperCaseInput = styled(Input)`
    text-transform: uppercase;
    &::placeholder {
        text-transform: none;
    }
`;

export const HR = styled.hr`
    background: #cccccc;
    border: none;
    height: 1px;
    margin: 2rem 0 1rem;
    width: 100%;
`;
export const ConfirmPlate: React.FunctionComponent<PublicAppComponent> = observer(({ vm }) => {
    const { routing } = useContext(Context);
    const { logo, helpPhone } = getOrgDetails(vm.organizationKey);

    // listening to nothing, so only runs once
    useEffect(() => {
        loadStripe();
        const params = parseLocation(routing.location);
        vm.code = params.code;
        vm.reg = params.reg;

        const { err } = params;
        if (err) {
            vm.detailsNotFound = err === 'details';
            vm.detailsError = err !== 'details';
        } else {
            // we only send a view event if we don't have an error
            // as we assume errors come from already visiting here
            vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewCodePage, vm.debtIdentifier);
        }
    }, []);

    const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        vm.code = e.currentTarget.value;
    };
    const onChangeReg = (e: React.ChangeEvent<HTMLInputElement>) => {
        vm.reg = e.currentTarget.value;
    };

    const goToConfirmDetails = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            routing.push(confirmDetailsRoute(vm.organizationKey, vm.code, vm.reg));
            vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayEnteredCode, vm.debtIdentifier);
        } catch (e) {
            throw globalHandleError(e);
        }
    };

    return (
        <PublicLayout wide={false} sendEvent={vm.sendChurnZeroEvent}>
            <Logo alt="" src={logo}/>
            <Form onSubmit={goToConfirmDetails}>
                <Label>Number plate</Label>
                <UpperCaseInput
                    placeholder="Enter your number plate here"
                    value={vm.reg}
                    onChange={onChangeReg}
                />
                <Label>Unique code</Label>
                <Input
                    placeholder="Enter your unique code here"
                    value={vm.code}
                    onChange={onChangeCode}
                />
                {vm.detailsError && (
                    <ErrorBlock>Uh oh! Something went wrong checking, please try again.</ErrorBlock>
                )}
                {vm.detailsNotFound && (
                    <ErrorBlock>Those details aren't correct, please try again.</ErrorBlock>
                )}
                <PublicButton disabled={!vm.canConfirmPlate} type="submit">
                    Find my details
                </PublicButton>
            </Form>
            {helpPhone && <HR />}
        </PublicLayout>
    );
});
