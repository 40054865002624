import { AdminHeader } from 'components/Header/AdminHeader';
import { observer } from 'mobx-react-lite';
import { AdminPage } from 'pages/RecoveryAdmin/AdminPage';
import React from 'react';
import { Api, DebtLettersGenerationStats } from 'api/Api';
import { Alert, Button } from 'reactstrap';
import { Spinner } from 'components/Spinner/Spinner';
import { formatDate } from 'utils/date';

export interface GenerateLettersComponent {
    api: Pick<Api, 'aurorAdmin_StartLettersGeneration'>;
}

const LetterGenerationComponent: React.FC<GenerateLettersComponent> = ({ api }) => {
    const [inAction, setInAction] = React.useState(false);
    const [isErrorOnStartGeneration, setIsErrorOnStartGeneration] = React.useState(false);
    const [stats, setStats] = React.useState<DebtLettersGenerationStats | null>(null);

    const startPrintGeneration = React.useCallback(async () => {
        setInAction(true);
        try {
            setStats(
                await api.aurorAdmin_StartLettersGeneration(formatDate(new Date(), 'yyyy-MM-dd')),
            );
        } catch (e) {
            setIsErrorOnStartGeneration(true);
        } finally {
            setInAction(false);
        }
    }, [setStats, api]);

    return (
        <div>
            {stats && (
                <div>
                    <div>Letters count : {stats.lettersCount}</div>
                    <div>File name: {stats.fileName} </div>
                    <div>
                        When letters are generated, you will be notified in #alfiepay-letter-run
                        slack channel
                    </div>
                </div>
            )}
            {isErrorOnStartGeneration && <Alert>Error on generation start</Alert>}
            {inAction && (
                <div>
                    We are starting the generation process, it can take a couple of minutes, please,
                    wait...
                </div>
            )}
            {inAction && <Spinner />}
            {!stats && (
                <Button color="primary" onClick={startPrintGeneration} disabled={inAction}>
                    {inAction ? 'Starting generation..' : 'Start generation'}
                </Button>
            )}
        </div>
    );
};
export const GenerateLetters: React.FC<GenerateLettersComponent> = observer(({ api }) => {
    return (
        <AdminPage>
            <AdminHeader showSearch={false} refreshing={false} refresh={() => {}} />
            <div className="container">
                Welcome to the new letters generation.
                <hr />
                <LetterGenerationComponent api={api} />
            </div>
        </AdminPage>
    );
});
