import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { action, observable } from 'mobx';

class AuthServiceViewModel {
    @observable
    isAuthenticated = false;

    @observable
    private client: Auth0Client | undefined = undefined;

    async getTokenSilently() {
        if (this.client) {
            return await this.client.getTokenSilently();
        } else {
            return undefined;
        }
    }

    @action.bound
    setClient(client?: Auth0Client) {
        this.client = client;
    }
}

export const AuthService = new AuthServiceViewModel();
