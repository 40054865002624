import React from 'react';
import { ButtonProps } from 'reactstrap';
import styled, { css } from 'styled-components';
import { PublicButton } from 'styles';
import { Spinner } from './Spinner/Spinner';

export type ButtonState = 'idle' | 'working' | 'complete';

interface ButtonComponent extends ButtonProps {
    workingContent?: React.ReactNode;
    completeContent?: React.ReactNode;
    state: ButtonState;
}

const StyledButton = styled(PublicButton)<{ state: ButtonState }>`
    ${props => {
        if (props.state === 'working') {
            return css`
                opacity: 0.5;
                pointer-events: none;
            `;
        } else if (props.state === 'complete') {
            return css`
                pointer-events: none;
            `;
        } else {
            return undefined;
        }
    }}
`;

export const ActionButton: React.FC<ButtonComponent> = ({
    children,
    state,
    workingContent,
    completeContent,
    ...props
}) => {
    if (state === 'working' && workingContent) {
        children = workingContent;
    } else if (state === 'complete' && completeContent) {
        children = completeContent;
    }

    return (
        <StyledButton state={state} {...props} disabled={state !== 'idle'}>
            {state !== 'idle' && <Spinner isComplete={state === 'complete'} icon={true} />}
            {children}
        </StyledButton>
    );
};
