import { ChangeEvent } from 'react';
import { action, computed, observable } from 'mobx';
import { Api, apiInstance, CreateFileTypeInvalidApiError, FileResponse, FileType } from 'api/Api';
import { OperationState, PromiseViewModel } from '../../viewmodel/PromiseViewModel';
import { aurorStorageFileUploader } from '../../utils/aurorStorageFileUploader';

export class AddressUploadViewModel {
    @observable
    currentFile: File | null = null;

    @observable
    upload: PromiseViewModel<string> | null = null;

    readonly api: Pick<Api, 'aurorAdmin_CreateFile'>;

    constructor(api?: Pick<Api, 'aurorAdmin_CreateFile'>) {
        this.api = api || apiInstance;
    }

    private boundUploader(file: File): PromiseViewModel<string> {
        return aurorStorageFileUploader(file, (fileId) => this.createOrangeboxFile(fileId));
    }

    @action.bound
    retryFileUpload() {
        if (this.currentFile) {
            this.upload = this.boundUploader(this.currentFile);
        }
    }

    @action.bound
    azureStorageFileUploader(event: ChangeEvent<HTMLInputElement>) {
        this.currentFile = event.target.files?.item(0) ?? null;

        if (this.currentFile) {
            this.upload = this.boundUploader(this.currentFile);
        }
    }

    @computed
    get statusMessage(): string {
        switch (this.upload?.state) {
            case OperationState.Loading:
                return `Currently uploading…`;
            case OperationState.Error:
                return `Error: ${this.upload?.error}`;
            case OperationState.Done:
                return `Successfully uploaded! ✨`;
            case OperationState.Cancelled:
                return 'It was cancelled';
            default:
                return '';
        }
    }

    @transformToMobxFlow
    private async createOrangeboxFile(fileId: string): Promise<FileResponse> {
        const response = await this.api.aurorAdmin_CreateFile({
            fileId,
            fileName: '',
            fileType: FileType.Orangebox,
        });

        if (CreateFileTypeInvalidApiError.isInstance(response)) {
            throw new Error('Could not complete file upload');
        } else {
            return response;
        }
    }
}
