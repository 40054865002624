import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap.css';

import { AppCrashed } from 'components/AppCrashed/AppCrashed';
import { AppOfflineSnackbar } from 'components/AppOffline/AppOfflineSnackbar';
import { AuthorizedRoute } from 'components/Authorize/AuthorizedRoute';
import {
    SnackbarController,
    SnackbarControllerContext,
} from 'components/Snackbar/SnackbarController';
import { SnackbarControllerViewModel } from 'components/Snackbar/SnackbarControllerViewModel';
import React, { Component, ErrorInfo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    HomeComponent,
    NotFoundComponent,
    OrgComponent,
    adminOrgRoute,
    PrintTriageComponent,
    RecoveryAdminComponent,
    GenerateLettersComponent,
} from 'routing';
import 'styles/styles.css';
import 'styles/variables.css';
import { globalHandleError } from 'utils/globalHandleError';
import { isOnlineStore } from 'utils/IsOnlineStore';
import { LoadingOverlay } from './components/Loader/LoaderOverlay';

const snackbarVm = new SnackbarControllerViewModel();

interface AppState {
    isCrashed: boolean;
}
export default class App extends Component<{}, AppState> {
    state = {
        isCrashed: false,
    };
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // this is quite a bad case, there was an uncaught exception in the component tree or during init
        // the only thing we can do is to report and show an error page
        globalHandleError(error, errorInfo);

        this.setState({ isCrashed: true });
    }
    render() {
        if (this.state.isCrashed) {
            return <AppCrashed />;
        }
        return (
            <SnackbarControllerContext.Provider value={snackbarVm}>
                <Suspense fallback={<LoadingOverlay />}>
                    <Switch>
                        <AuthorizedRoute
                            path="/auror/admin/:orgKey?/print"
                            exact={true}
                            component={PrintTriageComponent}
                        />
                        <AuthorizedRoute
                            path="/auror/admin/generate-letters"
                            exact={true}
                            component={GenerateLettersComponent}
                        />
                        <AuthorizedRoute
                            path={adminOrgRoute()}
                            exact={true}
                            component={RecoveryAdminComponent}
                        />
                        <Route path="/:orgKey" component={OrgComponent} />
                        <Route path="/" exact={true} component={HomeComponent} />

                        {/* default route */}
                        <Route component={NotFoundComponent} />
                    </Switch>
                </Suspense>
                <SnackbarController vm={snackbarVm} />
                <AppOfflineSnackbar store={isOnlineStore} />
            </SnackbarControllerContext.Provider>
        );
    }
}
