import { LoadingOverlay } from 'components/Loader/LoaderOverlay';
import React from 'react';
import { Route, RouteProps } from 'react-router';
import { alfieConfig } from 'utils/alfie-config';
import { Auth0Provider, useAuth0 } from './Auth0Wrapper';
import { Login } from './Login';

export const AuthorizedRoute: React.FC<RouteProps> = ({ component, ...props }) => {
    return (
        <Route {...props}>
            <Auth0Provider
                domain={alfieConfig.auth0.domain}
                client_id={alfieConfig.auth0.clientId}
                audience={alfieConfig.auth0.audience}
                redirect_uri={`${window.location.origin}/auror/admin`}
                onRedirectCallback={onRedirectCallback}
            >
                <AuthorizedComponent component={component} {...props} />
            </Auth0Provider>
        </Route>
    );
};

const onRedirectCallback = ({ appState }: { appState?: { targetUrl: string } }) => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
    );
};

export const AuthorizedComponent: React.FC<Pick<RouteProps, 'component'>> = ({ component }) => {
    const { isAuthenticated, loading } = useAuth0();

    if (loading) {
        return <LoadingOverlay />;
    } else if (isAuthenticated) {
        return <Route component={component} />;
    } else {
        return <Login />;
    }
};
