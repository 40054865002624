import { Api, apiInstance } from 'api/Api';
import {
    ChurnZeroEventName,
    CreatePaymentApiError,
    DebtAlreadyPaidApiError,
    DebtInInvalidStateApiError,
    PaymentCreationStatus,
} from 'api/Api.generated';
import { getFetchErrorName, KnownFetchErrorNames } from 'api/FetchError';
import { ButtonState } from 'components/ActionButton';
import { FormField } from 'components/FormInput';
import { parseISO } from 'date-fns';
import { action, computed, observable } from 'mobx';
import { CardBrand } from 'pages/Payment/CardIcon';
import { parse } from 'query-string';
import { createContext } from 'react';
import { ReactStripeElements } from 'react-stripe-elements';
import {
    alreadyPaidRoute,
    cannotPayRoute,
    confirmDetailsRoute,
    paymentCompleteRoute,
} from 'routing';
import { routingStore } from 'stores';
import { globalHandleError } from 'utils/globalHandleError';
import { isOnlineStore } from 'utils/IsOnlineStore';
import { preventDefault } from 'utils/preventDefault';
import isEmail from 'validator/lib/isEmail';
import {getCurrencyFormat} from "../utils/currency";

type PlateCheckState =
    | 'working'
    | 'successful'
    | 'errored'
    | 'invalid-details'
    | 'already-paid'
    | 'invalid-debt-state';

export class PublicAppViewModel {
    @computed
    get total() {
        return this.amount + this.fees;
    }
    @computed
    get cardValid() {
        return this.numberComplete && this.expiryComplete && this.cvcComplete;
    }
    @computed
    get isValid() {
        return this.cardValidated && this.name.isValid && this.email.isValid;
    }

    @computed
    get canConfirmPlate() {
        return !!(this.reg.trim() && this.code.trim());
    }

    @computed
    get cardError() {
        return (
            this.numberError ||
            this.expiryError ||
            this.cvcError ||
            (!this.cardValidated || this.cardValid
                ? undefined
                : { message: 'Please complete your card details' })
        );
    }

    api: Pick<Api, 'access_Confirm' | 'payment_Create' | 'sendChurnZeroEvent'>;

    /**
     * Start ConfirmPlate properties
     */
    @observable
    code = '';
    @observable
    reg = '';
    @observable
    detailsNotFound = false;
    @observable
    detailsError = false;
    /**
     * End ConfirmPlate properties
     */

    @observable
    plateCheckState: PlateCheckState = 'working';

    @observable
    chargeButtonState: ButtonState = 'idle';

    @observable
    detailsConfirmed = false;

    @observable
    imageUrl = '';

    @observable
    fuelType = '';

    @observable
    licensePlate = '';

    @observable
    date?: Date = undefined;

    @observable
    locationName = '';

    @observable
    locationAddress = '';

    @observable
    locationPump = '';

    @observable
    amount = 0;

    @observable
    volume = '';

    @observable
    price = '';

    @observable
    errorMessage?: string;

    @observable
    chargeError?: string;

    @observable
    stripeError?: string;

    @observable
    incorrectDetails = false;

    @observable
    fees = 0;

    @observable
    cardBrand: CardBrand = 'unknown';

    @observable
    email = new FormField('', (val) => {
        val = val.trim();
        return !val || isEmail(val) ? null : 'Invalid email address';
    });

    @observable
    name = new FormField('', (val) => (val.trim() ? null : 'Please enter your name'));

    @observable
    cardValidated = false;

    @observable
    numberComplete = false;
    @observable
    numberError: stripe.Error | undefined = undefined;

    @observable
    expiryComplete = false;
    @observable
    expiryError: stripe.Error | undefined = undefined;

    @observable
    cvcComplete = false;
    @observable
    cvcError: stripe.Error | undefined = undefined;

    @observable
    receiptUrl: string | null = null;

    @observable
    referenceNumber: string = '';

    @observable
    debtIdentifier: string = '';

    @observable
    organizationKey: string = '';

    private isHandlingCharge = false;

    constructor() {
        this.api = apiInstance;
    }

    @action.bound
    validate() {
        this.cardValidated = true;
        const fieldsValid = [this.name, this.email].map((field) => field.validate());
        fieldsValid.push(this.numberComplete, this.expiryComplete, this.cvcComplete);

        return fieldsValid.every((valid) => valid);
    }

    @action.bound
    hideModals() {
        this.incorrectDetails = false;
    }

    @action.bound
    @preventDefault
    showIncorrectDetails() {
        this.sendChurnZeroEvent(
            ChurnZeroEventName.AlfiepayClickedIncorrectDetails,
            this.debtIdentifier,
        );
        this.incorrectDetails = true;
    }

    @action.bound
    handleCharge(stripe: ReactStripeElements.StripeProps | undefined) {
        this.charge(stripe).catch(globalHandleError);
    }

    @action.bound
    clearError() {
        this.errorMessage = undefined;
    }

    @action.bound
    handleFindDetails() {
        this.findDetails().catch(globalHandleError);
    }

    @action.bound
    sendChurnZeroEvent(
        eventName: ChurnZeroEventName,
        token: string | null,
        description: string | null = null,
    ) {
        const { referenceNumber: eventData } = this;

        this.api
            .sendChurnZeroEvent({ eventName, eventData, eventValue: 0, token, description })
            .catch(globalHandleError);
    }

    @transformToMobxFlow
    private async charge(stripe: ReactStripeElements.StripeProps | undefined) {
        if (this.isHandlingCharge) {
            return;
        }
        this.chargeError = undefined; // clear any previous error
        this.stripeError = undefined;
        this.isHandlingCharge = true;

        const { debtIdentifier: debtId, email } = this;

        try {
            const valid = this.validate();
            if (!valid) {
                return;
            }
            if (!stripe) {
                this.stripeError = `Uh oh! There was an issue contacting your payment provider. Please reload & try again.`;
                return;
            }

            this.chargeButtonState = 'working';
            await isOnlineStore.whenOnline();
            const { token } = await stripe.createToken({ name: this.name.value });

            if (!token) {
                this.stripeError = `Uh oh! There was an issue contacting your payment provider. Please reload & try again.`;
                return;
            }
            const tokenizationResult = token as ConvertStripeType<stripe.Token>;

            const response = await this.api.payment_Create({
                debtIdentifier: debtId,
                emailAddress: email.value,
                tokenizationResult,
            });

            this.chargeButtonState = 'complete';
            if (CreatePaymentApiError.isInstance(response)) {
                this.sendChurnZeroEvent(
                    ChurnZeroEventName.AlfiepayPaymentFailed,
                    this.debtIdentifier,
                    getCurrencyFormat(this.total)
                );
                switch (response.saveEventErrorType) {
                    case PaymentCreationStatus.PaymentViaStripeFailed:
                        this.chargeError =
                            response.messageForUser ||
                            'Something went wrong processing this payment.';
                        break;
                    case PaymentCreationStatus.DebtHasAlreadyBeenPaid:
                        routingStore.push(alreadyPaidRoute(this.organizationKey));
                        break;
                    default:
                        routingStore.push(cannotPayRoute(this.organizationKey));
                }
            } else {
                this.sendChurnZeroEvent(
                    ChurnZeroEventName.AlfiepayPaid,
                    this.debtIdentifier,
                    getCurrencyFormat(this.total)
                );

                const params = parse(routingStore.location.search);
                const event =
                    'm' in params
                        ? ChurnZeroEventName.AlfiepayPaidManually
                        : ChurnZeroEventName.AlfiepayPaidText;
                this.sendChurnZeroEvent(event, this.debtIdentifier,
                    getCurrencyFormat(this.total));

                if (email) {
                    this.sendChurnZeroEvent(
                        ChurnZeroEventName.AlfiepayReceiptRequested,
                        this.debtIdentifier,
                        getCurrencyFormat(this.total)
                    );
                }
                this.receiptUrl = response.receiptUrl;
                routingStore.push(paymentCompleteRoute(this.organizationKey));
            }
        } catch (e) {
            this.sendChurnZeroEvent(
                ChurnZeroEventName.AlfiepayPaymentFailed,
                this.debtIdentifier,
                getCurrencyFormat(this.total)
            );
            this.chargeError = 'Something went wrong processing this payment.';
            throw e;
        } finally {
            this.chargeButtonState = 'idle';
            this.isHandlingCharge = false;
        }
    }

    @transformToMobxFlow
    private async findDetails() {
        const { referenceNumber, licensePlate, organizationKey } = this;
        if (referenceNumber && licensePlate) {
            this.plateCheckState = 'working';
            this.clearError();

            try {
                const response = await this.api.access_Confirm({ referenceNumber, licensePlate });

                if (DebtAlreadyPaidApiError.isInstance(response)) {
                    this.plateCheckState = 'already-paid';
                    this.sendChurnZeroEvent(
                        ChurnZeroEventName.AlfiepayAlreadyPaid,
                        this.debtIdentifier,
                    );
                    return;
                } else if (DebtInInvalidStateApiError.isInstance(response)) {
                    this.plateCheckState = 'invalid-debt-state';
                    return;
                }

                if (response.organizationKey !== organizationKey) {
                    routingStore.replace(
                        confirmDetailsRoute(
                            response.organizationKey,
                            referenceNumber,
                            licensePlate,
                        ),
                    );
                    return;
                }

                const { incidentDetails } = response;

                if (incidentDetails) {
                    this.locationName = incidentDetails.SiteName || '';
                    this.locationAddress = incidentDetails.SiteAddress || '';
                    this.locationPump = incidentDetails.LocationWithinSite || '';
                    this.fuelType = incidentDetails.ProductName || '';
                    this.volume = incidentDetails.ProductQuantity || '';
                    this.price = incidentDetails.ProductPrice || '';
                } else {
                    this.locationName = '';
                    this.locationAddress = '';
                    this.locationPump = '';
                    this.fuelType = '';
                    this.volume = '';
                    this.price = '';
                }
                this.debtIdentifier = response.debtIdentifier;
                this.licensePlate = response.licensePlate;
                this.amount = response.amount;
                this.organizationKey = response.organizationKey;
                this.date = parseISO(response.dateOfIncident);
                this.imageUrl =
                    (response.fawkesImage && response.fawkesImage.thumbnailMediumUrl) || '';
                this.plateCheckState = 'successful';
            } catch (e) {
                const errorName = getFetchErrorName(e);

                if (errorName === KnownFetchErrorNames.NotFound) {
                    this.plateCheckState = 'invalid-details';
                    this.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayInvalidCode, null);
                    return;
                }

                this.plateCheckState = 'errored';
                throw e;
            }
        }
    }
}

// changing stripe types to use `null` instead of `undefined` because that's what our ApiCodegen uses
type ConvertStripeType<T> = {
    [P in keyof T]-?: ConvertStripeTypeUndefinedToNull<T, P>;
};

type ConvertStripeTypeUndefinedToNull<
    TType,
    TPropertyName extends keyof TType
> = TType extends Required<Record<TPropertyName, TType[TPropertyName]>>
    ? ConvertStripeType<TType[TPropertyName]>
    : ConvertStripeType<TType[TPropertyName]> | null;

export const PublicAppViewModelContext = createContext(new PublicAppViewModel());
