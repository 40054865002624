import { ChurnZeroEventName } from 'api/Api.generated';
import alfieImg from 'assets/images/alfie-img.png';
import alfieLogo from 'assets/images/alfiepay-logo.svg';
import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Backdrop } from './Backdrop';

export const Body = styled.div`
    max-width: calc(30rem + 1vw);
    padding: 0 1rem;

    @media (max-width: 639px) {
        max-width: 20rem;
    }
`;

const Content = styled.div`
    align-items: center;
    display: flex;
`;

const Header = styled.h1`
    font-size: calc(3rem + 1vw);
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin: 0 0 2rem;
`;

const Logo = styled.img`
    display: block;
    height: calc(2rem + 3vw);
    margin: 0.5rem auto;
`;

const P = styled.p`
    text-align: center;
    margin-bottom: 20vh;
`;

const A = styled.a`
    color: inherit;
    font-weight: 600;
    &:hover,
    &:visited,
    &:active {
        color: inherit;
    }
`;

export const Strip = styled.div`
    background: #00b5a5;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 2rem;
`;

export const Alfie = styled.img`
    position: fixed;
    height: calc(5rem + 10vw);
    bottom: calc(2rem - 0.7vw);
    right: 5vw;
    z-index: 1;
`;

export const Home: React.FC<PublicAppComponent> = ({ vm }) => {
    useEffect(() => {
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewHomePage, vm.debtIdentifier);
    });
    return (
        <Backdrop>
            <Body>
                <Header>
                    Welcome to
                    <Logo src={alfieLogo} />
                </Header>
                <Content>
                    <P>
                        Welcome to <b>Alfiepay</b>, a payment portal providing a great online
                        payment experience for some of the largest brands in Australia and New Zealand. With{' '}
                        <A href="https://stripe.com/nz" target="_blank">
                            Stripe
                        </A>{' '}
                        as our payment gateway you automatically get a secure and reliable payment
                        solution!
                    </P>
                </Content>
            </Body>
            <Alfie src={alfieImg} />
            <Strip />
        </Backdrop>
    );
};
