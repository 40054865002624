import alfiepayLogo from 'assets/images/alfiepay-logo.svg';
import { FontLink } from 'components/FontLink';
import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { useAuth0 } from './Auth0Wrapper';

const Backdrop = styled.div`
    background: var(--gray-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`;

const Instructions = styled.p`
    margin: 0 0 1rem;
`;

const Body = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
`;
const AlfiepayLogo = styled.img`
    margin: 0 0 1rem;
    width: 240px;
`;

export const Login: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    const { location: loc } = window;
    const login = () => loginWithRedirect({ redirect_uri: `${loc.origin}/auror/admin` });
    return (
        <Backdrop>
            <FontLink />
            <Body>
                <AlfiepayLogo src={alfiepayLogo} />
                <Instructions>Please sign in to continue</Instructions>
                <Button color="primary" onClick={login}>
                    Sign in
                </Button>
            </Body>
        </Backdrop>
    );
};
