import React from 'react';
import styled from 'styled-components';

export const iconSizes = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 48,
};

export type SvgIconSize = 'xs' | 'sm' | 'md' | 'lg';

interface SvgComponent extends Omit<React.HTMLProps<SVGSVGElement>, 'size'> {
    rotation?: number;
    size: SvgIconSize;
}

const StyledSvg = styled.svg<SvgComponent>`
    height: ${({ size }) => iconSizes[size]}px;
    width: ${({ size }) => iconSizes[size]}px;
    transform: rotate(${({ rotation }) => rotation}deg);
`;

export class SvgIcon extends React.Component<
    {
        icon: SpriteSymbol;
        size?: SvgIconSize;
        rotation?: number;
    } & Omit<React.HTMLProps<SVGSVGElement>, 'size' | 'ref' | 'as'>
> {
    render() {
        const {
            icon: { viewBox, id },
            size: preferredSize,
            className,
            ...attributes
        } = this.props;
        let size = preferredSize;
        if (!size) {
            // read size from viewbox ?
            const [viewBoxSizeAsString] = viewBox.split(/\s/).reverse();
            const viewBoxSize = parseInt(viewBoxSizeAsString, 10);

            if (isNaN(viewBoxSize)) {
                throw new Error(`Please check that SVG viewbox attribute is set on '${id}'`);
            } else if (viewBoxSize <= iconSizes.xs) {
                size = 'xs';
            } else if (viewBoxSize <= iconSizes.sm) {
                size = 'sm';
            } else if (viewBoxSize <= iconSizes.md) {
                size = 'md';
            } else {
                size = 'lg';
            }
        }

        return (
            <StyledSvg className={className} {...attributes} viewBox={viewBox} size={size}>
                <use xlinkHref={`#${id}`} />
            </StyledSvg>
        );
    }
}
