import { ErrorCustomDataSymbol } from 'utils/ErrorCustomDataSymbol';
import { telemetry } from 'utils/telemetry';

export function globalHandleError(error: Error, customData?: { [key: string]: any }) {
    customData = Object.assign({}, customData, error[ErrorCustomDataSymbol]);

    if (typeof error.message !== 'string' || typeof error.stack !== 'string') {
        customData!.originalError = error;
        error = new Error(
            `Error of unexpected type. The original error value is stored in the 'originalError' property.`,
        );
    }

    // tslint:disable-next-line:no-console
    console.error(error, { customData });
    telemetry.trackException(error, customData);
}
