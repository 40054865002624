import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

const size = 24;
const cx = size / 2;
const cy = size / 2;
const spinnerOffset = cx * 2 * Math.PI;

const SpinnerSvg = styled.svg<SpinnerProps>`
    height: ${size}px;
    width: ${size}px;
    vertical-align: middle;

    .tick {
        visibility: hidden;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 15;
        stroke-dashoffset: -15;
        stroke-width: 2.5px;
        stroke: #fff;
    }
    .path {
        stroke-linecap: round;
        stroke-width: 2px;
        transition: stroke 0.15s ease-in-out;
    }

    @keyframes rotator {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: ${spinnerOffset * 0.02}, ${spinnerOffset};
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: ${spinnerOffset * 0.7}, ${spinnerOffset};
            stroke-dashoffset: -${spinnerOffset * 0.25};
        }
        100% {
            stroke-dasharray: ${spinnerOffset * 0.7}, ${spinnerOffset};
            stroke-dashoffset: -${spinnerOffset * 0.98};
        }
    }

    ${props =>
        props.isComplete
            ? css`
                  animation: none;
                  transform: rotate(0);
                  transition: transform 0.15s ease-in-out;

                  & .path {
                      animation: none;
                      stroke-dasharray: ${spinnerOffset};
                      stroke-dashoffset: 0;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      transition-property: stroke, stroke-dashoffset;
                  }
                  & .tick {
                      visibility: visible;
                      stroke-dashoffset: 0;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      transition-property: stroke-dashoffset;
                      transform-origin: center;
                  }
              `
            : css`
                  animation: rotator 2s linear infinite;
                  .path {
                      animation: dash 2s ease-in-out infinite;
                      stroke-dasharray: ${spinnerOffset * 0.7}, ${spinnerOffset};
                  }
              `}
`;

const ScalingSpinner = styled.div<SpinnerProps>`
    display: inline-flex;
    ${props =>
        props.icon &&
        css`
            margin-right: 0.25rem;
        `}
    ${props =>
        props.scale !== undefined
            ? css`
                  transform: scale(${props.scale});
                  transform-origin: 0 0;
                  height: ${size * props.scale}px;
                  width: ${size * props.scale}px;
              `
            : css`
                  height: ${size}px;
                  width: ${size}px;
              `}
`;

interface SpinnerProps {
    className?: string;
    isComplete?: boolean;
    icon?: boolean;
    scale?: number;
}

export function Spinner({ className, isComplete, icon, scale }: SpinnerProps) {
    const props = useSpring({ strokeDasharray: `0,${spinnerOffset}` });

    return (
        <ScalingSpinner
            className={className}
            icon={icon}
            style={{ transform: `scale(${scale || 1})` }}
        >
            <SpinnerSvg isComplete={isComplete}>
                <animated.circle
                    className="path"
                    stroke="currentColor"
                    fill="none"
                    cx={cx}
                    cy={cy}
                    transform={`rotate(-90 ${cx} ${cy})`}
                    r={8}
                    {...props}
                />
                <path
                    stroke="currentColor"
                    fill="none"
                    transform="translate(4.5 5.5) scale(0.8)"
                    transform-origin="50% 50%"
                    className="tick"
                    d="M 11.069616317749023 2.1864840984344482 L 4.569615840911865 8.519817352294922 L 1.0696157217025757 5.019817352294922"
                />
            </SpinnerSvg>
        </ScalingSpinner>
    );
}
