import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
    getCollectionFeeDate,
    P,
    Phone,
    PrintPageComponent,
    Signature,
    Signoff,
    Url,
} from './PrintPage';

export const SecondLetterBP: FC<PrintPageComponent> = ({ recovery }) => {
    const { helpPhone, storeDescription, teamName } = getOrgDetails(recovery.organizationKey);
    const collectionFeeDate = getCollectionFeeDate(recovery);

    return (
        <div>
            <P>Dear {recovery.recipient}</P>
            <P>
                This is a reminder that payment of {getCurrencyFormat(recovery.amount)} is still
                outstanding for fuel your vehicle received. Your details have been obtained from the
                Motor Vehicle Register.
            </P>
            <P>
                As we mentioned in our previous letter, this could have been because of a system or
                process error, or a variety of other reasons.
            </P>
            <P>
                For example, if the vehicle in the photo isn't yours, you may be a victim of stolen
                or doctored plates and it's important you let us know.
            </P>
            <P>
                We want to fix this matter for you quickly, so please call us on{' '}
                <Phone>{helpPhone}</Phone> to discuss (select option 8 from the menu). Our phones 
                are open Monday to Friday between 9am and 5pm.
            </P>
            <P>
                Or alternatively, you can pay now by visiting{' '}
                <Url>alfiepay.com/{recovery.organizationKey}</Url>, using{' '}
                <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
                directed. Or you can pop into your local {storeDescription} store to pay in person.
            </P>
            {recovery.collectionFee && (
                <P>
                    As this is the second letter we have sent, a collection fee of{' '}
                    {getCurrencyFormat(recovery.collectionFee)} may be applied if the matter hasn't
                    been resolved by {collectionFeeDate}.
                </P>
            )}
            <Signoff>
                Kind regards,
                <Signature>{teamName}</Signature>
            </Signoff>
        </div>
    );
};
