import isFunction from 'lodash.isfunction';

export function getCurrencyFormatFromString(valueString: string, fractionalDigits?: number) {
    const value = parseFloat(valueString);
    if (isNaN(value)) {
        return '';
    }
    return getCurrencyFormat(value, fractionalDigits);
}

export function getCurrencyFormat(value: number, fractionalDigits: number = 2) {
    // For the currency symbol to be displayed automagically the style parameter must be set to "currency" and
    // a currency parameter must be passed. Possible values are the ISO 4217 currency codes, such as "USD" for
    // the US dollar, "EUR" for the euro. These currency values must come from the server. For now we will just
    // display all currency with the $ symbol.
    //
    // Setting the locales argument as undefined results in the runtime's default locale is used.
    //
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
    const formattedValue = toLocaleStringSupportsOptions()
        ? `${value.toLocaleString(undefined, {
            minimumFractionDigits: fractionalDigits,
            maximumFractionDigits: fractionalDigits,
          })}`
        : `${value.toFixed(fractionalDigits)}`;
    return `$${formattedValue}`;
}

// ECMA-402 says that to support options for Number.prototype.toString, an implementation must:
// - Be consistent with ECMAScript Ed 5.1 and its successors
// - Extend the built–in Number.prototype
// - Implement an Intl global object
// - Support the Intl.NumberFormat constructor
//
// https://stackoverflow.com/questions/31871771/testing-for-tolocalestring-support/31872133#31872133
function toLocaleStringSupportsOptions() {
    return typeof Intl === 'object' && Intl && isFunction(Intl.NumberFormat);
}
