import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
    getCopyDate,
    getSiteName,
    P,
    Phone,
    PrintPageComponent,
    Signature,
    Signoff,
    Url,
} from './PrintPage';

export const FirstLetterZ: FC<PrintPageComponent> = ({ recovery }) => {
    const { helpPhone, storeDescription, teamName } = getOrgDetails(recovery.organizationKey);
    const siteName = getSiteName(recovery);

    return (
        <div>
            <P>Dear {recovery.recipient},</P>
            <P>
                We are writing to you as the registered owner of a vehicle with number plate{' '}
                <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor
                Vehicle Register.
            </P>
            <P>
                At {getCopyDate(recovery)} at {siteName}, the vehicle received{' '}
                {getCurrencyFormat(recovery.amount)} of fuel without full payment being made. This
                could have happened for a variety of reasons such as a mistake whilst using
                Pay@Pump, accidentally paying for someone else’s fuel at the till or forgetting to
                pay on the day. Don’t worry, we know mistakes happen!
            </P>
            <P>
                You can pay by credit card now by visiting{' '}
                <Url>alfiepay.com/{recovery.organizationKey}</Url>, using{' '}
                <b>{recovery.licensePlate}</b> and unique code <b>{recovery.debtIdentifier}</b> when
                directed. Alternatively, you can pop into any {storeDescription} station to pay in
                person.
            </P>
            <P>
                Until payment is received <b>{recovery.licensePlate}</b> will be required to prepay
                for fuel purchases at all {storeDescription} stores. Sorry for the inconvenience
                this might cause.
            </P>
            <P>
                Please call us on <Phone>{helpPhone}</Phone> (0800 Z ENERGY) if you have any
                questions or need help. You can also email us at general@z.co.nz.
            </P>

            <Signoff>
                Kind regards,
                <Signature>{teamName}</Signature>
            </Signoff>
        </div>
    );
};
