import { getFetchErrorName, KnownFetchErrorNames } from 'api/FetchError';
import { getAppInsights } from './appInsights';
import { setRaygunUser } from './raygun';

const fetchErrorsWithReducedSeverity = [
    KnownFetchErrorNames.AbortError,
    KnownFetchErrorNames.NetworkError,
    KnownFetchErrorNames.NotFound,
];

export const telemetry = {
    setCurrentUser(user: any | undefined) {
        setRaygunUser(user);
        if (user) {
            getAppInsights().setAuthenticatedUserContext(user.sub);
        } else {
            getAppInsights().clearAuthenticatedUserContext();
        }
    },
    trackException(error: Error | any, customData?: any) {
        const fetchErrorName = getFetchErrorName(error);

        if (fetchErrorName && fetchErrorsWithReducedSeverity.includes(fetchErrorName)) {
            getAppInsights().trackException(
                error,
                undefined,
                customData,
                undefined,
                1 /* Information */,
            );
            return;
        }

        rg4js('send', { error, customData });
        getAppInsights().trackException(error, undefined, customData);
    },
};
