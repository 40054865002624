import { DebtLetterDetails, LetterTemplateCode } from 'api/Api';
import { parseISO } from 'date-fns';
import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { getOrgDetails, toStyledTheme } from 'styles/theme';
import { getCurrencyFormat, getCurrencyFormatFromString } from 'utils/currency';
import { formatDate } from 'utils/date';
import { formatVolume } from 'utils/volume';
import { PdfBody } from './PdfBody';

// WARNING: This is no longer used to generate letters. Instead see: Auror.Alfiepay.Functions>LetterRun>Templates
export interface PrintPageComponent {
    recovery: DebtLetterDetails;
}

const Page = styled.div`
    width: 216mm;
    overflow: hidden;
    position: relative;
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
    color: rgba(40, 47, 54, 0.8);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        page-break-after: always;
    }
    @media screen {
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        margin: 5mm auto;
    }
`;

const Header = styled.div`
    background-color: rgba(234, 234, 234, 0.5);
    display: grid;
    flex: 0 0 103mm;
    grid: 71mm 32mm / 1fr;
`;

const AddressBlock = styled.div`
    padding: 45mm 30mm 0;
    z-index: 1;
`;

const Name = styled.div``;

const Address = styled.div`
    white-space: pre;
`;

const Logo = styled.img`
    height: 24mm;
    top: 6mm;
    position: absolute;
    right: 12mm;
`;

const Workflow = styled.div`
    align-self: flex-start;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 19px;
    justify-self: center;
    z-index: 1;
`;

const StepsHeading = styled.div`
    color: #555;
    font-size: 1rem;
`;

const Steps = styled.div<{ late: boolean }>`
    ${({ late }) =>
        late
            ? css`
                  border-radius: 4px;
              `
            : css`
                  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15) !important;
                  border-radius: 4px;
              `}
    display: flex;
    align-content: center;
    justify-content: center;
    & h4 {
        margin: 0 0 1px;
        font-size: 7px;
        line-height: 7px;
        font-weight: 400;
        text-transform: uppercase;
    }
`;

const Step = styled.div`
    color: #555;
    display: flex;
    flex-direction: column;
    padding: 7px 8px 7px 26px;
    position: relative;
    & a {
        line-height: 14px;
    }
    & h4 {
        font-weight: 600;
    }
    &:first-child {
        padding-left: 8px;
    }
    &:after {
        content: '';
        position: absolute;
        right: -35px;
        top: 0;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 18px;
        z-index: 1;
    }
`;

const Pay = styled(Step)<{ late: boolean }>`
    align-items: center;
    flex-direction: row;
    background: #fff;
    padding-right: 12px;
    ${({ late }) =>
        late
            ? css`
                  border-radius: 0 4px 0 0;
              `
            : css`
                  border-radius: 0 4px 4px 0;
              `}
    h4 {
        align-items: center;
        display: flex;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        text-transform: unset;
    }
    svg {
        color: #009639;
        margin-left: 7px;
        height: 16px;
        width: 16px;
    }
`;

const Visit = styled(Step)<{ late?: boolean }>`
    background: ${({ theme }) => theme.pdfVisitBackground};
    color: ${({ theme }) => theme.pdfVisitText};
    ${({ late }) =>
        late
            ? css`
                  border-radius: 4px 0 0 0;
              `
            : css`
                  border-radius: 4px 0 0 4px;
              `}
    & h4 {
        color: ${({ theme }) => theme.pdfVisitHeader};
    }
    &:after {
        border-left-color: ${({ theme }) => theme.pdfVisitBackground};
    }
`;

const NumberPlate = styled(Step)`
    background: ${({ theme }) => theme.pdfRegistrationBackground};
    color: ${({ theme }) => theme.pdfRegistrationText};
    &:after {
        border-left-color: ${({ theme }) => theme.pdfRegistrationBackground};
    }
`;

const Code = styled(Step)`
    background: ${({ theme }) => theme.pdfCodeBackground};
    color: ${({ theme }) => theme.pdfCodeText};
    &:after {
        border-left-color: ${({ theme }) => theme.pdfCodeBackground};
    }
`;

const Body = styled.div`
    align-items: flex-start;
    display: grid;
    flex: 0 0 199mm;
    grid: auto 1fr / 1fr 1fr;
    padding: 0 20mm;
`;

export const P = styled.div`
    line-height: 1.5;
    margin: 0 2rem 1rem 0;
`;

const PrintDate = styled.div`
    grid-column: 1 / span 2;
    padding: 24px 0;
`;

export const Signoff = styled.div`
    margin-top: 4em;
`;

export const Signature = styled.div`
    margin-top: 1em;
`;

const Details = styled.div`
    background-color: rgba(234, 234, 234, 0.5);
    font-size: 12px;
    width: 320px;
`;

const DetailRow = styled.div`
    display: flex;
    border-top: 1px solid #cccccc;
    &:first-of-type {
        border-top: none;
    }
`;

export const Url = styled.span`
    color: ${({ theme }) => theme.primaryColor};
    display: inline-block;
    font-weight: 600;
`;

const DetailsImage = styled.img`
    border-radius: 4px 4px 0 0;
    max-width: 320px;
    max-height: 320px;
    justify-self: center;
`;

const Detail = styled.div<{ flexBasis: number }>`
    ${({ flexBasis }) =>
        css`
            flex: 1 0 ${flexBasis}px;
        `}

    padding: 8px;
    font-size: 11px;
    line-height: 12px;

    & h4 {
        color: #555555;
        font-size: 8px;
        font-weight: 600;
        line-height: 8px;
        margin: 0 0 3px;
        text-transform: uppercase;
    }
    & h5 {
        color: #555555;
        font-size: 11px;
        font-weight: 600;
        line-height: 12px;
        margin: 0;
    }
`;

const Plate = styled.div`
    align-items: center;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #fff;
    display: inline-flex;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 1;
    padding: 6px 12px;
`;

const LateStrap = styled.div`
    background: #db524b;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15) !important;
    justify-self: stretch;
    color: #fff;
    font-size: 7px;
    font-weight: 600;
    padding: 4px 0;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
`;

export const Phone = styled.b`
    white-space: nowrap;
`;

export const getCopyDate = (recovery: DebtLetterDetails) =>
    formatDate(parseISO(recovery.dateOfIncident), `h:mma 'on' EEEE d MMMM yyyy`);

export const getSiteName = (recovery: DebtLetterDetails) =>
    (recovery.incidentDetails && recovery.incidentDetails.SiteName) || '';

export const getSitePhone = (recovery: DebtLetterDetails) =>
    (recovery.incidentDetails && recovery.incidentDetails.SitePhoneNumber) || null;

export const getCollectionFeeDate = (recovery: DebtLetterDetails) => {
    if (recovery.secondDebtLetterDetails) {
        return formatDate(
            parseISO(recovery.secondDebtLetterDetails.collectionFeeAppliedOn),

            `EEEE d MMMM yyyy`,
        );
    }
    return undefined;
};

export const PrintPage: React.FC<PrintPageComponent> = ({ recovery }) => {
    const { logo } = getOrgDetails(recovery.organizationKey);
    const detailsDate = formatDate(
        parseISO(recovery.dateOfIncident),
        `EEEE d MMMM yyyy 'at' h:mma`,
    );
    const pdfGenerationDate = formatDate(parseISO(recovery.pdfGenerationDate), `EEEE d MMMM yyyy`);

    const { secondDebtLetterDetails } = recovery;

    let collectionFeeAppliedOn: string | undefined;
    if (secondDebtLetterDetails) {
        collectionFeeAppliedOn = formatDate(
            parseISO(secondDebtLetterDetails.collectionFeeAppliedOn),

            `EEEE d MMMM yyyy`,
        );
    }

    const siteName = getSiteName(recovery);
    const siteAddress = (recovery.incidentDetails && recovery.incidentDetails.SiteAddress) || '';
    const productName = (recovery.incidentDetails && recovery.incidentDetails.ProductName) || '';
    const productQuantity =
        (recovery.incidentDetails && recovery.incidentDetails.ProductQuantity) || '';
    const productPrice = (recovery.incidentDetails && recovery.incidentDetails.ProductPrice) || '';

    const theme = toStyledTheme(recovery.organizationKey);

    return (
        <ThemeProvider theme={theme}>
            <Page>
                <Header>
                    <AddressBlock>
                        <Name>{recovery.recipient}</Name>
                        <Address>{recovery.postalAddress}</Address>
                    </AddressBlock>
                    <Logo src={logo} />
                    <Workflow>
                        <StepsHeading>Payment instructions</StepsHeading>
                        <Steps late={!!collectionFeeAppliedOn}>
                            <Visit late={!!collectionFeeAppliedOn}>
                                <h4>Visit</h4>
                                <a>alfiepay.com/{recovery.organizationKey}/pay</a>
                            </Visit>
                            <NumberPlate>
                                <h4>Number plate</h4>
                                <a>{recovery.licensePlate}</a>
                            </NumberPlate>
                            <Code>
                                <h4>Unique Code</h4>
                                <a>{recovery.debtIdentifier}</a>
                            </Code>
                            <Pay late={!!collectionFeeAppliedOn}>
                                <h4>
                                    Pay
                                    <svg viewBox="0 0 400 400">
                                        <path
                                            fill="currentColor"
                                            d="M199.996 0C89.713 0 0 89.72 0 200s89.713 200 199.996 200S400 310.28 400 200 310.279 0 199.996 0zm0 373.77C104.18 373.77 26.23 295.816 26.23 200c0-95.817 77.949-173.769 173.766-173.769S373.767 104.184 373.767 200c.001 95.816-77.955 173.77-173.771 173.77z"
                                        />
                                        <path
                                            fill="currentColor"
                                            d="M272.406 134.526L169.275 237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545.003-5.125 5.125-5.125 13.425 0 18.548l50.963 50.955c2.561 2.558 5.916 3.838 9.271 3.838s6.719-1.28 9.279-3.842c.008-.011.014-.022.027-.035L290.95 153.071c5.125-5.12 5.125-13.426 0-18.546-5.122-5.123-13.427-5.123-18.544.001z"
                                        />
                                    </svg>
                                </h4>
                            </Pay>
                        </Steps>
                        {collectionFeeAppliedOn &&
                            (!recovery.collectionFee ? (
                                <LateStrap>
                                    Pay by {collectionFeeAppliedOn} to avoid further action being
                                    taken
                                </LateStrap>
                            ) : recovery.letterTemplateCode == LetterTemplateCode.BpCorporate ? (
                                <LateStrap>
                                    If you have already paid or resolved this matter, please
                                    disregard this letter
                                </LateStrap>
                            ) : (
                                <LateStrap>
                                    Pay by {collectionFeeAppliedOn} to avoid collection fees
                                </LateStrap>
                            ))}
                    </Workflow>
                </Header>
                <Body>
                    <PrintDate>{pdfGenerationDate}</PrintDate>
                    <PdfBody recovery={recovery} />
                    <Details>
                        {recovery.fawkesImage && recovery.fawkesImage.thumbnailLargeUrl && (
                            <DetailsImage src={recovery.fawkesImage.thumbnailLargeUrl} />
                        )}
                        <DetailRow>
                            <Detail flexBasis={320}>
                                <h4>Date</h4>
                                <div>{detailsDate}</div>
                            </Detail>
                        </DetailRow>
                        <DetailRow>
                            <Detail flexBasis={320}>
                                <h4>Location</h4>
                                <h5>{siteName}</h5>
                                <div>{siteAddress}</div>
                            </Detail>
                        </DetailRow>
                        <DetailRow>
                            <Detail flexBasis={180}>
                                <h4>Number plate</h4>
                                <Plate>{recovery.licensePlate}</Plate>
                            </Detail>
                            <Detail flexBasis={140}>
                                <h4>Fuel Type</h4>
                                <div>{productName}</div>
                            </Detail>
                        </DetailRow>
                        <DetailRow>
                            <Detail flexBasis={110}>
                                <h4>Cost per litre</h4>
                                <div>{getCurrencyFormatFromString(productPrice, 4)}</div>
                            </Detail>
                            <Detail flexBasis={70}>
                                <h4>Litres</h4>
                                <div>{formatVolume(productQuantity)}</div>
                            </Detail>
                            <Detail flexBasis={140}>
                                <h4>Amount</h4>
                                <div>{getCurrencyFormat(recovery.amount)}</div>
                            </Detail>
                        </DetailRow>
                    </Details>
                </Body>
            </Page>
        </ThemeProvider>
    );
};

export default PrintPage;
