import { Location } from 'history';
import { parse } from 'query-string';

function doParse(location: undefined | Location<any>, key: string) {
    let val: string[] | string = '';
    if (location) {
        val = parse(location.search)[key] || '';
    }
    return val;
}

/**
 * Handles `location` being undefined
 * @param location
 * @param key
 * @returns `{ foo: 'x' }` for 'foo=x'
 */
export function parseParam(location: undefined | Location<any>, key: string) {
    const parsed = doParse(location, key);

    let val;

    if (Array.isArray(parsed)) {
        val = parsed[0] || '';
    } else {
        val = parsed || '';
    }

    return { [key]: val };
}

/**
 * Handles `location` being undefined
 * @param location
 * @param key
 * @returns `['x']` for 'foo=x'
 */
export function parseArrayParam(location: undefined | Location<any>, key: string) {
    const parsed = doParse(location, key);

    if (Array.isArray(parsed)) {
        return parsed;
    } else {
        return [parsed];
    }
}
