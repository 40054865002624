import { Spinner } from 'components/Spinner/Spinner';
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    align-items: center;
    background-color: #ccc;
    display: flex;
    position: fixed;
    top: 0;
    height: 100vh;
    justify-content: center;
    left: 0;
    width: 100vw;
`;

export const LoadingOverlay = () => {
    return (
        <Overlay>
            <Spinner scale={3} />
        </Overlay>
    );
};
