import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'styles/theme';

export const AdminPageEl = styled.div`
    background: var(--light-blue);
    min-height: 100vh;
    padding-bottom: 5rem;
`;
export const AdminPage: FC = ({ children }) => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <AdminPageEl>{children}</AdminPageEl>
        </ThemeProvider>
    );
};
