import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { toRecoveryKey } from 'utils/recovery';
import {
    DetailStrap,
    EmptyTriageCard,
    LicensePlate,
    Organization,
    PrintTriageComponent,
    TriageHeader,
    TriageStack,
} from './PrintTriage';
import { TriageCard } from './TriageCard';

export const RejectedStack: FC<PrintTriageComponent> = observer(({ vm }) => {
    return (
        <div>
            <TriageHeader>
                Invalid {vm.rejected.length > 0 && `(${vm.rejected.length})`}
            </TriageHeader>

            {vm.rejected.length === 0 ? (
                <EmptyTriageCard>None yet</EmptyTriageCard>
            ) : (
                <TriageStack>
                    {vm.rejectedData.map((recovery, i) => {
                        return (
                            <TriageCard
                                key={toRecoveryKey(recovery)}
                                index={i}
                                recovery={recovery}
                                small={true}
                                translateX={50}
                            >
                                <DetailStrap>
                                    <LicensePlate>{recovery.licensePlate}</LicensePlate>
                                    <Organization>{recovery.organizationKey}</Organization>
                                </DetailStrap>
                            </TriageCard>
                        );
                    })}
                </TriageStack>
            )}
        </div>
    );
});
