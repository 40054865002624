import { Spinner } from 'components/Spinner/Spinner';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { pluraliser } from 'utils/pluraliser';
import { toRecoveryKey } from 'utils/recovery';
import {
    DetailStrap,
    EmptyTriageCard,
    LicensePlate,
    Organization,
    PrintTriageComponent,
    TriageHeader,
    TriageStack,
} from './PrintTriage';
import { TriageCard } from './TriageCard';

export const ToPrintStack: FC<PrintTriageComponent> = observer(({ vm }) => {
    return (
        <div>
            <TriageHeader>
                To print {vm.toPrint.length > 0 && `(${vm.toPrint.length})`}
            </TriageHeader>
            {vm.toPrint.length === 0 ? (
                <EmptyTriageCard>None yet</EmptyTriageCard>
            ) : (
                <>
                    <TriageStack>
                        {vm.toPrintData.map((recovery, i) => {
                            return (
                                <TriageCard
                                    key={toRecoveryKey(recovery)}
                                    index={i}
                                    small={true}
                                    recovery={recovery}
                                    translateX={-50}
                                >
                                    <DetailStrap>
                                        <LicensePlate>{recovery.licensePlate}</LicensePlate>
                                        <Organization>{recovery.organizationKey}</Organization>
                                    </DetailStrap>
                                </TriageCard>
                            );
                        })}
                    </TriageStack>
                </>
            )}
            <Button
                block={true}
                color="primary"
                onClick={vm.handlePrint}
                disabled={vm.printing || vm.toPrint.length === 0}
            >
                {vm.printing ? (
                    <>
                        <Spinner className="mr-2" /> Printed {vm.successfulPrintCount}/
                        {vm.toPrint.length}
                    </>
                ) : (
                    <>
                        Print{' '}
                        {vm.toPrint.length > 0 &&
                            pluraliser(vm.toPrint.length, 'recovery', 'recoveries')}
                    </>
                )}
            </Button>
        </div>
    );
});
