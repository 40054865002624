import { SnackbarRenderer, SnackbarText } from 'components/Snackbar/Snackbar';
import { AppOfflineMessage } from 'components/Snackbar/SnackbarMessages';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { isOnlineStore } from 'utils/IsOnlineStore';

export const AppOfflineSnackbar = observer(
    ({
        store: { offlineUsageReportedCount },
    }: {
        store: Pick<typeof isOnlineStore, 'offlineUsageReportedCount'>;
    }) => {
        if (offlineUsageReportedCount > 0) {
            return (
                <SnackbarRenderer>
                    <SnackbarText>{AppOfflineMessage}</SnackbarText>
                </SnackbarRenderer>
            );
        }

        return null;
    },
);
