import debug from 'debug';

/**
 * @summary
 * Creates a namespaced `DebugFunction`.
 * The `DebugFunction` takes another function - `LogFunction` - as a parameter that actually prepares and outputs the debug message.
 * The `LogFunction` is only called when the namespace is enabled.
 * All this function in function syntax is needed to ensure that we don't evaluate message parameters when the debug namespace is disabled
 * because it can create performance issues.
 *
 * @example
 * const debug = createDebug('SnackbarController');
 *
 * debug(log => log`Log line with ${value1} and ${value2}`);
 * debug(log => log`My huge mobx viewmodel ${mobx.toJs(vm)}`);
 * // mobx.toJs(vm) will only be evaluated when the debug namespace is enabled.
 * // Otherwise, it's going to be a huge performance, especially if called on each keypress.
 *
 * @description
 * In order to use it in the browser, setup a `debug` variable in local storage with a comma separated list of namespaces
 * (DevTools -> Application -> Local Storage -> http://localhost:5000 -> double-click under the last variable).
 * It also supports wildcard.
 * For more configuration options: https://www.npmjs.com/package/debug#wildcards
 *
 * NOTE: All our debugs are prefaced with 'Auror:'.
 *
 * @example
 * // all
 * localStorage.debug = `Auror:*`;
 * // only SnackbarController
 * localStorage.debug = `Auror:SnackbarController:*`;
 *
 * @description
 * TIP: Getting the correct debug lines in console can be achieved by configuring blackboxing in Chrome Devtools -> Settings -> Blackboxing.
 * Add the following patterns:
 * - `/raygun.*$`
 * - `/debug.*$`
 */
export const createDebug = (namespace: string): DebugFunction => {
    const ns = debug(`Auror:${namespace}`);

    const debugFunction = (logFactory: (log: LogFunction) => void) => {
        if (!ns.enabled) {
            return;
        }

        logFactory((literals: TemplateStringsArray, ...args: any[]) => {
            const message = literals.join('%o');
            ns(message, ...args);
        });
    };

    debugFunction.enabled = () => ns.enabled;
    debugFunction.extend = (extendedNamespace: string) =>
        createDebug([namespace, extendedNamespace].join(':'));
    return debugFunction;
};

export type LogFunction = (literals: TemplateStringsArray, ...args: any[]) => void;

export interface DebugFunction {
    (log: (log: LogFunction) => void): void;
    enabled(): boolean;
    extend(namespace: string): DebugFunction;
}
