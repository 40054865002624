import { action, computed, observable } from 'mobx';
import { globalHandleError } from '../utils/globalHandleError';
import { tryCancelPromise } from '../utils/tryCancelPromise';

export enum OperationState {
    Done,
    Error,
    Loading,
    Cancelled
}

export interface ResolvedOperation<T> {
    value: T;
    error: undefined;
    state: OperationState.Done;
}

export interface RejectedOperation {
    value: undefined;
    error: Error;
    state: OperationState.Error;
}

export interface LoadingOperation {
    value: undefined;
    error: undefined;
    state: OperationState.Loading;
}

export interface CancelledOperation {
    value: undefined;
    error: undefined;
    state: OperationState.Cancelled;
}

export type Operation<T> =
    | ResolvedOperation<T>
    | RejectedOperation
    | LoadingOperation
    | CancelledOperation;

/**
 * Handles the loading and error states for any single promise in an observable manner
 * Construct one with no promise for a placeholder that will always be 'loading'
 */
export type PromiseViewModel<T> = Operation<T> & {
    done: Promise<void>;
    tryCancel(): void;
};

export function createPromiseViewModel<T>(
    promise?: Promise<T>,
    onResolve?: (x: T) => void,
    onReject?: (e: Error) => void,
): PromiseViewModel<T> {
    return new PromiseViewModelImplementation<T>(promise, onResolve, onReject) as PromiseViewModel<
        T
    >;
}

export function isPromiseViewModel<TValue>(
    value: PromiseViewModel<TValue> | unknown,
): value is PromiseViewModel<TValue> {
    return value instanceof PromiseViewModelImplementation;
}

class PromiseViewModelImplementation<T> {
    @observable
    value?: T;

    @observable
    loading = true;

    @observable
    error?: Error;

    done: Promise<void>;

    private isCancelled = false;

    constructor(
        private readonly promise?: Promise<T>,
        private onResolve?: (x: T) => void,
        private onReject?: (e: Error) => void,
    ) {
        // todo: allow replacing the promise (and cancelling the old one?). Sounds way more complicated than just making a new PromiseViewModel!
        if (promise) {
            this.done = promise.then(this.resolve, this.reject);
        } else {
            // is never resolving promise what we want here ?
            // tslint:disable-next-line:no-empty
            this.done = new Promise(() => {});
        }
    }

    @action.bound
    tryCancel(): void {
        if (this.loading) {
            this.isCancelled = true;
            this.loading = false;
            tryCancelPromise(this.promise);
        }
    }

    @action.bound
    private resolve(x: T) {
        if (this.isCancelled) {
            return;
        }

        this.loading = false;
        this.value = x;

        if (this.onResolve) {
            this.onResolve(x);
        }
    }

    @action.bound
    private reject(e: Error) {
        if (this.isCancelled) {
            return;
        }

        this.loading = false;
        this.error = e;
        globalHandleError(e, { fromPromiseViewModel: true });

        if (this.onReject) {
            this.onReject(e);
        }
    }

    @computed
    get state(): OperationState {
        return this.isCancelled
            ? OperationState.Cancelled
            : this.loading
            ? OperationState.Loading
            : this.error
            ? OperationState.Error
            : OperationState.Done;
    }
}
