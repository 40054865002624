import { initAppInsightsFetchMonitor } from 'application-insights-fetch-monitor';
import { AppInsights } from 'applicationinsights-js';
import { globalHandleError } from 'utils/globalHandleError';
import { AlfieConfig } from './alfie-config';
import { createDebug } from './debug';

const debug = createDebug('AppInsights');

const ignoreRemoteDependencyTargets = ['api.raygun.io', 'rs.fullstory.com'];

export function setupAppInsights(config?: AlfieConfig) {
    if (!config) {
        return;
    }
    const { instrumentationKey } = config.appInsights;

    if (!instrumentationKey) {
        return;
    }

    AppInsights.downloadAndSetup!({
        instrumentationKey,
        correlationHeaderExcludedDomains: ignoreRemoteDependencyTargets,
    });

    appInsights.queue.push(() => {
        appInsights.context.addTelemetryInitializer(envelope => {
            // do not log requests to raygun
            if (
                envelope.data.baseType === 'RemoteDependencyData' &&
                ignoreRemoteDependencyTargets.includes(envelope.data.baseData.target)
            ) {
                return false;
            }

            if (envelope.data.baseType === 'ExceptionData') {
                const { exceptions } = envelope.data.baseData;
                if (exceptions instanceof Array) {
                    // only send error when exceptions have full stack
                    // otherwise they come from browser extensions and can be ignored
                    return exceptions.some(x => x.hasFullStack);
                }
            }

            // debug log all the telemetry details
            debug(
                log =>
                    log`${envelope.name.substr(envelope.name.lastIndexOf('.') + 1)}: ${
                        envelope.data.baseData
                    }`,
            );
            return true;
        });
    });

    initAppInsightsFetchMonitor().catch(globalHandleError);
}

export function getAppInsights() {
    return AppInsights;
}
