import { DebtLetterDetails } from 'api/Api';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { toRecoveryKey } from 'utils/recovery';
import { visibleCardCount } from './PrintTriageViewModel';
import { TriageImage } from './TriageImage';

interface TriageCardComponent {
    index: number;
    initial?: boolean;
    recovery: DebtLetterDetails;
    small?: boolean;
    translateX: number;
}

const Card = styled(motion.div)`
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.25);
    margin: 0;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    padding: 0.25rem 0.25rem 0;
`;

export const TriageCard: React.FC<TriageCardComponent> = ({
    children,
    index,
    initial,
    recovery,
    small = false,
    translateX,
}) => {
    const imgUrl = recovery.fawkesImage && recovery.fawkesImage.imageFullSizeUrl;
    return (
        <Card
            key={toRecoveryKey(recovery)}
            initial={
                initial !== false
                    ? {
                          opacity: 0,
                          scale: 1,
                          translateX,
                          translateZ: `${-index * (small ? 1 : 2)}rem`,
                      }
                    : false
            }
            animate={{
                opacity: 1,
                scale: 1,
                translateX: 0,
                translateZ: `${-index * (small ? 1 : 2)}rem`,
                transition: { delay: small ? 0.05 : 0 },
                zIndex: visibleCardCount - index,
            }}
            exit={{
                opacity: 0,
                scale: 0.9,
                translateX,
                translateZ: '0rem',
                zIndex: visibleCardCount - index,
            }}
        >
            {imgUrl && <TriageImage small={small} src={imgUrl} />}
            {children}
        </Card>
    );
};
