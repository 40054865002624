export interface AlfieConfig {
    auth0: {
        domain: string;
        clientId: string;
        audience: string;
    };
    appInsights: {
        instrumentationKey: string;
    };
    raygun: {
        apiKey: string;
    };
    productInformation: {
        region: string;
    };
    staticFilesUrl?: string;
    stripe: {
        apiKey: string;
    };
}

export const alfieConfig = {} as AlfieConfig;

const configEl = document.querySelector('#root div[data-type="config"]');
if (configEl) {
    const json = configEl.innerHTML;
    Object.assign(alfieConfig, JSON.parse(json));
} else {
    // couldn't find config block, add a minimally valid structure
    Object.assign(alfieConfig, {
        auth0: { domain: '', clientId: '', audience: '' },
        appInsights: { instrumentationKey: '' },
        raygun: { apiKey: '' },
        productInformation: { region: '' },
        stripe: { apiKey: 'pk_test_atHWm6UAe7ws2gW9XAapoJVP00V6fiD4xD' },
    });
}

if (alfieConfig.staticFilesUrl) {
    __webpack_public_path__ = alfieConfig.staticFilesUrl + '/';
}
