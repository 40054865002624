import { FileResponse } from '../api/Api';
import { uuid } from './uuid';
import { newPipeline, BlockBlobClient, AnonymousCredential } from '@azure/storage-blob';
import { AbortController } from '@azure/abort-controller';
import { createPromiseViewModel, PromiseViewModel } from '../viewmodel/PromiseViewModel';

export function aurorStorageFileUploader(
    file: File,
    createFileResponse: (fileId: string) => Promise<FileResponse>,
): PromiseViewModel<string> {
    return createPromiseViewModel(
        transformToMobxFlow(async () => {
            return await uploadAndGetFileId(file, createFileResponse);
        })(),
    );
}

export async function uploadAndGetFileId(
    file: File,
    createFileResponse: (fileId: string) => Promise<FileResponse>,
) {
    const fileId = uuid();
    const response = await createFileResponse(fileId);
    await uploadBrowserData(response.uploadUrl, file as Blob, fileId);
    return fileId;
}

const uploadBrowserData = (uploadUriWithSasToken: string, blob: Blob, fileId: string) => {
    const pipeline = newPipeline(new AnonymousCredential());

    const aborter = new AbortController();

    const blobClient = new BlockBlobClient(uploadUriWithSasToken, pipeline);

    const promise = blobClient.uploadData(blob, {
        abortSignal: aborter.signal,
        blobHTTPHeaders: {
            blobContentType: blob.type,
        },
        metadata: fileId ? { fileId } : undefined,
        maxSingleShotSize: 100000000, // 100 MB is the max Content-Length for Azure CDN
    });

    Object.assign(promise, {
        cancel: () => {
            aborter.abort();
        },
    });
    return promise;
};
