import {
    Api,
    apiInstance,
    DebtState,
    DebtStateChangeResult,
    DebtStateTrigger,
    InvalidDebtStateApiError,
} from 'api/Api';
import { MotochekUploadViewModel } from 'components/Motochek/MotochekUploadViewModel';
import { Action, RecoveryBlockViewModel } from 'components/RecoveryBlock/RecoveryBlockViewModel';
import { RecoveryRowViewModel } from 'components/RecoveryBlock/RecoveryRowViewModel';
import { SnackbarControllerViewModel } from 'components/Snackbar/SnackbarControllerViewModel';
import { format } from 'date-fns';
import { action, autorun, computed, observable } from 'mobx';
import { addressUploadRoute, printTriageRoute } from 'routing';
import { getUniqueId } from 'utils/getUniqueId';
import { globalHandleError } from 'utils/globalHandleError';
import { downloadCSVs } from 'utils/motochek-csv';

export interface AfterActionPrompt {
    debtIds: string[];
    actionTitle: string;
    trigger: DebtStateTrigger;
}

export interface AfterActionViewModel {
    afterAction: AfterActionPrompt | null;
    fireTrigger: (debtIds: string[], trigger: DebtStateTrigger) => void;
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x);
}

export enum BlockTitles {
    GenerateLettersAndSendToMailhouse = 'Generate letters and send to mailhouse',
    NewDebt = 'New debt',
    TextNotifications = 'Text notifications',
    UploadPostalAddressFromMotochek = 'Upload postal address from Motochek',
    LetterWaitingPeriod = 'Letter waiting period',
    AllPossibleCommunicationsSent = 'All possible communications sent',
    UnableToSendCommunications = 'Unable to send communications',
    PaidInStore = 'Paid in-store',
    DebtExpired = 'Debt expired',
    PaidOnlineViaAlfiepay = 'Paid online via Alfiepay',
    SendToMotochekForPostalAddress = 'Send to Motochek for postal address',
    NoLongerADebt = 'No longer a debt',
    SendToOrangeboxForOrdering = 'Send to Orangebox for cleaning and ordering',
    WaitForOrangeboxOrdering = 'Upload clean data from Orangebox',
}

export type BlockTitle =
    | BlockTitles.GenerateLettersAndSendToMailhouse
    | BlockTitles.NewDebt
    | BlockTitles.TextNotifications
    | BlockTitles.UploadPostalAddressFromMotochek
    | BlockTitles.LetterWaitingPeriod
    | BlockTitles.AllPossibleCommunicationsSent
    | BlockTitles.UnableToSendCommunications
    | BlockTitles.PaidInStore
    | BlockTitles.DebtExpired
    | BlockTitles.PaidOnlineViaAlfiepay
    | BlockTitles.SendToMotochekForPostalAddress
    | BlockTitles.NoLongerADebt
    | BlockTitles.SendToOrangeboxForOrdering
    | BlockTitles.WaitForOrangeboxOrdering;

const activeBlockTitles: BlockTitle[] = [
    BlockTitles.GenerateLettersAndSendToMailhouse,
    BlockTitles.SendToMotochekForPostalAddress,
    BlockTitles.UploadPostalAddressFromMotochek,
    BlockTitles.SendToOrangeboxForOrdering,
    BlockTitles.WaitForOrangeboxOrdering,
];

export class RecoveryAdminViewModel {
    @computed
    get motochekPlates() {
        const { motochekIds } = this;
        return this.recoveries
            .filter((recovery) => motochekIds.includes(recovery.debtIdentifier))
            .map((recovery) => recovery.licensePlate);
    }

    @computed
    get printVm() {
        return this.getBlockVm(BlockTitles.GenerateLettersAndSendToMailhouse);
    }

    @computed
    get motochekVm() {
        return this.getBlockVm(BlockTitles.SendToMotochekForPostalAddress);
    }

    @computed
    get blockVms() {
        return Object.values(this.blockMap);
    }

    @computed
    get activeBlocks() {
        return this.blockVms.filter((block) => activeBlockTitles.includes(block.title));
    }

    @computed
    get informationalBlocks() {
        return this.blockVms.filter((block) => !activeBlockTitles.includes(block.title));
    }

    readonly api: Pick<
        Api,
        | 'aurorAdmin_GetAllDebtDetails'
        | 'aurorAdmin_UploadDebtPostalAddresses'
        | 'aurorAdmin_OverrideDebtState'
        | 'aurorAdmin_GetDebtLetterDetails'
        | 'aurorAdmin_FireDebtStateTrigger'
        | 'aurorAdmin_CreateFile'
        | 'aurorAdmin_StartLettersGeneration'
    > = apiInstance;

    @observable
    recoveries: RecoveryRowViewModel[] = [];

    @observable
    search: string = '';

    @observable
    motochekIds: string[] = [];

    @observable
    ingestingMotochek = false;

    @observable
    fetching = false;

    @observable
    actionInProgress = false;

    @observable
    uploadError?: Error = undefined;

    @observable
    uploading = false;

    @observable
    afterAction: AfterActionPrompt | null = null;

    @observable
    blockMap: { [title in BlockTitle]: RecoveryBlockViewModel };

    @observable
    invalidPrintRequest: InvalidDebtStateApiError | null = null;

    @observable
    orgFilter?: string;

    @observable
    motochekUploadVm: MotochekUploadViewModel | null = null;

    constructor(readonly snackbarVm: SnackbarControllerViewModel) {
        this.blockMap = {
            [BlockTitles.NewDebt]: this.createBlockVm(
                BlockTitles.NewDebt,
                'This event has arrived from Auror, but not yet been put into the most relevant state, this happens automatically every hour between 9am and 5pm.',
            ),
            [BlockTitles.TextNotifications]: this.createBlockVm(
                BlockTitles.TextNotifications,
                'The debt includes a phone number for the person, and is either receiving a 1st or 2nd text (if the number is valid).',
            ),
            [BlockTitles.LetterWaitingPeriod]: this.createBlockVm(
                BlockTitles.LetterWaitingPeriod,
                'This event is in the waiting period before sending letters, these will move into the next relevant state automatically when they are ready to receive letter 1 or letter 2.',
            ),
            [BlockTitles.SendToMotochekForPostalAddress]: this.createBlockVm(
                BlockTitles.SendToMotochekForPostalAddress,
                'These events need to be downloaded and sent to Motochek for an address.',
                {
                    rowAction: {
                        label: 'Download Plates',
                        fn: () => {
                            downloadCSVs(this.motochekPlates);
                            this.afterAction = {
                                actionTitle: 'Plates downloaded',
                                debtIds: this.motochekVm.selectedIds,
                                trigger: DebtStateTrigger.RequestedPostalAddress,
                            };
                            this.motochekVm.clearSelection();
                        },
                    },
                    onSelect: (motochekIds: string[]) => (this.motochekIds = motochekIds),
                },
            ),
            [BlockTitles.UploadPostalAddressFromMotochek]: this.createBlockVm(
                BlockTitles.UploadPostalAddressFromMotochek,
                'This event has been sent to Motochek and is waiting for the postal address to be uploaded.',
            ),
            [BlockTitles.SendToOrangeboxForOrdering]: this.createBlockVm(
                BlockTitles.SendToOrangeboxForOrdering,
                'These events need to be downloaded and sent to Orangebox so they can send us an order and fix up the addresses',
                {
                    rowAction: {
                        label: 'Download Addresses',
                        fn: transformToMobxFlow(async () => {
                            if (this.actionInProgress) {
                                return;
                            }
                            try {
                                this.actionInProgress = true;
                                const response = await apiInstance.getAddressCsv();
                                const csv = await response.text();
                                const csvUrl = encodeURIComponent(csv);
                                const hiddenElement = document.createElement('a');
                                hiddenElement.href = `data:text/csv;charset=utf-8,${csvUrl}`;
                                hiddenElement.target = '_blank';
                                hiddenElement.download = this.getCsvDownloadFileName();
                                hiddenElement.click();
                            } catch (e) {
                                this.snackbarVm.showSnackbar(getUniqueId(), {
                                    content: 'Oops! Could not download Orangebox CSV',
                                    persistent: true,
                                });
                                globalHandleError(e);
                            } finally {
                                this.actionInProgress = false;
                            }
                        }),
                    },
                },
            ),
            [BlockTitles.WaitForOrangeboxOrdering]: this.createBlockVm(
                BlockTitles.WaitForOrangeboxOrdering,
                'Waiting for Orangebox to send a file with the correct order.',
                {
                    rowAction: {
                        label: 'Upload Orangebox sorted file',
                        goto: addressUploadRoute(),
                    },
                },
            ),
            [BlockTitles.GenerateLettersAndSendToMailhouse]: this.createBlockVm(
                BlockTitles.GenerateLettersAndSendToMailhouse,
                'Postal addresses received from Motochek, ready to generate letters and send to the mailhouse to distribute.',
                {
                    blockAction: { label: 'Print', fn: this.printLetters },
                },
            ),
            [BlockTitles.PaidOnlineViaAlfiepay]: this.createBlockVm(
                BlockTitles.PaidOnlineViaAlfiepay,
                'This event has been paid in full online through Alfiepay',
            ),
            [BlockTitles.PaidInStore]: this.createBlockVm(
                BlockTitles.PaidInStore,
                'We think this means that the debt has been paid in-store, or any other debt state change has happened in Fawkes.',
            ),
            [BlockTitles.AllPossibleCommunicationsSent]: this.createBlockVm(
                BlockTitles.AllPossibleCommunicationsSent,
                'This event has had all possible communications, i.e. 2 texts if there was a valid mobile number, and/or 2 letters if we had a postal address',
            ),
            [BlockTitles.UnableToSendCommunications]: this.createBlockVm(
                BlockTitles.UnableToSendCommunications,
                'Events will transition here manually if Motochek gives us a non-valid address and no texts have been sent, otherwise manually by Auror Admins for reasons (which can include no/incorrect mobile number, no/incorrect license plate details, no address returned from motochek, or the debt not being valid because the intel entered in Auror does not meet the criteria set by the customer).',
            ),
            [BlockTitles.DebtExpired]: this.createBlockVm(
                BlockTitles.DebtExpired,
                'The event has not been paid, and 75 days has passed since the incident was created so it no longer qualifies for debt recovery through Alfiepay, has been marked as “3rd-Party Debt Collection” in Fawkes, and an exported list has been provided to the customer.',
            ),
            [BlockTitles.NoLongerADebt]: this.createBlockVm(
                BlockTitles.NoLongerADebt,
                'The event has been edited and it longer meets the criteria for a debt',
            ),
        };

        // make sure all debtstates are presented to "addBlock"
        Object.values(DebtState).forEach(this.registerState);

        autorun(() => {
            this.blockVms.forEach((blockVm) => {
                blockVm.search = this.search;
            });
        });
    }

    getCsvDownloadFileName() {
        return `orangebox-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.csv`;
    }

    getBlockVm(title: BlockTitle) {
        return this.blockMap[title];
    }

    createBlockVm(
        title: BlockTitle,
        description: string,
        params?: {
            blockAction?: Action;
            rowAction?: Action;
            onSelect?: (ids: string[]) => void;
        },
    ) {
        return new RecoveryBlockViewModel(
            {
                description,
                getAllRecoveries: () => this.recoveries,
                onSelect: params && params.onSelect,
                blockAction: params && params.blockAction,
                rowAction: params && params.rowAction,
                states: [],
                title,
            },
            this.api,
        );
    }

    @action.bound
    registerBlockState(title: BlockTitle, state: DebtState) {
        this.getBlockVm(title).states.push(state);
    }

    @action.bound
    registerState(state: DebtState) {
        switch (state) {
            case DebtState.New:
            case DebtState.InDebt:
                this.registerBlockState(BlockTitles.NewDebt, state);
                break;

            case DebtState.SendingSMS1:
            case DebtState.WaitingToSendSMS1:
            case DebtState.SendingSMS2:
            case DebtState.WaitingToSendSMS2:
                this.registerBlockState(BlockTitles.TextNotifications, state);
                break;

            case DebtState.NeedPostalAddress:
                this.registerBlockState(BlockTitles.SendToMotochekForPostalAddress, state);
                break;

            case DebtState.WaitingForPostalAddress:
                this.registerBlockState(BlockTitles.UploadPostalAddressFromMotochek, state);
                break;

            case DebtState.ReadyToSendLetter1:
            case DebtState.ReadyToSendLetter2:
                this.registerBlockState(BlockTitles.GenerateLettersAndSendToMailhouse, state);
                break;

            case DebtState.WaitingToSendLetter1:
            case DebtState.WaitingToSendLetter2:
                this.registerBlockState(BlockTitles.LetterWaitingPeriod, state);
                break;

            case DebtState.AllPossibleCommunicationsSent:
                this.registerBlockState(BlockTitles.AllPossibleCommunicationsSent, state);
                break;

            case DebtState.HandledExternally:
                this.registerBlockState(BlockTitles.PaidInStore, state);
                break;

            case DebtState.NotifiedExpired:
            case DebtState.NotifyExpired:
                this.registerBlockState(BlockTitles.DebtExpired, state);
                break;

            case DebtState.Settled:
                this.registerBlockState(BlockTitles.PaidOnlineViaAlfiepay, state);
                break;

            case DebtState.NoCommunicationsSent:
                this.registerBlockState(BlockTitles.UnableToSendCommunications, state);
                break;

            case DebtState.NotEligibleForRecovery:
                this.registerBlockState(BlockTitles.NoLongerADebt, state);
                break;

            case DebtState.NeedOrangeboxInputLetter1:
            case DebtState.NeedOrangeboxInputLetter2:
                this.registerBlockState(BlockTitles.SendToOrangeboxForOrdering, state);
                break;

            case DebtState.WaitingForOrangeboxInputLetter1:
            case DebtState.WaitingForOrangeboxInputLetter2:
                this.registerBlockState(BlockTitles.WaitForOrangeboxOrdering, state);
                break;

            default:
                assertNever(state);
        }
    }

    @action.bound
    cancelAfterAction() {
        this.afterAction = null;
    }

    @action.bound
    startIngesting() {
        this.motochekUploadVm = new MotochekUploadViewModel(this.api, () => {
            this.fetchAdminDetails();
            this.closeIngestionModal();
        });
    }

    @action.bound
    closeIngestionModal() {
        this.motochekUploadVm = null;
    }

    @action.bound
    handleFetchAdminDetails() {
        this.fetchAdminDetails().catch(globalHandleError);
    }

    @action.bound
    printLetters() {
        window.open(printTriageRoute(), '_blank');
    }

    @action.bound
    fireTrigger(debtIds: string[], trigger: DebtStateTrigger) {
        transformToMobxFlow(async () => {
            try {
                const response = await this.api.aurorAdmin_FireDebtStateTrigger({
                    debtIdentifiers: debtIds,
                    trigger,
                });

                this.processDebtStateChanges(response);
            } catch (e) {
                alert('Oops, something went wrong!');
                globalHandleError(e);
            }
        })().catch(globalHandleError);
    }

    @action.bound
    clearInvalidPrintRequest() {
        if (this.invalidPrintRequest) {
            const { details } = this.invalidPrintRequest;
            this.printVm.clearSelectionByDebtId(details.map((detail) => detail.debtIdentifier));
        }
        this.invalidPrintRequest = null;
    }

    @action.bound
    private processDebtStateChanges(changes: DebtStateChangeResult[]) {
        const failedChanges: string[] = [];

        changes.forEach((x) => {
            const recovery = this.recoveries.find((r) => r.debtIdentifier === x.debtIdentifier);

            if (!recovery) {
                return;
            }

            if (x.currentState) {
                recovery.debtState = x.currentState;
            }
            if (x.errorMessage) {
                failedChanges.push(`${x.debtIdentifier} ${x.errorMessage}`);
            }
        });

        if (failedChanges.length > 0) {
            alert(`Unable to update the following debts:
${failedChanges.join('\n')}`);
        }
    }

    @transformToMobxFlow
    private async fetchAdminDetails() {
        this.fetching = true;
        try {
            this.recoveries = [];
            const response = await this.api.aurorAdmin_GetAllDebtDetails(this.orgFilter);
            this.recoveries = response.map((x) => new RecoveryRowViewModel(x));
        } catch (e) {
            this.snackbarVm.showSnackbar(getUniqueId(), {
                content: e.message,
                persistent: true,
            });
            globalHandleError(e);
        } finally {
            this.fetching = false;
        }
    }
}

export const ensureImagesAreLoaded = transformToMobxFlow(async (container: HTMLElement) => {
    const allImages = container.querySelectorAll('img');

    return Promise.all(Array.from(allImages).map((x) => ensureImageIsLoaded(x)));
});

const ensureImageIsLoaded = async (image: HTMLImageElement) => {
    if (image.complete) {
        return;
    }

    return await new Promise((resolve, reject) => {
        image.addEventListener('load', resolve);
        image.addEventListener('error', () => reject(new Error('error loading an image')));
    });
};
