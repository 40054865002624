import React, { FC } from 'react';
import { getOrgDetails } from 'styles/theme';
import { getCurrencyFormat } from 'utils/currency';
import {
    getCopyDate,
    getSiteName,
    P,
    PrintPageComponent,
    Signature,
    Signoff,
    Url,
} from './PrintPage';

export const FirstLetterBPFranchise: FC<PrintPageComponent> = ({ recovery }) => {
    const { teamName } = getOrgDetails(recovery.organizationKey);
    const siteName = getSiteName(recovery);

    return (
        <div>
            <P>Dear {recovery.recipient},</P>
            <P>
                We are writing to you as the registered owner of a vehicle with number plate{' '}
                <b>{recovery.licensePlate}</b>. Your details have been obtained from the Motor
                Vehicle Register.
            </P>
            <P>
                At {getCopyDate(recovery)} at {siteName}, a vehicle with your number plate received{' '}
                {getCurrencyFormat(recovery.amount)} of fuel without full payment being made. This
                could be for a variety of reasons.
            </P>
            <P>
                You can pay now by visiting <Url>alfiepay.com/{recovery.organizationKey}</Url>,
                using <b>{recovery.licensePlate}</b> and unique code{' '}
                <b>{recovery.debtIdentifier}</b> when directed. Alternatively, you can pop into{' '}
                {siteName} to pay in person.
            </P>
            <P>
                If the vehicle in the photo isn't yours, you may be a victim of stolen or doctored
                plates. If you have any questions, feel free to come and see us in-store.
            </P>
            <P>
                Until payment is received you will be required to pre-pay for fuel purchases at all
                bp stores.
            </P>

            <Signoff>
                Kind regards,
                <Signature>{teamName}</Signature>
            </Signoff>
        </div>
    );
};
