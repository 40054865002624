import { ChurnZeroEventName } from 'api/Api.generated';
import { cardBackIcon } from 'assets/icons';
import alfiepayLogo from 'assets/images/alfiepay-logo-grey.svg';
import stripeLogo from 'assets/images/stripe.svg';
import { ActionButton } from 'components/ActionButton';
import { FormInput } from 'components/FormInput';
import { PublicLayout } from 'components/Layout';
import { StripeFailureSnackbar } from 'components/Snackbar/StripeFailureSnackbar';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import { observer } from 'mobx-react-lite';
import { PublicAppComponent } from 'pages/Details/ConfirmPlate';
import React, { useEffect, useState } from 'react';
import {
    CardCVCElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    injectStripe,
    ReactStripeElements,
    StripeProvider,
} from 'react-stripe-elements';
import styled, { css } from 'styled-components';
import { ErrorBlock, Form, H3, Logo } from 'styles';
import 'styles/stripe-elements.css';
import { getOrgDetails } from 'styles/theme';
import { alfieConfig } from 'utils/alfie-config';
import { getCurrencyFormat } from 'utils/currency';
import { loadStripe } from 'utils/load-stripe';
import { CardBrand, CardIcon } from './CardIcon';

const Details = styled.div`
    color: #555;
    display: grid;
    font-size: 0.875rem;
    grid-gap: 0.75rem 0;
    grid-template-columns: 1fr auto;
`;

const Total = styled.div`
    border-top: 1px solid #ccc;
    padding-top: 0.75rem;
`;
const Amount = styled.div`
    text-align: right;
`;

const TotalAmount = styled(Total)`
    text-align: right;
`;

const LogoStrip = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`;

const PoweredBy = styled.div`
    color: #6d6e6e;
    font-size: 0.6875rem;
    line-height: 1;
    margin: 1rem 0 0.1875rem;
    text-align: center;
`;
const PoweredByImg = styled.img`
    height: 1.625rem;
`;

const H4 = styled.h4`
    color: #555;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    margin: 1.5rem 0 0.25rem;
`;

const CardBlock = styled.div`
    background: #fff;
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: grid;
    grid: 40px 40px / 48px 1fr 1fr 40px;
    transition: box-shadow 150ms ease;
    &::focus-within {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }
`;

const IconBlock = styled.div<{ border?: boolean }>`
    ${({ border }) =>
        border &&
        css`
            border-bottom: ${({ theme }) => theme.inputBorder};
        `}
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
    & > svg {
        color: ${({ theme }) => theme.borderColor};
    }
`;

const CardNumber = styled(CardNumberElement)`
    border-bottom: ${props => props.theme.inputBorder};
    grid-column: span 3;
    &.StripeElement {
        padding-left: 0;
    }
`;

const CardExpiry = styled(CardExpiryElement)`
    border-right: ${props => props.theme.inputBorder};
    grid-column: span 2;
`;

const CardCVC = styled(CardCVCElement)``;

interface PaymentComponent extends ReactStripeElements.InjectedStripeProps, PublicAppComponent {}

export const Payment: React.FunctionComponent<PaymentComponent> = observer(({ vm }) => {
    const [stripeKey, setStripeKey] = useState<any>(null);
    useEffect(() => {
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayViewPaymentPage, vm.debtIdentifier);
        loadStripe(() => {
            setStripeKey(Stripe(alfieConfig.stripe.apiKey));
        });
    }, []);

    return (
        <StripeProvider stripe={stripeKey}>
            <Elements>
                <StripePayment vm={vm} />
            </Elements>
        </StripeProvider>
    );
});

export const PaymentEl: React.FunctionComponent<PaymentComponent> = observer(({ stripe, vm }) => {
    const { logo } = getOrgDetails(vm.organizationKey);
    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        vm.name.value = e.currentTarget.value;
    };
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        vm.email.value = e.currentTarget.value;
    };

    const onNumberChange = (e: stripe.elements.ElementChangeResponse) => {
        const { brand, complete, error } = e;
        vm.chargeError = undefined; // if was a charge error, now irrelevant
        vm.cardBrand = brand as CardBrand;
        vm.numberComplete = complete;
        vm.numberError = error;
    };

    const onExpiryChange = (e: stripe.elements.ElementChangeResponse) => {
        const { complete, error } = e;
        vm.chargeError = undefined; // if was a charge error, now irrelevant
        vm.expiryComplete = complete;
        vm.expiryError = error;
    };

    const onCvcChange = (e: stripe.elements.ElementChangeResponse) => {
        const { complete, error } = e;
        vm.chargeError = undefined; // if was a charge error, now irrelevant
        vm.cvcComplete = complete;
        vm.cvcError = error;
    };

    const onCharge = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        vm.sendChurnZeroEvent(ChurnZeroEventName.AlfiepayClickedPay, vm.debtIdentifier);
        vm.handleCharge(stripe);
    };

    return (
        <PublicLayout sendEvent={vm.sendChurnZeroEvent}>
            <Logo alt="" src={logo}/>
            <H3>Payment for {vm.licensePlate}</H3>
            <Details>
                <div>Amount</div>
                <Amount>{getCurrencyFormat(vm.amount)}</Amount>
                <Total>Total to pay</Total>
                <TotalAmount>{getCurrencyFormat(vm.total)}</TotalAmount>
            </Details>
            <Form onSubmit={onCharge} noValidate={true}>
                <H4>Credit or debit card</H4>
                <CardBlock>
                    <IconBlock border={true}>
                        <CardIcon brand={vm.cardBrand} />
                    </IconBlock>
                    <CardNumber onChange={onNumberChange} />
                    <CardExpiry onChange={onExpiryChange} />
                    <CardCVC onChange={onCvcChange} />
                    <IconBlock>
                        <SvgIcon icon={cardBackIcon} />
                    </IconBlock>
                </CardBlock>
                {vm.cardError && <ErrorBlock>{vm.cardError.message}</ErrorBlock>}
                {vm.chargeError && <ErrorBlock>Uh oh! {vm.chargeError}</ErrorBlock>}
                {vm.stripeError && <StripeFailureSnackbar message={vm.stripeError} />}
                <H4>Name on card</H4>
                <FormInput placeholder="Enter name here" field={vm.name} onChange={onChangeName} />
                {vm.name.validationMessage && <ErrorBlock>{vm.name.validationMessage}</ErrorBlock>}
                <H4>Email address (if you require a receipt)</H4>
                <FormInput
                    field={vm.email}
                    onChange={onChangeEmail}
                    placeholder="Enter email for receipt"
                    type="email"
                />
                {vm.email.validationMessage && (
                    <ErrorBlock>{vm.email.validationMessage}</ErrorBlock>
                )}
                <ActionButton type="submit" state={vm.chargeButtonState}>
                    Pay {getCurrencyFormat(vm.total)}
                </ActionButton>
            </Form>
            <PoweredBy>Powered by</PoweredBy>
            <LogoStrip>
                <PoweredByImg alt="" src={stripeLogo} />
                <div>&amp;</div>
                <PoweredByImg alt="" src={alfiepayLogo} />
            </LogoStrip>
        </PublicLayout>
    );
});

export const StripePayment = injectStripe(PaymentEl);
