import isFunction from 'lodash.isfunction';
/**
 * Decorator to PreventDefault on a react method
 * @param target
 * @param {string} propertyKey
 * @param {TypedPropertyDescriptor<Function>} descriptor
 */
export function preventDefault(
    target: any,
    propertyKey: string,
    descriptor?: TypedPropertyDescriptor<(...args: any[]) => any>,
): any {
    // the following code was ~taken from~ inspired by mobx

    if (descriptor) {
        // @preventDefault method() { }
        if (!isFunction(descriptor.value)) {
            throw new Error('@preventDefault decorator only works on with functions');
        }

        return {
            value: wrapWithPreventDefault(descriptor.value),
            enumerable: false,
            configurable: true,
            writable: true, // for typescript, this must be writable, otherwise it cannot inherit :/ (see inheritable actions test)
        };
    }

    // field decorator @preventDefault method = () => { }
    Object.defineProperty(target, propertyKey, {
        configurable: true,
        enumerable: false,
        get() {
            return undefined;
        },
        set(value: any) {
            if (!isFunction(value)) {
                throw new Error('@preventDefault decorator only works on with functions');
            }

            Object.defineProperty(this, propertyKey, {
                enumerable: false,
                writable: true,
                configurable: true,
                value: wrapWithPreventDefault(value),
            });
        },
    });
}

function wrapWithPreventDefault(fn: (...args: any[]) => any) {
    return function(this: any, ...args: any[]) {
        const [pontentialEvent] = args;

        if (pontentialEvent && isFunction(pontentialEvent.preventDefault)) {
            pontentialEvent.preventDefault();
        }

        return fn.apply(this, args);
    };
}
