import { action, observable, when } from 'mobx';

/**
 * Observable store to keep the browser's online state
 */
class IsOnlineStore {
    @observable
    isOnline = navigator.onLine;

    /**
     * The count of actions executed or awaiting while offline.
     * Stored as a count to show the snackback again if it's been closed by the user
     */
    @observable
    offlineUsageReportedCount = 0;

    constructor() {
        window.addEventListener('offline', () => {
            this.isOnline = false;
        });
        window.addEventListener(
            'online',
            action('onOnline', () => {
                this.isOnline = true;
                this.offlineUsageReportedCount = 0;
            }),
        );
    }

    @action.bound
    whenOnline() {
        if (this.isOnline) {
            return Promise.resolve();
        }

        this.offlineUsageReportedCount++;
        return when(() => this.isOnline);
    }
}

export const isOnlineStore = new IsOnlineStore();
