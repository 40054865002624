import { timesIcon } from 'assets/icons';
import { SvgIcon } from 'components/SvgIcon/SvgIcon';
import { motion } from 'framer-motion';
import isFunction from 'lodash.isfunction';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { SnackbarContent, SnackbarText } from './Snackbar';
import { SnackbarConfig } from './Snackbar.types';
import { SnackbarControllerViewModel } from './SnackbarControllerViewModel';

const transientAlertDurationMs = 7000;

export const SnackbarControllerContext = React.createContext<SnackbarControllerViewModel | null>(
    null,
);

export interface SnackbarControllerComponent {
    vm: SnackbarControllerViewModel;
}

const variants = {
    visible: { scaleY: 1, opacity: 0.98 },
    hidden: { scaleY: 0, opacity: 0 },
};

const SnackbarContainer = styled.div`
    z-index: 1065;
    position: fixed;
    top: 9rem;
    left: 1rem;
    right: 1rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    pointer-events: none;

    @media (min-width: 768px) {
        width: 320px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const SnackbarAlert = styled(motion.div)`
    color: #fff;
    background: #333;
    pointer-events: all;

    padding: 0.75rem 1.25rem;
    box-shadow: 0 0.25rem 0.75rem rgba(#000, 0.1);
    border-radius: 4px;

    & + & {
        margin-top: 1rem;
    }
`;

export const SnackbarController: React.FC<SnackbarControllerComponent> = observer(({ vm }) => {
    const { snackbars, handleDismiss } = vm;
    return (
        <SnackbarContainer>
            {snackbars.map(x => (
                <SnackbarAlert initial="hidden" animate="visible" variants={variants} key={x.id}>
                    <SnackbarItem
                        key={x.id}
                        id={x.id}
                        config={x.config}
                        onDismiss={handleDismiss}
                    />
                </SnackbarAlert>
            ))}
        </SnackbarContainer>
    );
});

interface SnackbarItemComponent {
    id: string;
    config: SnackbarConfig;
    onDismiss: (id: string) => void;
}

const SnackbarItem: React.FC<SnackbarItemComponent> = ({ id, config, onDismiss }) => {
    const { content, persistent } = config;
    useEffect(() => {
        if (persistent) {
            return;
        }
        const timeout = window.setTimeout(handleDismiss, transientAlertDurationMs);
        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [config.persistent]);

    const handleDismiss = () => {
        if (isFunction(onDismiss)) {
            onDismiss(id);
        }
    };

    return (
        <SnackbarContent>
            {typeof content === 'string' ? <SnackbarText>{content}</SnackbarText> : content}
            <SnackbarCloseButton onClose={handleDismiss} />
        </SnackbarContent>
    );
};

const CloseButton = styled(Button)`
    border-color: transparent;
    border-radius: 50%;
    color: #fff;
    padding: 0.375rem;
`;

const SnackbarCloseButton: React.StatelessComponent<{ onClose?: () => void }> = ({ onClose }) => {
    return (
        <CloseButton
            color="primary"
            locator="Snackbar-Close"
            outline={true}
            description="Close"
            onClick={onClose}
        >
            <SvgIcon icon={timesIcon} size="md" />
        </CloseButton>
    );
};
