import {
    Api,
    BatchStatusResponseCode,
    CreateDebtPostalAddressesResponse,
    CreateDebtPostalAddressPayload,
    CreateFileTypeInvalidApiError,
    FileResponse,
    FileType,
    InvalidBatchApiError,
} from 'api/Api';
import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { globalHandleError } from 'utils/globalHandleError';
import { toRecords } from 'utils/motochek-parser';
import { uploadAndGetFileId } from 'utils/aurorStorageFileUploader';

export class MotochekUploadViewModel {
    @observable
    fileError?: string;

    @observable
    uploadError?: Error = undefined;

    @observable
    records?: CreateDebtPostalAddressPayload[];

    @observable
    uploading = false;

    @observable
    uploadResult: CreateDebtPostalAddressesResponse | null = null;

    @observable
    file?: File;

    @computed
    get acceptedCount() {
        return this.uploadResult ? this.uploadResult.stateUpdates.length : 0;
    }

    @computed
    get uploadErrors() {
        return this.uploadResult ? this.uploadResult.errors : [];
    }

    @computed
    get ignoredCount() {
        return this.uploadResult ? this.uploadResult.warnings.length : 0;
    }

    @computed
    get ignoredDebtStates() {
        if (!this.uploadResult) {
            return [];
        }
        return [
            ...Object.entries(
                this.uploadResult.warnings.reduce((result, warning) => {
                    if (!warning.currentDebtState) {
                        return result;
                    }
                    result[warning.currentDebtState] = (result[warning.currentDebtState] || 0) + 1;

                    return result;
                }, {} as { [key: string]: number }),
            ),
        ];
    }

    @computed
    get ignoredDebtReasons() {
        if (!this.uploadResult) {
            return [];
        }
        return [
            ...Object.entries(
                this.uploadResult.warnings.reduce((result, warning) => {
                    if (!warning.warningReason) {
                        return result;
                    }
                    result[warning.warningReason] = (result[warning.warningReason] || 0) + 1;

                    return result;
                }, {} as { [key: string]: number }),
            ),
        ];
    }

    constructor(
        readonly api: Pick<Api, 'aurorAdmin_UploadDebtPostalAddresses' | 'aurorAdmin_CreateFile'>,
        readonly closeModal: () => void,
    ) {}

    @action.bound
    handleMotochekUpload() {
        if (this.records) {
            this.motochekUpload(this.records).catch(globalHandleError);
        }
    }

    @action.bound
    updateFile(e: ChangeEvent<HTMLInputElement>) {
        // clear any previous error & records
        this.fileError = undefined;
        this.uploadResult = null;
        this.records = undefined;
        this.file = undefined;

        if (!e.currentTarget.files || !e.currentTarget.files[0]) {
            return;
        }
        this.file = e.currentTarget.files && e.currentTarget.files[0];
        if (!this.file) {
            return;
        }
        const reader = new FileReader();
        reader.readAsText(this.file, 'UTF-8');
        reader.onload = () => {
            const { result } = reader;
            if (result) {
                try {
                    this.records = toRecords(result.toString());
                } catch (e) {
                    this.fileError = `Uh oh! ${e.message}. You'll need to edit the file to take out the bad records.`;
                    globalHandleError(e);
                }
            } else {
                this.fileError = `Uh oh! Something went wrong uploading this file. Try again?`;
            }
        };
        reader.onerror = () =>
            (this.fileError = `Uh oh! Something went wrong uploading this file. Try again?`);
    }

    @transformToMobxFlow
    private async motochekUpload(payload: CreateDebtPostalAddressPayload[]) {
        this.uploadError = undefined; // clear any previous error
        this.uploadResult = null;
        this.uploading = true;

        //Upload File to Azure and Create File definition in Db
        if (!this.file) {
            throw new Error('Could not complete Motochek ingestion.');
        }

        try {
            const motochekFileId = await uploadAndGetFileId(this.file, (fileId) =>
                this.createMotochekFile(fileId),
            );

            const result = await this.api.aurorAdmin_UploadDebtPostalAddresses({
                motochekFileId,
                payload,
            });

            if (InvalidBatchApiError.isInstance(result)) {
                this.uploadError = new Error(
                    result.batchStatus === BatchStatusResponseCode.FailedSafeToReprocess
                        ? 'Address upload failed. It is safe to reprocess Motochek file.'
                        : 'Address upload failed. It is not safe to reprocess Motochek file',
                );
            } else {
                this.uploadResult = result;
            }
        } catch (e) {
            this.uploadError = e;
            globalHandleError(e);
        } finally {
            this.uploading = false;
        }
    }

    @transformToMobxFlow
    private async createMotochekFile(fileId: string): Promise<FileResponse> {
        const response = await this.api.aurorAdmin_CreateFile({
            fileId,
            fileName: '',
            fileType: FileType.MotochekResponse,
        });

        if (CreateFileTypeInvalidApiError.isInstance(response)) {
            throw new Error('Could not complete file upload');
        } else {
            return response;
        }
    }
}
